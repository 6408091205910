import React, { useEffect, useState, createRef } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { IconButton, TextField } from "@material-ui/core";
import { getData, postData, ServerURL } from "../FetchNodeServices";
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useSelector, useDispatch } from "react-redux";
import Image from "react-image-resizer";

const useStyles = makeStyles((theme) => ({
  scardview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //flexWrap: "wrap",
    // margin: "10px 20px",
  },
  headingName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 20,
    fontSize: 30,
    color: "#212121",
    textAlign: 'center',
    fontWeight: "bold",
    letterSpacing: "2.5px",
    fontFamily: 'Calibri',
    backgroundColor: 'transparent'
  },
  divider: {
    margin: '15px 10px',
    backgroundColor: '#e74c3c',
    height: 3,
    width: 100,
  },

}));

export default function TopBrands(props) {
  const classes = useStyles();
  const [getTopBrands, setTopBrands] = useState([]);

  useEffect(function () {
    fetchTopBrands();
  }, []);

  const fetchTopBrands = async () => {
    var list = await postData("brand/brandfilltop", {});
    setTopBrands(list);
  };

  const TopBrands = () => {
    return getTopBrands.map((item, key) => {
      return (
        // <div className={classes.scardview} style={{ padding: 5 , cursor:'pointer'}}onClick={()=>handleProductBrand(item.brandid)} >
        //   <div style={{padding:10,}}>
        //     <Paper elevation={3}>
        //     <img
        //       style={{ width: "100%", height: "100%" }}
        //       src={`${ServerURL}/images/${item.ad}`}
        //     />
        //     </Paper>
        //   </div>
        // </div>
        <div className={classes.scardview} onClick={() => handleProductBrand(item.brandid)} >
          <Paper elevation={0}>
            <div style={{ padding: 10, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={`${ServerURL}/images/${item.ad}`} style={{ width: '80%', height: '50%', borderRadius: 5 }} />
            </div>
          </Paper>
        </div>
      );
    });
  };

  const handleProductBrand = (brandid) => {
    props.history.push({ pathname: `/ViewListofBrandProducts/${brandid}` });
  };

  var sliderRef = createRef()
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,

        }
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]

  };


  const gotoNext = () => {
    sliderRef.current.slickNext()
  }
  const gotoPrev = () => {
    sliderRef.current.slickPrev()
  }


  return (
    <div className={classes.scardview} style={{ margin: 10 }} >
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {getTopBrands.length != 0 ? <Paper elevation={0} style={{ width: '95%', }} >
          <div className={classes.headingName}>
            <Divider className={classes.divider} />
            TRENDING IN TOP BRANDS
            <Divider className={classes.divider} />
          </div>
          {getTopBrands.length > 5 ? <div className={classes.scardview}><div style={{}} onClick={() => gotoPrev()} >
            <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
          </div>
            <div style={{ width: '90%', }}>
              <Slider {...settings} ref={sliderRef} >
                {TopBrands()}
              </Slider>
            </div>
            <div style={{}} onClick={() => gotoNext()}>
              <KeyboardArrowRightIcon style={{ fontSize: 40 }} />
            </div></div> : <div style={{ display: 'flex', flexWrap:'wrap', alignItems: 'center', justifyContent: 'center', }}>
            {TopBrands()}</div>}
        </Paper> : <></>}
      </div>
    </div>)
}
