import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Footer from './Footer';
import { postDataAndImage, getData, postData } from '../FetchNodeServices'
import { useSelector, useDispatch } from 'react-redux';
import ShowProfile from './ShowProfile';
import AddAddress from './AddAddress';
import OrderHistory from './OrderHistory'
import Header from './Header';
import HistoryIcon from '@material-ui/icons/History';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import "./style.css"

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //margin: '5px 10px',
    padding: 10,
    flexDirection: 'column'
  },
  paperStyle: {
    width: window.innerWidth * 0.75,
    margin: 10,
    padding: '5px'
  },

  gridStyle: {
    display: 'flex',
    margin: 5,
    padding: 20
  },
  red: {
    color: '#ffffff',
    backgroundColor: '#de011b',
    width: 55,
    height: 55,
    margin: '10px 30px',

  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textfieldStyle:
  {
    margin: '4px 4px',
    // height:'30px'
  },
  divRow: {
    display: 'flex', flexDirection: 'row',
    fontSize: 12,
  },
  error: {
    color: 'red',
    //position:"absolute",
    fontSize: 12,
    margin: '0px 4px'
  },
  paper: {
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 5px 0px',
    "&:hover": {
      // background: "#f5f6fa",
      transition: 'all 0.5s ease 0s',
    },
    // width: window.innerWidth * 0.75,
    // display: 'flex',
    padding: '20px 5px',
    backgroundColor: "#FFFFFF",
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 5px 0px',
    borderRadius: 0,
  },
  gridpaper: {
    margin: '0px 0px 10px 0px',
    //cursor: 'pointer'
  },
  hover: {
    "&:hover": {
      background: "#f1f2f6",
      transition: 'all 0.5s ease 0s',
    },
  },
  filterStyleHeading: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1,
    padding: "0px 0px 0px 15px",
  },
  filterStyle: {
    fontSize: 12,
    color: '#000000',
    letterSpacing: 0.5,
    fontWeight: 550,
    padding: "10px 0px 8px 0px",
  },
}));


function MyAccount(props) {
  const classes = useStyles()
  const [getMobile, setMobile] = useState('')
  const [getErrMob, setErrMob] = useState('')
  const [getOTP, setOTP] = useState('')
  const [getFirstName, setFirstName] = useState('')
  const [getLastName, setLastName] = useState('')
  const [getEmail, setEmail] = useState('')
  const [view, setView] = useState('')

  var dispatch = useDispatch()
  const path = props.history.location.path
  const setContainerView = (n) => {
    //alert(n)
    switch (n) {
      case 1: setView(<ShowProfile />)
        break;
      case 2: setView(<AddAddress />)
        break;
      case 3: setView(<OrderHistory history={props.history} />)
        break;

    }
  }

  //////////////////////////////////////////////////////////


  useEffect(function () {
    CheckSession()
  }, [])


  const CheckSession = async () => {
    if (localStorage.getItem('user')) {
      var user = JSON.parse(localStorage.getItem('user'))
      var name = user.username.split(' ')
      setFirstName(name[0])
      setLastName(name[1])
      setEmail(user.emailaddress)
      setMobile(user.mobileno)
      if (path == undefined) {
        setContainerView(2)
      }
      else {
        setContainerView(path)
      }
      return
    }
    else {
      props.history.replace({ pathname: `/UserLogin` })
    }
  }

  const userLogout = async () => {
    dispatch({ type: "REMOVE_ALL_CART_AND_USER" })
    props.history.replace({ pathname: `/UserLogin` })
    localStorage.clear()
  }

  var active = JSON.parse(localStorage.getItem("MyProfile"))

  const userMenu = () => {
    return (
      <Grid container spacing={0} >
        <Grid item xs={12} sm={12} className={classes.gridpaper}>
          <div style={{ padding: '10px 20px' }} className={classes.paper}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', fontWeight: 600, marginBottom: 10 }}>
              <img src="/signimage/user-male.png" height='100px' width='100px' />
              {/* <span ><AccountCircleIcon style={{ padding: '5px', fontSize: 40, }} /></span> */}
              {getFirstName} {getLastName}
              <small>+91 {getMobile}</small>
            </div>
            <Divider />
            {active == "MyProfile" ?
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, cursor: 'pointer', padding: 10, borderRadius: 10, background: '#212121' }} onClick={() => (setContainerView(1), localStorage.setItem('MyProfile', JSON.stringify("MyProfile")))}>
                {/* <img src="/signimage/icons8.png" height='60px' width='60px' /> */}
                <AccountCircleOutlinedIcon style={{ background: '#fff', color: '#212121', borderRadius: 10, padding: 2 }} />
                <div className={classes.filterStyleHeading} style={{ color: '#fff' }}>My Profile</div>
              </div>
              :
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, padding: 10 }} className="menuhover" onClick={() => (setContainerView(1), localStorage.setItem('MyProfile', JSON.stringify("MyProfile")))}>
                {/* <img src="/signimage/icons8.png" height='60px' width='60px' /> */}
                <AccountCircleOutlinedIcon style={{ background: '#fff', color: '#212121', borderRadius: 10, padding: 2 }} />
                <div className={classes.filterStyleHeading}>My Profile</div>
              </div>
            }
            {active == "OrderHistory" ?
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, cursor: 'pointer', padding: 10, borderRadius: 10, background: '#212121' }} onClick={() => (setContainerView(3), localStorage.setItem('MyProfile', JSON.stringify("OrderHistory")))}>
                {/* <img src="/signimage/shoppingcart.png" height='60px' width='60px' /> */}
                <HistoryIcon style={{ background: '#fff', color: '#212121', borderRadius: 10, padding: 2 }} />
                <div className={classes.filterStyleHeading} style={{ color: '#fff' }}>Orders History </div>
              </div>
              :
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, padding: 10 }} className="menuhover" onClick={() => (setContainerView(3), localStorage.setItem('MyProfile', JSON.stringify("OrderHistory")))}>
                {/* <img src="/signimage/icons8.png" height='60px' width='60px' /> */}
                <HistoryIcon style={{ background: '#fff', color: '#212121', borderRadius: 10, padding: 2 }} />
                <div className={classes.filterStyleHeading}>Orders History</div>
              </div>
            }
            {active == "Address" ?
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, cursor: 'pointer', padding: 10, borderRadius: 10, background: '#212121' }} onClick={() => (setContainerView(2), localStorage.setItem('MyProfile', JSON.stringify("Address")))}>
                {/* <img src="/signimage/shoppingcart.png" height='60px' width='60px' /> */}
                <LocationOnOutlinedIcon style={{ background: '#fff', color: '#212121', borderRadius: 10, padding: 2 }} />
                <div className={classes.filterStyleHeading} style={{ color: '#fff' }}>Address </div>
              </div>
              :
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, padding: 10 }} className="menuhover" onClick={() => (setContainerView(2), localStorage.setItem('MyProfile', JSON.stringify("Address")))}>
                {/* <img src="/signimage/icons8.png" height='60px' width='60px' /> */}
                <LocationOnOutlinedIcon style={{ background: '#fff', color: '#212121', borderRadius: 10, padding: 2 }} />
                <div className={classes.filterStyleHeading}>Address</div>
              </div>
            }
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, padding: 10 }} className="menuhover" onClick={() => userLogout()}>
              {/* <img src="/signimage/logout.png" height='60px' width='60px' /> */}
              <ExitToAppOutlinedIcon style={{ background: '#fff', color: '#000', borderRadius: 10, padding: 2 }} />
              <div className={classes.filterStyleHeading}>Logout</div>
            </div>
          </div>
        </Grid>
      </Grid >
    )
  }

  return (
    <div >
      <Header history={props.history} />
      <div className={classes.root} >

        {/* <div style={{ width: '98%' }} > */}
        {/* <div elevation={1} className={classes.paper}> */}
        <Grid container spacing={1}>
          <Grid item xs={12} md={3} style={{ padding: '15px 25px', }} >
            {userMenu()}
          </Grid>

          <Grid item xs={12} md={9} style={{ padding: '15px 25px', }} >
            <div elevation={0} className={classes.paper}>
              {view}
            </div>
          </Grid>

        </Grid>
        {/* </div> */}
        {/* </div> */}

        <div style={{ textAlign: 'center', fontSize: 13, paddingTop: 30 }}>
          By continuing you agree to our <font color='red'>Terms of service</font><br />
          and <font color='red' >Privacy & Legal Policy.</font>
        </div>

      </div>
      <Footer history={props.history} />
    </div>

  )
}


export default MyAccount;