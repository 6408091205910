import React, { useEffect, useState, ComponentDidUpdate } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import { getData, postData, ServerURL } from "../FetchNodeServices";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import QtyCtrl from "./QtyCtrl";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Image from "react-image-resizer";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Slider from "@material-ui/core/Slider";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import StarIcon from "@material-ui/icons/Star";
import "./style.css";


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    padding: 0,
    backgroundColor: "#f3f3f3",
  },
  scardview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: 10,
  },
  productAction: {
    display: "flex",
    justifyContent: "flex-start",
    width: 250,
    //alignItems:'center',
    //height: 320,
    //borderRadius: 10,
    margin: 7,
    //padding: 10,
    // border: "2px solid #dcdde1",
    flexDirection: "column",
    color: "#747d8c",
    cursor: "pointer",
  },
  ImageView: {
    width: 250,
    height: 250,
    display: "flex",
    // border:'1px solid',
    alignItems: "center",
    justifyContent: "center",
    //padding: 5,
    "&:hover": {
      // background: "#747d8c",
      transform: "scale(1.035)",
      transition: "all 0.1s ease 0s",
    },
    backgroundColor: "#f1f2f6",
    borderRadius: 5,
  },
  divStyle: {
    margin: "0px 20px 20px",
    // width: window.innerWidth * 0.75,
    borderRadius: 5,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filterStyle: {
    fontSize: 14,
    fontWeight: "bold",
    padding: "10px 10px 5px 14px",
  },
  hover: {
    "&:hover": {
      background: "#747d8c",
      transform: "scale(1)",
      transition: "all 0.5s ease 0s",
      color: "#fff",
    },
  },
}));

export default function ViewListofModelProducts(props) {
  const classes = useStyles();
  const [getList, setList] = useState([]);
  const [getFilterList, setFilterList] = useState([]);
  const [getSubCat, setSubCat] = useState('');
  const [getCount, setCount] = useState(false);
  const [getPage, setPage] = useState([]);
  const [getCategoryList, setCategoryList] = useState([]);
  const [getMaxPrice, setMaxPrice] = React.useState(0);
  const [getMinPrice, setMinPrice] = React.useState(0);
  const [getPrice, setPrice] = React.useState([getMinPrice, getMaxPrice]);
  const [getAvailabilty, setAvailability] = useState("");
  const [getOrder, setOrder] = useState("");
  const [getCategory, setCategory] = useState("");
  const [getDiscount, setDiscount] = useState("");
  const [getState, setState] = useState(false);
  const [getBrandList, setBrandList] = useState([]);


  const dispatch = useDispatch();
  const params = useParams();
  var cart = useSelector((state) => state.cart);
  var cartItems = Object.values(cart);

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  useEffect(
    function () {
      //fetchBrandAd();
      fetchData();
      fetchCategory();
      fetchBrand()
      window.scrollTo(0, 0);
      setState(false);
    },
    [params.sid]
  );

  /* const fetchBrandAd = async () => {
    var result = await postData("brand/fetchbrandbyid", {
      brandid: params.bid,
    });
    if (result) {
      //console.log(result)
      setBrand(result.data);
    }
  };*/

  const fetchData = async () => {
    var arr = [];
    let body = { subcategoryid: params.sid };
    var list = await postData("product1/productsbysubcategoryid", body);
    setList(list);
    setFilterList(list);
    if (list.length != 0) {
      list.map(function (item, index) {
        if (index < 20) {
          arr.push(item);
        }
      });
      setPage(arr);
      setSubCat(list[0].subcategoryname)
      var min = list.reduce(
        (min, b) => Math.min(min, b.offerprice),
        list[0].offerprice
      );
      var max = list.reduce(
        (max, b) => Math.max(max, b.offerprice),
        list[0].offerprice
      );
      setMinPrice(min);
      setMaxPrice(max);
      setPrice([min, max]);
      setOrder("");
      setDiscount("");
      setAvailability("");
      setState(true);
    }
    else{
      setState(true);
     }
  };

  //Count Cart Items//
  const handleQtyChange = (value, item, key) => {
    if (value >= 1 && value <= item.stock) {
      item["qtydemand"] = value;
      dispatch({ type: "ADD_CART", payload: [item.colorid, item] });
      setCount(!getCount);
    } else if (value == 0) {
      item["qtydemand"] = value;
      var list = getPage;
      list[key]["cartstatus"] = 0;
      setPage(list);
      dispatch({ type: "REMOVE_CART", payload: [item.colorid, item] });
      setCount(!getCount);
    } else {
    }
  };

  //ADD To Cart//
  const AddToCart = (item, key) => {
    if (item.stock >= 1) {
      item["qtydemand"] = 1;
      var list = getPage;
      list[key]["cartstatus"] = 1;
      setPage(list);
      dispatch({ type: "ADD_CART", payload: [item.colorid, item] });
      setCount(!getCount);
    } else {
      handleShowProductView(item.productid,item.modelid);
    }
  };

  const ShowProducts = () => {
    return getPage.map(function (item, key) {
      var save = item.price - item.offerprice;
      var o = 1;
      var color = "green";
      if (item.status == "Coming Soon" || item.status == "Pre Booking") {
        o = 0.5;
        if (item.status == "Coming Soon") {
          color = "red";
        }
      }
      var st = false;
      var discount = Math.ceil((save / item.price) * 100);
      item["cartstatus"] = 0;
      cartItems.map(function (product, key) {
        if (item.colorid == product.colorid) {
          item.qtydemand = product.qtydemand;
          item.cartstatus = 1;
          st = true;
        }
      });

      return (
        <div className={classes.productAction}>
          <div
            className={classes.ImageView}
            onClick={() => handleShowProductView(item.productid,item.modelid)}
          >
            {/*<img src={`${ServerURL}/images/${item.picture}`} width="100%" height="100%" />*/}
            {
              <Image
                width={250}
                height={250}
                style={{ opacity: o }}
                alt={item.productname}
                src={`${ServerURL}/images/${item.picture}`}
                onClick={() => handleShowProductView(item.productid,item.modelid)}
              />
            }
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 0px 10px 20px",
              border: "1px solid #dcdde1",
              borderRadius: 5,
              padding: 10,
              margin: "5px 0px",
            }}
          >
            <div style={{ fontSize: 14, padding: "5px 0px", borderRadius: 3 }}>
              <span
              className="price_tag"
              >
                {numberFormat(item.offerprice)}
               
              </span>

              <span>
              <small>
                  &nbsp; &nbsp; MRP &nbsp;
                  <b>{numberFormat(item.price)}</b>&nbsp;<br/>
                  {/* {discount > 5 ? (
                <span style={{ color: "red", fontSize: 12,marginLeft:100,position:'absolute' }}>
                  ({discount}% off)
                </span>
              ) : (
                <></>
              )} */}
                </small>
              </span>
            </div>
            <div
              style={{ fontSize: 15, fontWeight: "bold", padding: "5px 0px 3px" }}
            >
              {item.brandname}
            </div>
            <div style={{ fontSize: 14, padding: "3px 0px" }}>
              {item.productname.length >= 30
                ? item.productname.toString().substring(0, 27) + "..."
                : item.productname}
            </div>
            {/*<div style={{ fontSize: 14 }}>
                <small>
                  {" "}
                  M.R.P <s> {numberFormat(item.price)} </s>{" "}
                </small>
                &nbsp;&nbsp;
                <b>{numberFormat(item.offerprice)}</b>&nbsp;&nbsp;
                {discount >5  ? <span style={{color:'red',fontSize:13}}>({discount}% off)</span> :<></>}
                </div>*/}
            <div
              style={{
                border: "1px solid #dcdde1",
                fontSize: 13,
                width: 50,
                borderRadius: 3,
                margin: "2px 0px",
                fontWeight: "bold",
                //padding: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 5,
              }}
            >
              {item.ratings}/5{" "}
              <StarIcon
                style={{ color: "#f39c12", fontSize: 18, padding: 2 }}
              />
            </div>
          </div>
          <div
            style={{
              // padding: "5px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {item.status == "Pre Booking" ? (
              <>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    height: 30,
                    padding: 5,
                    backgroundColor: "#ffea29",
                    color: "#ffffff",
                  }}
                  onClick={() => handleShowProductView(item.productid,item.modelid)}
                >
                  Pre Book Now &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </Button>
              </>
            ) : item.cartstatus == 0 ? (
              <Button
                variant="contained"
                fullWidth
                style={{
                  height: 40,
                  padding: 10,
                  backgroundColor: "#fff200",
                  //color: "#747d8c",
                  fontSize: 15,
                  letterSpacing: "0px",
                  fontWeight: "bold",
                }}
                onClick={
                  item.qtdemand > 0
                    ? () => AddToCart(item, key)
                    : () => handleShowProductView(item.productid,item.modelid)
                }
              >
                Add to Cart &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <big>
                  <ShoppingCart
                    style={{ marginTop: 6, backgroundColor: "transparent" }}
                  />{" "}
                </big>
              </Button>
            ) : (
              <QtyCtrl
                value={item.qtydemand}
                onChange={(value) => handleQtyChange(value, item, key)}
              />
            )}
          </div>
        </div>
      );
    });
  };

  

  const handleShowProductView = (productid,modelid, qt) => {
    props.history.push({ pathname: `/ProductView/${productid}/${modelid}` }, qt);
  };

  const handlePages = (event, page) => {
    var list = [];
    var i = 0;
    getFilterList.map(function (item, index) {
      if (index >= (page - 1) * 20 && i < 20) {
        list.push(item);
        i++;
      }
    });
    setPage(list);
    setState(true);
  };

  const fetchCategory = async () => {
    var list = await getData("pack/category");
    setCategoryList(list);
  };

  const Categories = () => {
    return (
      <RadioGroup
        value={getCategory}
        onChange={(event) => setCategory(event.target.value)}
      >
        {getCategoryList.map(function (item, key) {
          return (
            <FormControlLabel
              value={item.categoryname}
              control={<Radio style={{ color: "#747d8c" }} size="small" />}
              label={<span style={{ fontSize: 11 }}>{item.categoryname}</span>}
            />
          );
        })}
      </RadioGroup>
    );
  };

  const fetchBrand = async () => {
    var list = await postData("brand/brandfillbysubcat", { subcategoryid: params.sid});

    list.map((item)=>{
     item['checked']=false
    })
    setBrandList(list);
  };


  const BrandWise = (brand) => {
    var arr=[]
    getList.map((item)=>{
      brand.map((d)=>{
       if(item.brandid==d.brandid){
         if(d.checked){
           arr.push(item)
         }
       }
      })
    })
    setFilterList(arr)
    var list = [];
    arr.map(function (item, index) {
      if (index < 20) {
        list.push(item);
      }
    });
    setPage(list);
    setAvailability("");
    setPrice([getMinPrice, getMaxPrice]);
    setDiscount("");
    setOrder("") 
  };

  
  const handleBrandChange = (event) => {
    var arr=[]
    getBrandList.map((item)=>{
      if(item.brandid==event.target.name){
        item['checked']=event.target.checked
      }
      arr.push(item)
    })
    BrandWise(arr)
  };

 

  const BrandFilter = () => {
    return (
      <FormControl component="fieldset" >
        <FormGroup>
        {getBrandList.map((item)=>{
          return(
            <FormControlLabel
            control={<Checkbox checked={item.checked} style={{color:'#747d8c'}} size="small" onChange={handleBrandChange} name={item.brandid} />}
            label={<span style={{ fontSize: 12 }}>{item.brandname}</span>}
          />
          )
        })
        }
        </FormGroup>
      </FormControl>
    );
  };


  const PriceWise = () => {
    var arr = [];
    var list = [];
    getList.map((item) => {
      if (item.offerprice >= getPrice[0] && item.offerprice <= getPrice[1]) {
        arr.push(item);
      }
    });
    setFilterList(arr);
    //console.log(list)
    arr.map(function (item, index) {
      if (index < 20) {
        list.push(item);
      }
    });
    setPage(list);
    setAvailability("");
    setDiscount("");
    setOrder("");
  };

  const handlePrice = (event, newPrice) => {
    setPrice(newPrice);
    PriceWise();
  };

  const Price = () => {
    return (
      <div>
        <div style={{ padding: "0px 8px" }}>
          <Slider
            value={getPrice}
            onChange={handlePrice}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            min={getMinPrice}
            max={getMaxPrice}
            style={{ color: "#747d8c" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: 12,
            padding: "0px 20px 10px",
          }}
        >
          <div style={{ width: "100%" }}>
            {" "}
            Min
            <br />
            {getPrice[0]}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {" "}
            Max <br />
            {getPrice[1]}{" "}
          </div>
        </div>
      </div>
    );
  };

  const SortWise = (order) => {
    var arr = [];
    switch (order) {
      case "Low to High":
        arr = getList.sort((a, b) => a.offerprice - b.offerprice);
        setFilterList(arr);
        break;
      case "High to Low":
        arr = getList.sort((a, b) => b.offerprice - a.offerprice);
        setFilterList(arr);
        break;
      case "Oldest to Newest":
        arr = getList.sort((a, b) => a.productid - b.productid);
        setFilterList(arr);
        break;
      case "Newest to Oldest":
        arr = getList.sort((a, b) => b.productid - a.productid);
        setFilterList(arr);
        break;
      default:
        return;
    }
    var list = [];
    arr.map(function (item, index) {
      if (index < 20) {
        list.push(item);
      }
    });
    setPage(list);
    setAvailability("");
    setPrice([getMinPrice, getMaxPrice]);
    setDiscount("");
  };
  const handleOrder = (event) => {
    setOrder(event.target.value);
    var order = event.target.value;
    SortWise(order);
  };
  const SortBy = () => {
    return (
      <RadioGroup value={getOrder} onChange={(event) => handleOrder(event)}>
        <FormControlLabel
          value="Low to High"
          control={<Radio style={{ color: "#747d8c" }} size="small" />}
          label={<span style={{ fontSize: 12 }}>Low to High</span>}
        />
        <FormControlLabel
          value="High to Low"
          control={<Radio style={{ color: "#747d8c" }} size="small" />}
          label={<span style={{ fontSize: 12 }}>High to Low</span>}
        />
        <FormControlLabel
          value="Oldest to Newest"
          control={<Radio style={{ color: "#747d8c" }} size="small" />}
          label={<span style={{ fontSize: 12 }}>Oldest to Newest</span>}
        />
        <FormControlLabel
          value="Newest to Oldest"
          control={<Radio style={{ color: "#747d8c" }} size="small" />}
          label={<span style={{ fontSize: 12 }}>Newest to Oldest</span>}
        />
      </RadioGroup>
    );
  };

  const handleChangeAvailability = (event) => {
    setAvailability(event.target.value);
    var arr = [];
    getList.map((item) => {
      if (item.stock > 0) {
        arr.push(item);
      }
    });
    setFilterList(arr);
    var list = [];
    arr.map(function (item, index) {
      if (index < 20) {
        list.push(item);
      }
    });
    setPage(list);
    setDiscount("");
    setPrice([getMinPrice, getMaxPrice]);
    setOrder("");
  };

  const Availability = () => {
    return (
      <div style={{ fontSize: 15 }}>
        <Checkbox
          checked={getAvailabilty == "In Stock"}
          onChange={handleChangeAvailability}
          style={{ color: "#747d8c" }}
          value="In Stock"
        />
        <span style={{ padding: "10px 0px" }}>In Stock</span>
      </div>
    );
  };

  const DiscountWise = (discount) => {
    var arr = [];
    switch (discount) {
      case "40% or more":
        getList.map((item) => {
          var dis = Math.ceil(
            ((item.price - item.offerprice) / item.price) * 100
          );
          if (dis >= 40) {
            arr.push(item);
          }
        });
        setFilterList(arr);
        break;
      case "30% or more":
        getList.map((item) => {
          var dis = Math.ceil(
            ((item.price - item.offerprice) / item.price) * 100
          );
          if (dis >= 30) {
            arr.push(item);
          }
        });
        setFilterList(arr);
        break;
      case "20% or more":
        getList.map((item) => {
          var dis = Math.ceil(
            ((item.price - item.offerprice) / item.price) * 100
          );
          if (dis >= 20) {
            arr.push(item);
          }
        });
        setFilterList(arr);
        break;
      case "10% or more":
        getList.map((item) => {
          var dis = Math.ceil(
            ((item.price - item.offerprice) / item.price) * 100
          );
          if (dis >= 10) {
            arr.push(item);
          }
        });
        setFilterList(arr);
        break;
      default:
        return;
    }
    var list = [];
    arr.map(function (item, index) {
      if (index < 20) {
        list.push(item);
      }
    });
    setPage(list);
    setAvailability("");
    setPrice([getMinPrice, getMaxPrice]);
    setOrder("");
  };

  const handleDiscount = (event) => {
    setDiscount(event.target.value);
    DiscountWise(event.target.value);
  };

  const Discount = () => {
    return (
      <div>
        <RadioGroup
          value={getDiscount}
          onChange={(event) => handleDiscount(event)}
        >
          <FormControlLabel
            value="40% or more"
            control={<Radio style={{ color: "#747d8c" }} size="small" />}
            label={<span style={{ fontSize: 12 }}>40% or more</span>}
          />
          <FormControlLabel
            value="30% or more"
            control={<Radio style={{ color: "#747d8c" }} size="small" />}
            label={<span style={{ fontSize: 12 }}>30% or more</span>}
          />
          <FormControlLabel
            value="20% or more"
            control={<Radio style={{ color: "#747d8c" }} size="small" />}
            label={<span style={{ fontSize: 12 }}>20% or more</span>}
          />
          <FormControlLabel
            value="10% or more"
            control={<Radio style={{ color: "#747d8c" }} size="small" />}
            label={<span style={{ fontSize: 12 }}>10% or more</span>}
          />
        </RadioGroup>
      </div>
    );
  };

  const Filters = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          margin: "20px 0px",
          height: "auto",
          position: "sticky",
          top: 120,
        }}
      >
        <div style={{ fontSize: 16, fontWeight: "bold", padding: 10 }}>
          {" "}
          Filters
        </div>
        <Divider />
        {/*<div >
        <Accordion elevation={0} >
          <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header" 
          style={{fontSize:14, fontWeight:'bold',}} >
         Categories
        </AccordionSummary>
        <AccordionDetails>
          {Categories()}
        </AccordionDetails>
      </Accordion>
        </div>*/}
        <Divider />
        <div>
          {" "}
          <div className={classes.filterStyle}>Price</div> {Price()}{" "}
        </div>
        <Divider />
        <div>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ fontSize: 14, fontWeight: "bold" }}
            >
              Sort By
            </AccordionSummary>
            <AccordionDetails>{SortBy()}</AccordionDetails>
          </Accordion>
        </div>
        <Divider />
        <div>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ fontSize: 14, fontWeight: "bold" }}
            >
              Discount
            </AccordionSummary>
            <AccordionDetails>{Discount()}</AccordionDetails>
          </Accordion>
        </div>
        <Divider />
        <div>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ fontSize: 14, fontWeight: "bold" }}
            >
             Brands
            </AccordionSummary>
            <AccordionDetails>{BrandFilter()}</AccordionDetails>
          </Accordion>
        </div>
        <Divider/>
        {/* <div>
          {" "}
          <div className={classes.filterStyle}>Availability</div>{" "}
          {Availability()}{" "}
        </div> */}
      </div>
    );
  };

  const ProductsData = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px 10px",
        }}
      >
        {/*<div className={classes.divStyle}>
          <Grid container spacing={2}>
           <Grid item xs={12} sm={6} style={{ padding: 5 }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={`${ServerURL}/images/${getBrand.ad}`}
                alt={getBrand.brandname}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ padding: 5 }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={`${ServerURL}/images/${getBrand.ad}`}
                alt={getBrand.brandname}
              />
            </Grid>
          </Grid>
    </div>*/}
        <div
          className={classes.divStyle}
          style={{ display: "flex", flexDirection: "row", color: "none" }}
        >
          <div style={{ width: "100%", padding: "0px 10px", fontSize: 17 }}>
            <b>{getSubCat }</b>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              padding: "0px 10px",
              fontSize: 17,
            }}
          >
            Showing &nbsp;
            <b>
              {getPage.length}/{getFilterList.length}
            </b>{" "}
            &nbsp;items
          </div>
        </div>
        <div className={classes.divStyle}>
          <div
            className={classes.hover}
            style={{
              border: "1px solid #747d8c",
              borderRadius: 20,
              padding: "5px 10px",
              width: 130,
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => fetchData()}
          >
            All Products
          </div>
        </div>
        <div className={classes.divStyle} style={{ backgroundColor: "#fff" }}>
          <div className={classes.scardview}>
            {getFilterList.length == 0 ? (
              <h4>No Products found</h4>
            ) : (
              ShowProducts()
            )}
          </div>
        </div>
        {getFilterList.length / 20 <= 1 ? (
          <></>
        ) : (
          <div className={classes.center} style={{ width: "100%" }}>
            <Pagination
              count={Math.ceil(getFilterList.length / 20)}
              variant="outlined"
              shape="rounded"
              onChange={(event, page) => handlePages(event, page)}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.grow}>
      <Header history={props.history} />
      <div className={classes.center}>
        <div
          style={{ width: window.innerWidth, margin: 5, padding: "5px 20px" }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
              {Filters()}
            </Grid>
            <Grid item xs={12} md={10}>
              {getState ? (
                ProductsData()
              ) : (
                <div className={classes.center} style={{ marginTop: 100 }}>
                  <CircularProgress style={{ color: "#747d8c" }} />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer history={props.history} />
    </div>
  );
}

/*  <Grid container spacing={1}>
      <Grid item xs={12} sm={2} >
       {Filters()}
        </Grid>
    <Grid item xs={12} sm={10} >
      {ProductsData()}
      </Grid>
      </Grid> */
