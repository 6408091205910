import React, { useEffect, useState, createRef } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { ServerURL, getData, postData } from "../FetchNodeServices";
import ArrowRightOutlinedIcon from "@material-ui/icons/ArrowRightOutlined";
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';

const useStyles = makeStyles((theme) => ({
  divAction: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    padding: 10,
    "&:hover": {
      // background: "#de011b",
      transform: "scale(1.15)",
      transition: "all 0.5s ease 0s",
    },
    cursor: "pointer",
    width:200,
    height:200
  },

  cardview: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  headingName: {
    padding: 10,
    fontSize:25,
    color: "#747d8c",
    marginTop:20,
    textAlign:'center',
    fontWeight: "bold",
    letterSpacing: "2.5px",
    fontFamily: 'Georgia,Times,"Times New Roman",serif',
  },
  hover:{
    "&:hover": {
      background: "#747d8c",
      transform: "scale(1.035)",
      transition: "all 0.1s ease 0s",
    },
  }
}));

export default function ShowCategory(props) {
  const classes = useStyles();
  const [getListAd, setListAd] = useState([]);
  const [getCategoryList,setCategoryList]=useState([])
  const [getSubCategoryList,setSubCategoryList]=useState([])
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(function () {
    fetchCategoryAd();
    fetchAllCategories()
  }, []);

  const fetchCategoryAd = async () => {
    var body = {};
    var list = await postData("pack/categoryfillad", body);
    setListAd(list);
  };

  const fetchSubCategory = async (categoryid) => {
    var body = { categoryid: categoryid };
    var result = await postData(
      "subcategory/subcategorybycategoryid",
      body
    );
    setSubCategoryList(result);
  };

  const handleClose = () => {
    setAnchorEl();
  };


  const fetchAllCategories = async () => {
    var result = await getData("pack/category");

    setCategoryList(result);
  };

const handleClick=(event)=>{ 
  setAnchorEl(event.currentTarget)
  fetchSubCategory(event.currentTarget.value)
}

const handleProductSubCategory = (subcategoryid) => {
  props.history.push({ pathname: `/ViewListofSubCategory/${subcategoryid}` });
};

  const subCategoryMenu = () => {
    return getSubCategoryList.map((item, index) => {
      return (
        <div
        className={classes.hover}
          style={{
            background: "#FFF",
            padding: 10,
            marginBottom: 5,
            outline: "none",
            width:'100%',
            cursor:'pointer'
          }}
          onClick={()=>handleProductSubCategory(item.subcategoryid)}
        >
          <div style={{ display: "flex", alignItems: "center",marginBottom:10 ,}} >
            <div style={{width:'100%'}}>{item.subcategoryname}</div>
            <div
              style={{
                display: "flex",
                width: '100%',
                flexDirection: "row-reverse",
              }}
            >
              <ArrowRightOutlinedIcon />
            </div>
          </div>
        </div>
      );
    });
  };


  const showAllCategories = () => {
    return getCategoryList.map((item, index) => {
      return (
        <Button
          value={item.id}
          style={{
            border: ".5px solid #F4F4F4",
            background: "#F4F4F4",
            padding: 5,
            margin: 10,
            width: 320,
            height: 80,
            outline: "none",
          }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
          <div style={{width:60,height:60}}>
          <img
              src={`${ServerURL}/images/${item.picture}`}
              width="100%"
              height="100%"
            />
          </div>
            <div
              style={{
                width: 200,
                textTransform: "uppercase",
                maxHeight: "5em",
                fontWeight: "500",
                fontSize: "1.2em",
                letterSpacing: ".15em",
                color: "#3e4152",

                marginLeft: 30,
              }}
            >
              {item.categoryname}
            </div>
            <div
              style={{
                color: "black",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <AddIcon />
            </div>
          </div>
        </Button>
      );
    });
  };


  return (
    <div>
      <div className={classes.headingName}>SHOP FROM CATEGORIES</div>
      <div className={classes.cardview}> <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 10,
            margin: 10,
            alignItems: "center",
            justifyContent: "center",
            flexWrap:'wrap'
          }}
        >
          {showAllCategories()}
        </div>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <div
            style={{
              border: ".5px solid #F4F4F4",
              background: "#F4F4F4",
              padding: 10,
              display: "flex",
              flexDirection: "column",
              margin: 10,
              width: 280,
              alignItems: "center",
              outline: "none",
            }}
          >
            {subCategoryMenu()}
          </div>
        </Menu>
</div>
    </div>
  );
}
