import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import { checkRequire, checkMobile, checkEmail, checkUserPassword } from '../Checks';
import Button from '@material-ui/core/Button';
import { postDataAndImage, getData, postData } from '../FetchNodeServices'
import { useSelector, useDispatch } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import swal from 'sweetalert';

import { FormatTextdirectionLToRSharp } from '@material-ui/icons';

const theme = createMuiTheme({
  status: {
    danger: '#e57373',
  },
  palette: {
    primary: {
      main: '#343434',
      light: '#ff9800',
      darker: '#e65100',
      contrastText: '#fff',
    },
    neutral: {
      main: '#e57373',
      contrastText: '#fff',
    },
  },
});
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px 10px',
    padding: 10,
    flexDirection: 'column'
  },
  paperStyle: {
    width: 'auto',
    margin: 10,
    padding: '5px 30px 5px 5px',
    height: 'auto',
    borderRadius: 10,
    backgroundColor: '#FFFFFF'
  },
  avator: {
    width: 'auto',
    height: 'auto',
    borderRadius: 10
  },
  gridStyle: {
    display: 'flex',
    //margin:5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 0
    //justifyContent:'center',
    //alignItems:'center'
  },
  red: {
    color: '#ffffff',
    backgroundColor: '#de011b',
    width: 55,
    height: 55,
    margin: '10px 30px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textfieldStyle:
  {
    margin: '4px 4px',
    // height:'30px'
  },
  divRow: {
    display: 'flex', flexDirection: 'row',
    fontSize: 12,
  },
  error: {
    color: 'red',
    position: "absolute",
    fontSize: 12,
    margin: '0px 4px'
  }

}));

export default function ShowProfile(props) {
  const classes = useStyles()

  const [getUserName, setUserName] = useState('')
  const [getFirstName, setFirstName] = useState('')
  const [getLastName, setLastName] = useState('')
  const [getEmail, setEmail] = useState('')
  const [getMobile, setMobile] = useState('')
  const [getMsg, setMsg] = useState('')
  const [getFirstNameMsg, setFirstNameMsg] = useState('')
  const [getLastNameMsg, setLastNameMsg] = useState('')
  const [getEmailMsg, setEmailMsg] = useState('')


  const handleEdit = async () => {
    setFirstNameMsg('')
    setLastNameMsg('')
    setEmailMsg('')

    var err = false;
    if (!checkRequire(getFirstName)) {
      err = true
      setFirstNameMsg("Please enter your First Name")
    }

    if (!checkRequire(getLastName)) {
      err = true
      setLastNameMsg("Please enter your Last Name")
    }

    if (getEmail.length == 0) {
      err = true
      setEmailMsg("Please enter your Email Id")
    }

    if (!checkEmail(getEmail)) {
      err = true
      setEmailMsg("Invalid Email Id")
    }

    if (!err) {
      let body = { mobileno: getMobile, username: getFirstName + " " + getLastName, emailaddress: getEmail }
      var result = await postData('users/updateprofile', body)
      if (result.RESULT) {
        // setMsg("Your Personal Information has been updated")
        localStorage.setItem('user', JSON.stringify(body))
        swal("Updated", "Your Personal Information has been updated", "success");
      }
      else {
        swal("Not Updated", " ", "error");
      }
    }
  }

  useEffect(function () {
    // CheckSession()
    fetchUserRec()
    // setMsg("")

  }, [getMsg])

  const CheckSession = () => {
    if (!localStorage.getItem('user')) {
      props.history.replace({ pathname: `/UserLogin` })
    }
  }

  const fetchUserRec = async () => {
    var user = JSON.parse(localStorage.getItem('user'))
    var name = user.username.split(' ')
    setFirstName(name[0])
    setLastName(name[1])
    setEmail(user.emailaddress)
    setMobile(user.mobileno)
  }

  return (
    <Grid container spacing={3} className={classes.gridStyle} >
      <ThemeProvider theme={theme}>
        <Grid item xs={12} sm={12} >
          <div style={{ fontSize: 20 }}> <b>Personal Information</b></div>
          <div style={{ margin: '4px 4px', fontSize: 14 }}><b>You can update your details.</b></div>
        </Grid>
        <Grid item xs={12} sm={12} >
          <TextField fullWidth value={getFirstName} id="outlined-size-small"
            variant="outlined" style={{ backgroundColor: '#f8f9f9' }}
            size="small" label="Your First Name" onChange={(event) => setFirstName(event.target.value)} />
          <div className={classes.error}> {getFirstNameMsg}    </div>
        </Grid>
        <Grid item xs={12} sm={12} >
          <TextField fullWidth value={getLastName} id="outlined-size-small"
            variant="outlined" style={{ backgroundColor: '#f8f9f9' }}
            size="small" label="Your Last Name" onChange={(event) => setLastName(event.target.value)} />
          <div className={classes.error}>{getLastNameMsg}</div>
        </Grid>
        <Grid item xs={12} sm={12} >
          <TextField fullWidth value={getMobile}
            id="outlined-size-small"
            variant="outlined" style={{ backgroundColor: '#f8f9f9' }}
            size="small" label="Your Mobile No" />
        </Grid>
        <Grid item xs={12} sm={12} >
          <TextField fullWidth value={getEmail}
            id="outlined-size-small"
            variant="outlined" style={{ backgroundColor: '#f8f9f9' }}
            size="small" label="Your Email-id" onChange={(event) => setEmail(event.target.value)} />
          <div className={classes.error}>{getEmailMsg}</div>
        </Grid>
        <Grid item xs={12} sm={12} >
          <Button fullWidth variant="contained" color="primary" onClick={() => handleEdit()} >
            <b> Update</b>
          </Button>
        </Grid>
        {/* 
        <Grid item xs={12} sm={12} className={classes.container}>
          <b>{getMsg}</b>
        </Grid> */}
      </ThemeProvider>
    </Grid>

  )
}


