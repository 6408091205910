/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getData, ServerURL } from '../FetchNodeServices';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Button from '@material-ui/core/Button';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Hidden from "@material-ui/core/Hidden";
const useStyles = makeStyles((theme) => ({

    cardview: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center'
    },
    scardview: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    lroot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        overflow: 'hidden',
    },
    gridList: {
        width: '100%',
        height: '100%',
    },
    hover: {
        "&:hover": {
            // background: "#de011b",
            backgroundPosition: 'left',
            //background: linear-gradient"('to left, salmon 50%', 'lightblue 50%'), 'right'",
        },
        cursor: 'pointer'
    },
    sevenshades: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 0,
        marginBottom: 20,
        fontSize: 35,
        color: "#e74c3c",
        textAlign: 'center',
        fontWeight: "bold",
        letterSpacing: "2.5px",
        fontFamily: 'Calibri',
        backgroundColor: 'transparent'
    },
    sevenshades2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 0,
        marginBottom: 20,
        fontSize: 25,
        color: "#e74c3c",
        textAlign: 'center',
        fontWeight: "bold",
        letterSpacing: "2.5px",
        fontFamily: 'Calibri',
        backgroundColor: 'transparent'
    },

    headingName: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        // fontSize: 30,
        color: "#212121",
        textAlign: 'center',
        fontWeight: "bold",
        letterSpacing: "2.5px",
        fontFamily: 'Calibri',
        backgroundColor: 'transparent'
    },
    divider: {
        margin: '15px 10px',
        backgroundColor: '#e74c3c',
        height: 3,
        width: 100,
    },

}));

export default function Dealsoftheday(props) {
    const classes = useStyles();
    const [getDeals, setDeals] = useState([])


    useEffect(function () {
        fetchDealsoftheday()
    }, [])


    const fetchDealsoftheday = async () => {
        // var body = {}
        var list = await getData('dealsoftheday/displayjson')
        console.log("deals", list.data)
        setDeals(list.data)
        // alert(JSON.stringify(list))
    }



    const Deals = () => {
        return (
            getDeals.map((item, key) => {

                return (
                    <div className={classes.scardview}>
                        <Paper className={classes.scardview} elevation={0} onClick={() => handleShowProductView(item.productid, item.modelid)} >
                            <div style={{ padding: 20, cursor: 'pointer', }}>
                                <img src={`${ServerURL}/images/${item.dealspicture}`} style={{ width: '50%', height: '50%', borderRadius: 5 }} />
                            </div>
                        </Paper>
                    </div>
                )
            })
        )
    }

    const DealsWrap = () => {
        return (
            getDeals.map((item, key) => {

                return (
                    // <div className={classes.scardview} >
                    // <div className={classes.scardview}>
                    <Paper className={classes.scardview} elevation={0} onClick={() => handleShowProductView(item.productid, item.modelid)} >

                        <div class="container-block">
                            <img src={`${ServerURL}/images/${item.dealspicture}`} style={{ width: '100%', height: '50%', borderRadius: 0, cursor: 'pointer' }} />
                            <div class="inner-block">
                                <div class="slider-top-right"></div>
                            </div>
                            <div class="block-content">
                                <h4>{item.productname}</h4>
                            </div>
                        </div>
                    </Paper>
                    // </div>
                    // </div>
                )
            })
        )
    }

    const DealsWrapNew = () => {
        return (
            getDeals.map((item, key) => {
                return (
                    // <div className={classes.scardview} >
                    // <div className={classes.scardview}>
                    <figure class="snip1104"><img src={`${ServerURL}/images/${item.dealspicture}`} style={{ width: '100%', height: '50%', borderRadius: 5 }} />
                        <figcaption>
                            <h2>{item.productname} </h2>
                        </figcaption>
                        <a href="#"></a>
                    </figure>
                    // </div>
                    // </div>
                )
            })
        )
    }

    const handleShowProductView = (productid, modelid) => {
        props.history.push({ pathname: `/ProductView/${productid}/${modelid}` })
    }

    var sliderRef = createRef()


    const settings = {
        autoplay: true,
        dots: false,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,

                }
            },
            {
                breakpoint: 890,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 610,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 415,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true
                }
            }
        ]

    };


    const gotoNext = () => {
        sliderRef.current.slickNext()
    }
    const gotoPrev = () => {
        sliderRef.current.slickPrev()
    }

    return (
        <>
            {/* <div > */}
            <div className={classes.scardview} style={{ margin: 10 }}>
                {getDeals.length != 0 ? <Paper elevation={0} style={{ width: '95%', }} >
                    <div className={classes.headingName}>
                        <Divider className={classes.divider} />
                        <Hidden mdDown>
                            <h1>DEALS OF THE DAY</h1>
                        </Hidden>
                        <Hidden mdUp>
                            <h6>DEALS OF THE DAY</h6>
                        </Hidden>
                        <Divider className={classes.divider} />
                    </div>
                    {/* <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', }}>
                            {DealsWrapNew()}
                        </div> */}
                    {getDeals.length > 4 ? <div className={classes.scardview}><div style={{}} onClick={() => gotoPrev()} >
                        <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
                    </div>
                        <div style={{ width: '90%', }}>
                            <Slider {...settings} ref={sliderRef} >
                                {DealsWrap()}
                            </Slider>
                        </div>
                        <div onClick={() => gotoNext()}>
                            <KeyboardArrowRightIcon style={{ fontSize: 40 }} />
                        </div></div> :
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', }}>
                            {DealsWrapNew()
                            }</div>}
                </Paper> : <></>}
            </div>
            {/* </div> */}
            <div class="parallax">
                <div className={classes.scardview} style={{ width: '50%', }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '20%' }}>
                        <div style={{ fontSize: 60, fontWeight: 700, color: '#000' }}>
                            <Hidden mdDown>

                                <img
                                    src="/images/the7shades.png"
                                    height={100}
                                    width={300}
                                />
                                <span className={classes.sevenshades}>
                                    Special Offer
                                </span>
                            </Hidden>
                            <Hidden mdUp>

                                <img
                                    src="/images/the7shades.png"
                                    height={50}
                                    width={140}
                                />
                                <span className={classes.sevenshades2}>
                                    Special Offer
                                </span>
                            </Hidden>
                        </div>

                        <Button variant="outlined" size="large" style={{ border: '2px solid #e74c3c', color: "#e74c3c", fontWeight: 'bold', borderRadius: 0 }} color="primary">
                            Read More <ArrowRightAltIcon />
                        </Button>
                    </div>
                </div>
            </div>

        </>
    );
}



