import React, { useEffect, useState, createRef } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import { IconButton, } from '@material-ui/core';
import { getData, postData, ServerURL } from '../FetchNodeServices'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Image from 'react-image-resizer'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import StarIcon from "@material-ui/icons/Star";
import "react-placeholder/lib/reactPlaceholder.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import "./style.css";
import Divider from "@material-ui/core/Divider";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import SearchIcon from "@material-ui/icons/Search";


const useStyles = makeStyles((theme) => ({


  toproot: {
    //alignItems:'center',
    justifyContent: 'flex-start',
    display: 'flex',
    padding: 10,
    width: 250,
    height: 310,
    margin: 10,
    padding: 10,
    // border:'2px solid #dcdde1',
    borderRadius: 10,
    flexDirection: 'column',

  },
  productAction: {
    display: "flex",
    justifyContent: "flex-start",
    //alignItems:'center',
    //height: 320,
    //borderRadius: 10,
    margin: 7,
    //padding: 10,
    // border: "2px solid #dcdde1",
    flexDirection: "column",
    color: "#747d8c",
    //cursor: "pointer",
  },
  ImageView: {
    // width: 250,
    // height: 250,
    display: "flex",
    border: '1px solid #f1f2f6',
    // alignItems: "center",
    // justifyContent: "center",
    //padding: 5,
    "&:hover": {
      // background: "#747d8c",
      // transform: "scale(1.035)",
      // transition: "all 0.1s ease 0s",
    },
    // backgroundColor: "#f1f2f6",
    borderRadius: 5,
  },
  cartbtn: {
    padding: 2,
    height: 30,
    margin: '5px 0px',
    backgroundColor: '#747d8c',
    color: '#ffffff',
    "&:hover": {
      background: "#747d8c",
      transition: 'all 0.5s ease 0s',
    },

  },
  cardview: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scardview: {
    display: "flex",
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center",
    // flexWrap: "wrap",
    padding: 10,
  },

  headingName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 30,
    fontSize: 35,
    color: "#747d8c",
    textAlign: 'center',
    fontWeight: "bold",
    letterSpacing: "2.5px",
    fontFamily: 'Calibri',
    backgroundColor: 'transparent'
  },
  divider: {
    margin: 15,
    backgroundColor: '#e74c3c',
    height: 3,
    width: 100,
  },
  text: {
    display: 'flex',
    paddingTop: 10,

  },
  hoverimg: {
    "&:hover": {
      //background: "#de011b",
      //background: "#000000",
      transform: "rotateY(0deg)",
      transition: "all 1s ease 1s",
      color: "#fff",
    },
  },

}));

export default function TopProducts(props) {
  const classes = useStyles();
  const [getTopProducts, setTopProducts] = useState([])
  const [getState, setState] = useState(false)
  const modelid = props.modelid
  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(value);


  useEffect(function () {
    fetchTopProducts()
  }, [])

  const fetchTopProducts = async () => {
    var list = await postData('product1/productsbymodelid', { modelid: modelid })
    var arr = list.sort((a, b) => (b.price - b.offerprice) - (a.price - a.offerprice))
    list = []
    arr.map((item, key) => {
      if (key < 20) {
        list.push(item)
      }
    })
    setTopProducts(list)
    setState(true)
  }


  //Show Products //
  const ShowTopProducts = () => {
    return (

      getTopProducts.slice(0, 20).map((item, key) => {
        var save = item.price - item.offerprice
        var o = 1
        var color = 'green'
        if (item.status == 'Coming Soon' || item.status == 'Pre Booking') {
          o = 0.5
          if (item.status == 'Coming Soon') {
            color = 'red'
          }
        }
        var discount = Math.ceil((save / item.price) * 100)
        if (save > 0) {
          return (
            <div className={classes.productAction}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} > */}
                <Paper elevation={3}>
                  {/* <div class="show" > */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-start",
                      cursor: 'pointer',
                      padding: 10
                    }}

                    onClick={() => handleShowProductView(item.productid, item.modelid)}
                    className={classes.hover1img}
                  >
                    {/*<img src={`${ServerURL}/images/${item.picture}`} width="100%" height="100%" />*/}
                    <Hidden mdUp>
                      <div style={{ position: 'absolute', zIndex: 1, opacity: 1, }}>
                        <img src="/images/dis.png" />
                        <div style={{ position: 'absolute', top: 9, color: 'rgb(255, 255, 255)', fontSize: 12, fontWeight: 'bold', left: 12, textAlign: 'center', cursor: 'pointer' }}>
                          <p>{discount}% </p><p>Off</p>
                        </div>
                      </div>
                      <img
                        width={250}
                        height={"100%"}
                        // style={{ opacity: o, borderRadius: 100 }}
                        alt={item.productname}
                        src={`${ServerURL}/images/${item.picture}`}
                      // onClick={() => handleShowProductView(item.productid)}
                      />
                    </Hidden>
                    <Hidden smDown>
                      <div style={{ position: 'absolute', zIndex: 1, opacity: 1, }}>
                        <img src="/images/dis.png" />
                        <div style={{ position: 'absolute', top: 9, color: 'rgb(255, 255, 255)', fontSize: 12, fontWeight: 'bold', left: 12, textAlign: 'center', cursor: 'pointer' }}>
                          <p>{discount}% </p><p>Off</p>
                        </div>
                      </div>
                      <img
                        width={231}
                        height={"100%"}
                        // style={{ opacity: o,borderRadius: 100}}
                        alt={item.productname}
                        src={`${ServerURL}/images/${item.picture}`}
                      // onClick={() => handleShowProductView(item.productid)}
                      />
                    </Hidden>

                    <div id="axis" style={{ flexDirection: 'column', display: 'flex', }}>
                      <span style={{ position: 'absolute', marginLeft: -40, marginTop: 40, cursor: 'pointer' }} class="mousehover"> <ShoppingCart onClick={() => handleShowProductView(item.productid, item.modelid)} style={{ color: '#212121' }} /><FavoriteBorderIcon style={{ color: '#212121' }} /><SearchIcon style={{ color: '#212121' }} /><img class="object van move-left" /></span>
                    </div>
                  </div>
                  {/* </div> */}
                  <div

                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderTop: "1px solid #dcdde1",

                      padding: 10,
                      maxWidth: '250px'
                    }}
                  >
                    <div style={{ fontSize: 14, padding: "5px 0px", borderRadius: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <span
                        className="price_tag" style={{ backgroundColor: '#212121', color: '#fff' }}
                      >
                        {numberFormat(item.offerprice)}

                      </span>
                      <span>
                        <small>
                          MRP &nbsp;
                          <b><del>{numberFormat(item.price)}</del></b>&nbsp; <br />

                        </small>
                        {/* {discount > 5 ? (
                            <span style={{ color: "red", fontSize: 10,marginLeft:90,  position: 'absolute' }}>
                              {discount}% off
                            </span>
                          ) : (
                            <></>
                          )} */}
                      </span>
                    </div>
                    <div
                      style={{ fontSize: 15, fontWeight: "bold", padding: "5px 0px 3px" }}
                    >
                      {item.brandname}
                    </div>
                    <div style={{ fontSize: 14, padding: "3px 0px" }}>
                      {item.productname.length >= 30
                        ? item.productname.toString().substring(0, 27) + "..."
                        : item.productname}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 0
                    }}
                  >
                    {item.status == "Pre Booking" ? (
                      <>
                        <Button
                          variant="contained"
                          fullWidth
                          style={{
                            height: 30,
                            padding: 5,
                            backgroundColor: "#212121",
                            color: "#ffffff",
                            borderRadius: 50
                          }}
                          onClick={() => handleShowProductView(item.productid, item.modelid)}
                        >
                          Pre Book Now &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        </Button>
                      </>
                    ) :
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          height: 40,
                          padding: 10,
                          backgroundColor: "#212121",
                          color: "#ffffff",
                          fontSize: 15,
                          letterSpacing: "0px",
                          fontWeight: "bold",
                          // width: 250,
                          borderRadius: 0
                        }}
                        onClick={() => handleShowProductView(item.productid, item.modelid)}
                      >
                        Add to Cart &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <big>
                          <ShoppingCart
                            style={{ marginTop: 6, backgroundColor: "transparent" }}
                          />{" "}
                        </big>
                      </Button>
                    }
                  </div>
                </Paper>
                {/* </Grid>
              </Grid> */}
              </div>
            </div>
          );
        }
      }))
  }

  const state = () => {
    setState(true)
  }
  const handleShowProductView = (productid, modelid) => {
    props.history.push({ pathname: `/ProductView/${productid}/${modelid}` })
  }

  var sliderRef = createRef()

  const settings = {
    //autoplay: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,

        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,

        }
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };



  const gotoNext = () => {
    sliderRef.current.slickNext()
  }
  const gotoPrev = () => {
    sliderRef.current.slickPrev()
  }


  return (<>

    <div className={classes.scardview}>
      <Paper elevation={0} style={{ width: '100%', backgroundColor: 'transparent' }} >
        {getState && getTopProducts.length > 1 ? <> <div className={classes.headingName}>
          <Hidden smDown>
            <Divider className={classes.divider} />
            Similar Products
            <Divider className={classes.divider} />
          </Hidden>
          <Hidden mdUp>
            <Divider className={classes.divider} />
            <div style={{ fontSize: 20 }}>Similar Products</div>
            <Divider className={classes.divider} />
          </Hidden>
        </div>
          <div >
            {getTopProducts.length > 5 ?
              <div className={classes.scardview}>
                <div style={{ marginLeft: 0, }} onClick={() => gotoPrev()} >
                  <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
                </div>
                <div style={{ width: '90%', }}>
                  <Slider {...settings} ref={sliderRef} >
                    {ShowTopProducts()}
                  </Slider>
                </div>
                <div style={{ marginRight: 0, }} onClick={() => gotoNext()}>
                  <KeyboardArrowRightIcon style={{ fontSize: 40 }} />
                </div>
              </div> : <div className={classes.cardview}>
                {ShowTopProducts()}</div>}</div> </> :
          <></>}
      </Paper>
    </div>
  </>
  );
}



