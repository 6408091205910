import React, { useEffect, useState, createRef } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import { getData, postData, ServerURL } from "../FetchNodeServices";
import Avatar from "@material-ui/core/Avatar";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  divAction: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    // padding: 10,
    // margin:10,
    // "&:hover": {
    //   // background: "#de011b",
    //   // transform: "scale(1.10)",
    //   // transition: "all 0.5s ease 0s",
    // },
    cursor: "pointer",
    // width:160,
    // height:160,
    // borderRadius:100,
    color: '#7ed6df',
  },

  cardview: {
    display: "flex",
    //flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  headingName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
    // fontSize: 30,
    color: "#212121",
    textAlign: 'center',
    fontWeight: "bold",
    letterSpacing: "2.5px",
    fontFamily: 'Calibri',
    backgroundColor: 'transparent'
  },
  divider: {
    margin: '5px 5px',
    backgroundColor: '#e74c3c',
    height: 3,
    width: 100,
  },
  large: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  hover: {
    "&:hover": {
      //transform: "scale(0.5)",
      //margin:10,
      //transform: "rotateY(360deg)",
      //transition: "all 0.1s ease 0s",
      boxShadow: ('0 4px 8px 0 rgba(0, 0, 0, 0.2)', '0 6px 20px 0 rgba(0, 0, 0, 0.19)')
    },
  },
}));

export default function ShowTopCategories(props) {
  const classes = useStyles();
  const [getListAd, setListAd] = useState([]);

  useEffect(function () {
    fetchSubCategory();
  }, []);

  const fetchSubCategory = async () => {
    var body = {};
    var list = await postData("models/modelad", body);
    setListAd(list);
  };

  //Ad Categories//
  const ShowTopCategory = () => {

    return getListAd.map(function (item, key) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Paper elevation={3} style={{ margin: 20, padding: 10 }}>
            <div
              className={
                classes.divAction
              }
              onClick={() => handleProductModel(item.modelid)}
            >
              <img
                src={`${ServerURL}/images/${item.picture}`}
                style={{ width: 160, height: 160, borderRadius: 100 }}
              />
            </div>
            <div class="catefont">
              {item.modelname}
            </div>
          </Paper>
        </div >
      );
    });
  };

  const handleProductModel = (modelid) => {
    props.history.push({ pathname: `/ViewListofModelProducts/${modelid}` });
  };

  var sliderRef = createRef()
  const settings = {
    // autoplay: true,
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,

        }
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 415,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]

  };

  const gotoNext = () => {
    sliderRef.current.slickNext()
  }
  const gotoPrev = () => {
    sliderRef.current.slickPrev()
  }

  return (
    <div className={classes.cardview} style={{ margin: 10 }}>
      {getListAd.length != 0 ? <Paper elevation={0} style={{ width: '96%', }} >
        <div className={classes.headingName}>
          <Divider className={classes.divider} />
          <Hidden mdDown>
            <h1>CATEGORIES TO CART</h1>
          </Hidden>
          <Hidden mdUp>
            <h6>CATEGORIES TO CART</h6>
          </Hidden>
          <Divider className={classes.divider} />
        </div>
        {getListAd.length > 5 ? <div className={classes.cardview}><div style={{}} onClick={() => gotoPrev()} >
          <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
        </div>
          <div style={{ width: '90%', }}>
            <Slider {...settings} ref={sliderRef} >
              {ShowTopCategory()}
            </Slider>
          </div>
          <div style={{}} onClick={() => gotoNext()}>
            <KeyboardArrowRightIcon style={{ fontSize: 40 }} />
          </div></div> : <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', }}>
          {ShowTopCategory()}</div>}
      </Paper> : <></>}
    </div>
  );
}
