import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import UpdateIcon from '@material-ui/icons/Update';
import PaymentIcon from '@material-ui/icons/Payment';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },

    subdiv: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        border:'1px solid #e2e2e2',
        // backgroundColor: "#EEEEEE",
        borderRadius: '0px',
        flexWrap: 'wrap',
        flexFlow: 'column',
        width: '95%',
        margin: '30px 5px 20px',
        fontFamily:'Calibri',
        fontWeight:500
    },

    heading: {
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTopLeftRadius:6,
        borderBottomLeftRadius:6,
        padding: 10,
        backgroundColor:'#32AEB1',
    },
    h3:{
        color:'#fff' 
    },
    promise: {
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color:'#666666',
        fontSize:16,
        margin:'5px 10px',
        padding:'20px 0px 20px 0px',
        width:240
    },
    h4: {
        margin:0,
        color:'#666666',
        fontWeight:600,
        fontSize:20,
        paddingBottom:5
    },
    h5: {
        margin:0,
        letterSpacing:1,
        fontSize:14,
        color:'#95a5a6',
        fontWeight:'normal'
    },
    divider: {
      height: 60,
      margin: 10,
    },
}));

export default function PromiseMenu() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.subdiv}>
                {/* <div className={classes.heading}>
                    <h3 className={classes.h3}>OUR PROMISE</h3>
                </div> */}
                <div className={classes.promise}>
                    <div>
                        <LocalShippingOutlinedIcon color='primary' style={{fontSize:60,color:'#212121'}} />
                    </div>
                    <Divider className={classes.divider} orientation="vertical" />
                    <div>
                        <h4 className={classes.h4}>Free Shipping</h4>
                        <h5 className={classes.h5}>Free Shipping World Wide</h5>
                    </div>
                </div>
                <div className={classes.promise}>
                    <div>
                        <UpdateIcon color='primary' style={{fontSize:60,fontWeight:100,color:'#212121'}} />
                    </div>
                    <Divider className={classes.divider} orientation="vertical" />
                    <div>
                        <h4 className={classes.h4}>24 X 7 Service</h4>
                        <h5 className={classes.h5}>Online Service For New Customer</h5>
                    </div>
                </div>
                <div className={classes.promise}>
                    <div>
                        <RecordVoiceOverOutlinedIcon color='primary' style={{fontSize:60,color:'#212121'}} />
                    </div>
                    <Divider className={classes.divider} orientation="vertical" />
                    <div>
                        <h4 className={classes.h4}>Festival Offer</h4>
                        <h5 className={classes.h5}>New Online Special Festival Offer</h5>
                    </div>
                </div>
                <div className={classes.promise}>
                    <div>
                        <PaymentIcon color='primary' style={{fontSize:60,color:'#212121'}} />
                    </div>
                    <Divider className={classes.divider} orientation="vertical" />
                    <div>
                        <h4 className={classes.h4}>Online Payment</h4>
                        <h5 className={classes.h5}>Contrary To Popular Belief.</h5>
                    </div>
                </div>
                {/* <div className={classes.promise}>
                    <div>
                        <VerifiedUserIcon color='primary' style={{fontSize:50,color:'#8bc34a'}} />
                    </div>
                    <Divider className={classes.divider} orientation="vertical" />
                    <div>
                        <p className={classes.span}>Minimum 2 Years Warranty*</p>
                    </div>
                </div>
                <div className={classes.promise}>
                    <div>
                        <StoreMallDirectoryIcon color='primary' style={{fontSize:50,color:'#ff9800'}} />
                    </div>
                    <Divider className={classes.divider} orientation="vertical" />
                    <div>
                        <p className={classes.span}>Drive through/Free Pickup in Stores</p>
                    </div>
                </div>
                <div className={classes.promise}>
                    <div>
                        <LocalShippingIcon color='primary' style={{fontSize:50,color:'#009688'}} />
                    </div>
                    <Divider className={classes.divider} orientation="vertical" />
                    <div>
                        <p className={classes.span}>Home Delivery at Your Doorstep</p>
                    </div>
                </div>
                <div className={classes.promise}>
                    <div>
                        <SwapHorizontalCircleIcon color='primary ' style={{fontSize:50,color:'#d500f9'}} />
                    </div>
                    <Divider className={classes.divider} orientation="vertical" />
                    <div>
                        <p className={classes.span}>Easy 90 Days Return</p>
                    </div>
                </div> */}
            </div>
        </div>
    );
}