import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import { checkRequire, checkMobile, checkEmail, checkUserPassword } from '../Checks';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Header from '../clientview/Header';
import { postDataAndImage, getData, postData } from '../FetchNodeServices'
import { useSelector, useDispatch } from 'react-redux';
import MyAccount from '../clientview/MyAccount';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Icon from '@material-ui/core/Icon';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Divider from "@material-ui/core/Divider";
import Footer from "../clientview/Footer"
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  input: {
    fontSize: 15,
    color: '#757575',
    fontFamily: 'Calibri'
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '5px 10px',
    padding: 10,
    justifyContent: 'space-between',
  },
  divider: {
    width: '100%'
  },
  IconBorder: {
    border: '3px solid #000',
    padding: 0,
    borderRadius: 50,
    color: '#000',
  },

  paperStyle: {
    //width: window.innerWidth * 0.5,
    // width:330,
    //margin: 10,
    padding: '10px 10px 10px 10px',
    height: 'auto',
    borderRadius: 10,
    backgroundColor: '#FFFFFF',
    border: '0.5px solid #f5f6fa'
  },
  avator: {
    width: 'auto',
    height: 'auto',
    borderRadius: 10
  },
  gridStyle: {
    display: 'flex',
    margin: 5,
    padding: 20,
  },
  red: {
    color: '#fff',
    backgroundColor: '#747d8c',
    width: 55,
    height: 55,
    margin: '10px 30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  IconButton: {
    color: '#fff',
    backgroundColor: '#000',
    margin: '10px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
  },

  hover: {
    "&:hover": {
      transform: "scale(1)",
      transition: "all 0.5s ease 0s",
      backgroundColor: '#288E91',
      //borderRadius:100,
    },
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textfieldStyle:
  {
    margin: '4px 0px',
    // height:'30px'
  },
  divRow: {
    display: 'flex', flexDirection: 'row',
    fontSize: 12,
  },
  error: {
    color: 'red',
    //position:"absolute",
    fontSize: 12,
    margin: '0px 4px'
  }
}));
const theme = createMuiTheme({
  status: {
    danger: '#e57373',
  },
  palette: {
    primary: {
      main: '#212121',
      light: '#ff9800',
      darker: '#e65100',
      contrastText: '#fff',
    },
    neutral: {
      main: '#e57373',
      contrastText: '#fff',
    },
  },
});
function UserLogin(props) {
  const classes = useStyles()
  const [getMobile, setMobile] = useState('')
  const [getErrMob, setErrMob] = useState('')
  const [getFirstName, setFirstName] = useState('')
  const [getLastName, setLastName] = useState('')
  const [getEmail, setEmail] = useState('')
  const [getPassword, setPassword] = useState({ password: '', showPassword: false })
  const [getOPassword, setOPassword] = useState({ password: '', showPassword: false })
  const [getConfirmPassword, setConfirmPassword] = useState({ password: '', showPassword: false })
  const [getState, setState] = useState(true)
  const [getMobileState, setMobileState] = useState(true)
  const [checked, setChecked] = React.useState(false);
  const [getOTP, setOTP] = useState('')
  const [getErrFirst, setErrFirst] = useState('')
  const [getErrLast, setErrLast] = useState('')
  const [getErrEmail, setErrEmail] = useState('')
  const [getErrPassword, setErrPassword] = useState('')
  const [getErrCPwd, setErrCPwd] = useState('')
  const [getErrOTP, setErrOTP] = useState('')
  const [getMsg, setMsg] = useState('')
  const [getGOTP, setGOTP] = useState('')
  const [getUser, setUser] = useState('')
  const [getSMsg, setSMsg] = useState('')
  const [snackbar, setSnackBar] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = snackbar;
  const dispatch = useDispatch()

  useEffect(function () {
    window.scrollTo(0, 0)
  }, [])

  const CheckUser = async () => {
    var body = { mobileno: getMobile, password: getPassword || getPassword == 0 }
    var result = await postData('users/checkLogin', body)
    if (!result.RESULT) {
      // alert()
      // setSnackBar({ open: true });
      setErrOTP('Please enter OTP / Password')
      setSMsg(result.msg)
      // handleStatus()
    }
    else {
      setUser(result.data)
      localStorage.setItem('user', JSON.stringify(result.data))
      dispatch({ type: "ADD_USER", userdata: [getMobile, result.data] })
      props.history.replace({ pathname: `/ShowCartWithAddress` }, { 'data': result.data, 'mobileno': getMobile })
    }
  }

  const handleClose = () => {
    setSnackBar({ open: false });
  };

  const UserSetLogin = () => {
    localStorage.setItem('user', JSON.stringify(getUser))
    dispatch({ type: "ADD_USER", userdata: [getMobile, getUser] })
    props.history.replace({ pathname: `/ShowCartWithAddress` }, { 'data': getUser, 'mobileno': getMobile })
  }

  const otpCallback = async () => {
    var otp = parseInt(Math.random() * 999) + 1000
    var body = { 'otps': otp, mbl: getMobile }
    var result = await postData('api/sendotp', body)
    if (result.result == 'Success') {
      setGOTP(otp)
      return otp
    }
    else {
      return false
    }
  }


  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleMobileVerify = () => {
    setErrMob('')
    setErrPassword('')
    if (!checkRequire(getOTP)) {
      setErrOTP('Please enter OTP')
    }
    else {
      //console.log('otp',getGOTP)
      if (getGOTP == getOTP) {
        UserSetLogin()
        //alert('OTP matched now u can navigate to dashboard')
        // props.history.push({pathname:`/MyAccount`},getMobile);
      }
      // else if (getPassword) {
      //   // UserSetLogin()
      //   alert(getPassword)
      // }
      else {
        // setErrOTP('Invalid OTP') 
        CheckUser()
      }
    }
  }

  ////Handle User Verification////
  const handleMobile = async () => {
    var err = false
    if (checkRequire(getMobile)) {
      if (!checkMobile(getMobile)) {
        err = true
        setErrMob("Please enter valid Mobile Number!")
      }
      else {
        setErrMob('')
      }
    }
    if (!checkRequire(getMobile)) {
      err = true
      setErrMob("Please Enter Mobile Number!")
    }
    if (!err) {
      var otp = otpCallback()
      setGOTP(otp)
      var body = { mobileno: getMobile }
      var result = await postData('users/checkUser', body)
      if (!result.RESULT) {
        setState(false)
      }
      else {
        setUser(result.data)
        setMobileState(false)
        //dispatch({type:"REMOVE_USER"})
      }
    }
  }
  /////////////////////////////////////////////////
  const handleChangePassword = (event) => {
    setPassword({ password: event.target.value, showPassword: getPassword.showPassword });
  };

  const handleChangePassword2 = (event) => {
    setOPassword({ password: event.target.value, showPassword: getOPassword.showPassword });
    setPassword(event.target.value)
    setOTP(event.target.value)
  };

  const handleClickShowPassword = () => {
    setPassword({ password: getPassword.password, showPassword: !getPassword.showPassword });
    setOPassword({ password: getOPassword.password, showPassword: !getOPassword.showPassword });
  };


  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword({ password: event.target.value, showPassword: getConfirmPassword.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setConfirmPassword({ password: getConfirmPassword.password, showPassword: !getConfirmPassword.showPassword });
  };

  const handleResendOtp = async () => {
    var otp = parseInt(Math.random() * 999) + 1000
    var body = { 'otps': otp, mbl: getMobile }
    var result = await postData('api/sendotp', body)
    setGOTP(otp)
    // alert(otp)
    // var body = { mobileno: getMobile, }
    // var result = await postData('users/checkUser', body)
    // if (!result.RESULT) {
    //   setState(false)
    // }
    // else {
    //   setUser(result.data)
    //   setMobileState(false)
    //   //dispatch({type:"REMOVE_USER"})
    // }
  }

  const handleChangeMobile = async () => {
    // var otp = otpCallback()
    setMobileState(true)
    setOTP('')
    setOPassword('')
    setErrOTP('')
    // setGOTP(otp)
    // setState(true)
    // setErrMob('')
    // setMobile('')
    // setErrEmail('')
    // setFirstName('')
    // setLastName('')
    // setErrEmail('')
    // setOTP('')
    // setMsg('')
    // setErrFirst('')
    // setErrLast('')
    // setErrOTP('')
  }

  ///////////////User Registration/////////////////////
  const handleVerify = async () => {
    var err = false
    if (!checkRequire(getFirstName)) {
      err = true
      setErrFirst('Please enter Your first name')
    }
    else {
      setErrFirst('')
    }

    if (!checkRequire(getLastName)) {
      err = true
      setErrLast('Please enter your last name')
    }
    else {
      setErrLast('')
    }

    if (!checkRequire(getEmail)) {
      err = true
      setErrEmail('Please enter your email address')
    }
    if (checkRequire(getEmail)) {
      if (!checkEmail(getEmail)) {
        err = true
        setErrEmail('Please enter valid email address')
      }
      else {
        setErrEmail('')
      }
    }


    if (!checkRequire(getPassword.password)) {
      err = true
      setErrPassword('Please enter password')
    }
    if (checkRequire(getPassword.password)) {
      if (!checkUserPassword(getPassword.password)) {
        err = true
        setErrPassword('Password must be alphanumeric and between 8-20 characters! Allowed special characters are !@#$%^&*')
      }
      else {
        setErrPassword('')
      }
    }


    if (!checkRequire(getConfirmPassword.password)) {
      err = true
      setErrCPwd('Please confirm your password')
    }
    if (checkRequire(getConfirmPassword.password)) {
      if (getConfirmPassword.password != getPassword.password) {
        err = true
        setErrCPwd("Password doesn't match")
      }
      else {
        setErrCPwd('')
      }
    }

    if (!checkRequire(getOTP)) {
      err = true
      setErrOTP('Please enter OTP')
    }
    else {
      setErrOTP('')
    }

    if (!err) {

      if (getGOTP == getOTP) {
        let body = { mobile: getMobile, name: getFirstName + " " + getLastName, email: getEmail, password: getPassword.password, loginstatus: 'Login' }
        var result = await postData('users/addnewrecord', body)
        if (result.RESULT) {
          CheckUser()
          //Checklogin()
        }
        else {
          // alert("Server Error..Please Try again")
          setSMsg(result.msg)
          setSnackBar({ open: true });
          // handleStatus()
        }
      }
      else {
        setErrOTP('Invalid OTP')
      }
    }
  }
  //////////////////////////////////////////////////////////

  //Sign In Mobile ///

  const SignInMobile = () => {
    return (

      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          onClose={handleClose}
          message={getSMsg}
        // key={vertical + horizontal}
        />
        <ThemeProvider theme={theme}>
          <Grid container spacing={1}>
            <Grid item xs={10} sm={10}>
              <div style={{ fontSize: 30, color: '#000' }}> <b>Sign in</b></div>
              <div style={{ color: '#666666', fontSize: 12 }}><b>Sign in to access your Orders, Offers and Wishlist.</b></div>
            </Grid>
            <Grid item xs={2} sm={2} >
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {/* <PersonAddIcon variant="filled" aria-label="menu" size="medium" className={classes.IconBorder} style={{fontSize:49}}/> */}
                <AccountCircleIcon variant="filled" aria-label="menu" className={classes.IconBorder} style={{ fontSize: 59 }} />
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <AccountCircleIcon variant="filled" aria-label="menu" className={classes.IconBorder} style={{ fontSize: 59 }} />

              </div>
              <div style={{ fontSize: 30, color: '#000' }}> <b>Sign in</b></div>
              <div style={{ color: '#666666', fontSize: 14 }}>Sign in to access your Orders, Offers and Wishlist.</div>
            </Grid> */}

            <Grid item xs={12} sm={12} >

              {getMobileState ? <FormControl fullWidth size="small" variant="outlined" style={{ marginTop: 20, }}>
                <InputLabel htmlFor="outlined-adornment-amount"
                >Enter Your Mobile no.</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  size="small"
                  type="text"
                  title="starting with 9 or 8 or 7 or 6 and other 9 digit using any number"
                  pattern="[6789][0-9]{9}"
                  error={getErrMob}
                  onChange={(event) => {
                    setMobile(event.target.value);
                    if (event.target.value.length > 10) {
                      setErrMob(true)
                    }
                    else {
                      setErrMob(false)
                    }
                  }}
                  // onChange={(event) => setMobile(event.target.value)}
                  startAdornment={<InputAdornment position="start">+91 |</InputAdornment>}
                  labelWidth={160}
                  className={classes.input}
                />
              </FormControl> : <FormControl fullWidth size="small" variant="outlined" style={{ marginTop: 20, }}>
                <InputLabel htmlFor="outlined-adornment-amount">Enter Your Mobile no.</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  size="small"
                  disabled
                  onChange={(event) => setMobile(event.target.value)}
                  startAdornment={<InputAdornment position="start">+91 |</InputAdornment>}
                  labelWidth={160}
                  className={classes.input}
                />
              </FormControl>}
              <div className={classes.error}><small>{getErrMob}</small></div>
            </Grid>
            {getMobileState ? <>
              <Grid item xs={12} sm={12}>
                <Button onClick={() => handleMobile()}
                  variant="contained"
                  //className={classes.hover}
                  style={{ color: '#fff', backgroundColor: '#000', margin: '10px 0px', }}
                  endIcon={<Icon>send</Icon>}
                  fullWidth
                >
                  Send
                </Button>
                {/* <Avatar className={classes.red} onClick={() => handleMobile()} ><span style={{ fontSize: 30 }}>&gt;222</span></Avatar> */}
              </Grid></> : <div></div>
            }
            {getMobileState ? <div></div> : <>
              <Grid item xs={12} sm={12} >
                <div style={{ fontSize: 20 }}><b>Verify</b></div>
              </Grid>
              <Grid item xs={11}  >
                <div style={{ fontSize: 11, paddingRight: 2 }}>We have sent 4 digit OTP on<b> +91-{getMobile}</b></div>
              </Grid>
              <Grid item xs={1} className={classes.center}>
                <div style={{ fontSize: 10, color: 'red', cursor: 'pointer' }} onClick={() => handleChangeMobile()}>Change</div>
              </Grid>
              <Grid item xs={12} sm={12} >
                <FormControl fullWidth className={classes.textfieldStyle} variant="outlined" size="small" >
                  <InputLabel htmlFor="outlined-adornment-password2" >Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password2"
                    type={getOPassword.showPassword ? 'text' : 'password'}
                    value={getOPassword.password}
                    onChange={(event) => handleChangePassword2(event)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          //onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {getOPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
                {/* <TextField className={classes.textfieldStyle} fullWidth value={getOTP}
                  id="outlined-size-small"
                  variant="outlined"
                  size="small" label="Enter Your OTP / Password"
                  onChange={(event) => { setOTP(event.target.value); setPassword(event.target.value) }}
                /> */}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}><div className={classes.error}>{getErrOTP}</div>
                  <div style={{ fontSize: 12, color: 'red', textAlign: 'right', cursor: 'pointer' }} onClick={() => handleResendOtp()} >Resend OTP</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} >
                <Button fullWidth variant="contained" style={{ backgroundColor: '#000', color: '#fff' }} onClick={() => handleMobileVerify()} >
                  <b> Verify</b>
                </Button>
              </Grid>
            </>}
          </Grid>
        </ThemeProvider>
      </div>
    )
  }
  //SignUp User //
  const SignUpUser = () => {
    return (
      <div className={classes.root}>
        <Grid container spacing={1} >
          <Grid item xs={12} sm={10}>
            <div style={{ fontSize: 30, color: '#000' }}> <b>Sign Up</b></div>
            <div style={{ color: '#666666', fontSize: 14 }}><b>Please enter your details.</b></div>
          </Grid>
          <Grid item xs={12} sm={2} >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {/* <PersonAddIcon variant="filled" aria-label="menu" size="medium" className={classes.IconBorder} style={{fontSize:49}}/> */}
              <PersonAddIcon variant="filled" aria-label="menu" className={classes.IconBorder} style={{ fontSize: 45, padding: 5 }} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} >
            <Divider className={classes.divider} orientation="horizontal" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField className={classes.textfieldStyle} fullWidth value={getFirstName} id="outlined-size-small"
              variant="outlined"
              size="small" label="Your First Name" onChange={(event) => setFirstName(event.target.value)} />
            <div className={classes.error}><small>{getErrFirst}</small></div>
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField className={classes.textfieldStyle} fullWidth value={getLastName} id="outlined-size-small"
              variant="outlined"
              size="small" label="Your Last Name" onChange={(event) => setLastName(event.target.value)} />
            <div className={classes.error}><small>{getErrLast}</small></div>
          </Grid>
          <Grid item xs={12} sm={12} >
            <TextField className={classes.textfieldStyle} fullWidth value={getEmail}
              id="outlined-size-small"
              variant="outlined"
              size="small" label="Your Email-id" onChange={(event) => setEmail(event.target.value)} />
            <div className={classes.error}><small>{getErrEmail}</small></div>
          </Grid>
          <Grid item xs={12} sm={6} >
            <FormControl fullWidth className={classes.textfieldStyle} variant="outlined" size="small" >
              <InputLabel htmlFor="outlined-adornment-password" >Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={getPassword.showPassword ? 'text' : 'password'}
                value={getPassword.password}
                onChange={(event) => handleChangePassword(event)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      //onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {getPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth className={classes.textfieldStyle} variant="outlined" size="small" >
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={getConfirmPassword.showPassword ? 'text' : 'password'}
                value={getConfirmPassword.password}
                onChange={(event) => handleChangeConfirmPassword(event)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      //onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {getConfirmPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={130}
              />
            </FormControl>
            <div className={classes.error}><small>{getErrCPwd}</small></div>
          </Grid>
          <Grid item xs={12} sm={12} >
            <div style={{ margin: '0px 0px', fontSize: 11 }}>Use 8 or more characters with a mix of letters & numbers</div>
            <div className={classes.error}><small>{getErrPassword}</small></div>
          </Grid>
          <Grid item xs={12} sm={11}>
            <div style={{ fontSize: 20 }}><b>Verify</b></div>
          </Grid>
          <Grid item xs={12} sm={12} >
            <div style={{ fontSize: 11, }}>We have sent 4 digit OTP on<b>+91-{getMobile}</b></div>
          </Grid>
          {/* <Grid item xs={12} sm={4} >
            <div style={{ fontSize: 10, color: 'red', cursor: 'pointer', textAlign: 'right' }} onClick={() => handleMobileVerify()}>Change</div>
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <TextField className={classes.textfieldStyle} fullWidth value={getOTP}
              id="outlined-size-small"
              variant="outlined"
              size="small" label="Enter Your OTP" onChange={(event) => setOTP(event.target.value)} />
            <div style={{ fontSize: 11, color: 'red', textAlign: 'right', cursor: 'pointer' }} onClick={() => handleResendOtp()} >Resend OTP</div>
            <div className={classes.error}><small>{getErrOTP}</small></div>
          </Grid>
          <Grid item xs={12} sm={12} >
            <Button fullWidth variant="contained" style={{ color: '#fff', backgroundColor: '#000' }} onClick={() => handleVerify()} >
              <b> Verify</b>
            </Button>
          </Grid>
        </Grid>
      </div>
      // <div>
      //   <Grid container spacing={1} className={classes.gridStyle} >
      //     <Grid item xs={12} sm={12} >
      //       <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
      //         {/* <PersonAddIcon variant="filled" aria-label="menu" size="medium" className={classes.IconBorder} style={{fontSize:49}}/> */}
      //         <PersonAddIcon variant="filled" aria-label="menu" className={classes.IconBorder} style={{fontSize:49,padding:5}}/>
      //       </div>
      //       <div style={{ fontSize: 20 }}> <b>Sign Up</b></div>
      //       <div style={{ margin: '4px 4px', fontSize: 14 }}><b>Please enter your details.</b></div>
      //     </Grid>
      //     <Grid item xs={12} sm={6} >
      //       <TextField className={classes.textfieldStyle} fullWidth value={getFirstName} id="outlined-size-small"
      //         variant="outlined"
      //         size="small" label="Your First Name" onChange={(event) => setFirstName(event.target.value)} />
      //       <div className={classes.error}><small>{getErrFirst}</small></div>
      //     </Grid>
      //     <Grid item xs={12} sm={6} >
      //       <TextField className={classes.textfieldStyle} fullWidth value={getLastName} id="outlined-size-small"
      //         variant="outlined"
      //         size="small" label="Your Last Name" onChange={(event) => setLastName(event.target.value)} />
      //       <div className={classes.error}><small>{getErrLast}</small></div>
      //     </Grid>
      //     <Grid item xs={12} sm={12} >
      //       <TextField className={classes.textfieldStyle} fullWidth value={getEmail}
      //         id="outlined-size-small"
      //         variant="outlined"
      //         size="small" label="Your Email-id" onChange={(event) => setEmail(event.target.value)} />
      //       <div className={classes.error}><small>{getErrEmail}</small></div>
      //     </Grid>
      //     <Grid item xs={12} sm={6} >
      //       <FormControl fullWidth className={classes.textfieldStyle} variant="outlined" size="small" >
      //         <InputLabel htmlFor="outlined-adornment-password" >Password</InputLabel>
      //         <OutlinedInput
      //           id="outlined-adornment-password"
      //           type={getPassword.showPassword ? 'text' : 'password'}
      //           value={getPassword.password}
      //           onChange={(event) => handleChangePassword(event)}
      //           endAdornment={
      //             <InputAdornment position="end">
      //               <IconButton
      //                 aria-label="toggle password visibility"
      //                 onClick={handleClickShowPassword}
      //                 //onMouseDown={handleMouseDownPassword}
      //                 edge="end"
      //               >
      //                 {getPassword.showPassword ? <Visibility /> : <VisibilityOff />}
      //               </IconButton>
      //             </InputAdornment>
      //           }
      //           labelWidth={70}
      //         />
      //       </FormControl>
      //       <div style={{ margin: '0px 4px', fontSize: 11 }}>Use 8 or more characters with a mix of letters & numbers</div>
      //       <div className={classes.error}><small>{getErrPassword}</small></div>
      //     </Grid>
      //     <Grid item xs={12} sm={6}>
      //       <FormControl fullWidth className={classes.textfieldStyle} variant="outlined" size="small" >
      //         <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
      //         <OutlinedInput
      //           id="outlined-adornment-password"
      //           type={getConfirmPassword.showPassword ? 'text' : 'password'}
      //           value={getConfirmPassword.password}
      //           onChange={(event) => handleChangeConfirmPassword(event)}
      //           endAdornment={
      //             <InputAdornment position="end">
      //               <IconButton
      //                 aria-label="toggle password visibility"
      //                 onClick={handleClickShowConfirmPassword}
      //                 //onMouseDown={handleMouseDownConfirmPassword}
      //                 edge="end"
      //               >
      //                 {getConfirmPassword.showPassword ? <Visibility /> : <VisibilityOff />}
      //               </IconButton>
      //             </InputAdornment>
      //           }
      //           labelWidth={130}
      //         />
      //       </FormControl>
      //       <div className={classes.error}><small>{getErrCPwd}</small></div>
      //     </Grid>
      //     <Grid item xs={12} sm={11}>
      //       <div style={{ fontSize: 20 }}><b>Verify</b></div>
      //     </Grid>
      //     <Grid item xs={12} sm={11} >
      //       <div style={{ fontSize: 11, paddingRight: 2 }}>We have sent 4 digit OTP on<b>+91-{getMobile}</b></div>
      //       <div style={{ fontSize: 10, color: 'red', cursor: 'pointer', textAlign: 'right' }} onClick={() => handleStatus()}>Change</div>
      //     </Grid>
      //     <Grid item xs={12} sm={11}>
      //       <TextField className={classes.textfieldStyle} fullWidth value={getOTP} label="Size"
      //         id="outlined-size-small"
      //         variant="outlined"
      //         size="small" label="Enter Your OTP" onChange={(event) => setOTP(event.target.value)} />
      //       <div style={{ fontSize: 11, color: 'red', textAlign: 'right', cursor: 'pointer' }} onClick={() => handleStatus()} >Resend OTP</div>
      //       <div className={classes.error}><small>{getErrOTP}</small></div>
      //     </Grid>
      //     <Grid item xs={12} sm={11} >
      //       <Button fullWidth variant="contained" style={{ backgroundColor: '#747d8c', color: '#fff' }} onClick={() => handleVerify()} >
      //         <b> Verify</b>
      //       </Button>
      //     </Grid>
      //   </Grid>
      // </div>
    )
  }

  const SignInImage = () => {
    return (
      <img alt="PriceHatke" style={{ width: '100%', borderRadius: 7 }} src="/signimage/signin.jpeg" />
    )
  }

  const SignUpImage = () => {
    return (
      <img alt="PriceHatke" style={{ width: '100%', borderRadius: 7 }} src="/signimage/short-girl-dressing-tips4.jpg" />
    )
  }

  return (
    <div className={classes.grow} >
      <Header history={props.history} />
      <div className={classes.root}  >
        <div className={classes.paperStyle}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {/* <img alt="PriceHatke" style={{width:'98%',borderRadius:7}} src="/signimage/signin.jpeg" /> */}
                {getState ? SignInImage() : SignUpImage()}
              </div>
            </Grid>
            {/* <Divider className={classes.divider} orientation="vertical" /> */}
            <Grid item xs={12} md={4} style={{ /*border: '1px solid #CFCFCF',*/ borderRadius: 5 }}>
              {getState ? SignInMobile() : SignUpUser()}
              <div style={{ color: 'red', position: "absolute" }}><small>{getMsg}</small></div>
              <div style={{ textAlign: 'center', fontSize: 13 }}>
                By continuing you agree to our <font color='red'>Terms of service</font><br />
                and <font color='red' >Privacy & Legal Policy.</font>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer history={props.history} />
    </div>
  )
}



export default UserLogin;