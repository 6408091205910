import React, { useEffect, useState, createRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, TextField } from "@material-ui/core";
import { getData, postData,ServerURL } from "../FetchNodeServices";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const useStyles = makeStyles((theme) => ({
  gridRoot: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    outline: "none",
  },
}));

export default function ViewSlider(props) {
  const classes = useStyles();
  const [getProductBanner, setProductBanner] = useState([]);

  useEffect(function () {
    fetchProductBanner();
  }, []);

  var settings = {
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
  };

  const fetchProductBanner = async () => {
    var list = await getData("productbanner/displayjson");
    setProductBanner(list.data);
  };

  const viewSlider=()=>{
    
      //onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb}
        return getProductBanner.map(function(item,key){

          return(
            <div style={{cursor:'pointer'}} onClick={()=>handleShowProductView(item.productitemid,item.modelid)}>
              <img style={{ width:'100%', height:'100%',cursor:'pointer'}} src={`${ServerURL}/images/${item.bannerpicture}`}/>
          </div>
          )
        })
      } 

 /*const viewSlider = () => {
    //onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb}
    return getProductBanner.map(function (item, key) {
      return (
        <div style={{ cursor: "pointer" }}>
          <img
            style={{ width: "100%", height: "100%", cursor: "pointer" }}
            src={`${ServerURL}/images/${item.bannerpicture}`}
          />
        </div>
      );
    });
  };
*/

const handleShowProductView = (productid,modelid) => {
    props.history.push({ pathname: `/ProductView/${productid}/${modelid}` });
  };

  var sliderRef = createRef();

  return (
    <div>
      <Carousel showArrows={true} showThumbs={false} autoPlay={true}>
          {viewSlider()}
  </Carousel>
     {/*<div style={{width:'99%'}}>
     <div className={classes.gridRoot}>
     <div style={{width:'99%'}}>
      <Slider {...settings} ref={sliderRef}>
        {viewSlider()}
      </Slider>
      </div>
     </div>
</div>*/}
    </div>
  );
}
