import React, { useEffect, useState } from 'react';
import { fade, jssPreset, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { getData, ServerURL, postData, postDataAndImage } from '../FetchNodeServices';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Image from 'react-image-resizer';
import ReceiptIcon from '@material-ui/icons/Receipt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StarIcon from '@material-ui/icons/Star';
import moment from "moment"
import QRCode from 'react-qr-code'
import { DateRange } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'flex', justifyContent: 'center', alignItems: 'center'
  },
  hover: {
    "&:hover": {
      //background: "#de011b",
      background: "#FFC312",
      transform: "scale(1)",
      //transition: "all 0.5s ease 0s",
      color: "#000",
    },
  },
  gridStyle: {
    display: 'flex',
    padding: '0px 20px 0px 20px',
    flexDirection: 'row'
  },
  filterStyleHeading: {
    display: 'flex',
    fontSize: 20,
    fontFamily: 'Calibri',
    color: '#000000',
    letterSpacing: 1,
    fontWeight: 600,
  },
  filterStyle: {
    fontSize: 14,
    fontFamily: 'Calibri',
    border: '1px solid #dfe6e9',
    //letterSpacing:0.5, 
    borderRadius: 1,
    padding: 10,
  },
  divider: {
    margin: '4px 4px 0px 4px',
    backgroundColor: '#ff4757',
    height: 2,
    width: '99%',
  },
}));

export default function OrderHistory(props) {
  const classes = useStyles();
  const [getOrderHistory, setOrderHistory] = useState([])
  const [getProduct, setProduct] = useState([])
  const [getUser, setUser] = useState([])
  const [getStatus, setStatus] = useState(false)
  const [getAmount, setAmount] = useState(0)
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState('');
  const [getTrackStatus, setTrackStatus] = React.useState({});

  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(value);

  const CheckSession = async () => {
    if (!localStorage.getItem('user')) {
      props.history.replace({ pathname: `/UserLogin` })
    }
  }

  const fetchProducts = async (orderno) => {
    var result = await postData('purchase/orderdetailsbyorderno', { 'orderno': orderno })
    if (result.length != 0) {
      setProduct(result)
      // alert(JSON.stringify(result))
      setStatus(true)
      setAmount(result[0].amountpaid)
      setOrder(result[0].orderno)
    }
    var result2 = await postData('purchase/trackorderbyorderno', { 'orderno': orderno })
    if (Object.keys(result2.result).length != 0) {
      setTrackStatus(result2.result)
      // alert(JSON.stringify(result2))
    }
  }


  const fetchOrderHistory = async () => {
    var user = JSON.parse(localStorage.getItem('user'))
    setUser(user)
    var body = { mobileno: user.mobileno }
    var list = await postData('purchase/orderdisplaybymobileno', body)
    //console.log("list",list)
    setOrderHistory(list)
    // alert(JSON.stringify(list))
  }

  const CancelOrder = async (orderno) => {
    var result = await postData('purchase/cancelorder', { 'orderno': orderno })
    if (result.result) {
      fetchProducts(orderno)
    }
    setOpen(false)
  }

  useEffect(function () {
    CheckSession()
    window.scrollTo(0, 0)
    fetchOrderHistory()
  }, [setStatus])

  const OrderDetailsHeading = () => {
    return (
      < thead >
        <th>Order Placed at</th>
        <th>Total Amount Paid</th>
        <th>Order No</th>
        <th>Status</th>
        <th>Show Bill</th>
      </thead >
    )
  }

  const OrderDetails = () => {
    return (
      getOrderHistory.map(function (item, key) {
        // alert(JSON.stringify(getOrderHistory))
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let date = new Date(item.orderdate.split("T")[0])
        let ddate = item.ordertime.split(":")
        var t = (parseInt(ddate[0]) > 9 ? ddate[0] : '0' + ddate[0]) + ':' + (parseInt(ddate[1]) > 9 ? ddate[1] : '0' + ddate[1])
        const timeString12hr = new Date('1970-01-01T' + t + 'Z')
          .toLocaleTimeString('en-US',
            { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
          );
        // alert(timeString12hr)
        var dd = date.getDate()
        if (parseInt(dd) < 9) {
          dd = "0" + dd;
          // return mm;
        }
        var mm = date.getMonth() + 1
        if (parseInt(mm) < 9) {
          mm = "0" + mm;
          // return mm;
        }
        let day = days[date.getDay()];
        // let d = date.getFullYear() + '/' + date.getMonth() + 1 + '/' + date.getDate()
        let d = dd + '/' + mm + '/' + date.getFullYear()
        //console.log('time')
        return (
          <tbody>
            <tr>
              <td data-label="Order Placed at">{day} {d} at {timeString12hr}</td>
              <td data-label="Total Amount Paid"><span>&#8377;</span> {item.amountpaid}</td>
              <td data-label="Order No">{item.orderno}</td>
              <td data-label="Status">
                {item.status === "Cancel Order" ? (
                  <div style={{ color: "#EA2027", display: 'inline-block', background: '#ffbec05d', padding: '3px 10px', borderRadius: 50, fontWeight: 600 }}>
                    {item.status}
                  </div>
                ) : item.status === "Active" ? (
                  <div style={{ color: "#009432", display: 'inline-block', background: '#6ad85950', padding: '3px 10px', borderRadius: 50, fontWeight: 600 }}>
                    {item.status}
                  </div>
                ) : item.status === "Bill Generated" ? (
                  <div style={{ color: "#341f97", display: 'inline-block', background: '#b6aeff63', padding: '3px 10px', borderRadius: 50, fontWeight: 600 }}>
                    {item.status}
                  </div>
                ) : item.status === "Delivered" ? (
                  <div style={{ color: "#009432", display: 'inline-block', background: '#6ad85950', padding: '3px 10px', borderRadius: 50, fontWeight: 600 }}>
                    {item.status}
                  </div>
                ) : (<></>)}
              </td>
              {/* <td data-label="Status"><QRCode value={item.orderno} size={100} /></td> */}
              <td data-label="Show Bill" onClick={() => fetchProducts(item.orderno)}><ReceiptIcon style={{ cursor: 'pointer', fontSize: 20 }} /></td>
            </tr>
          </tbody>
        )
      })
    )
  }

  const handleShowProductView = (productid, modelid, qt) => {
    props.history.push({ pathname: `/ProductView/${productid}/${modelid}` }, qt);
  };

  const showProducts = () => {
    return (
      getProduct.map(function (item, key) {
        // alert(JSON.stringify(item))
        return (
          <>
            <Grid container spacing={0} className={classes.gridStyle} style={{ borderBottom: '1px solid #dcdde1', paddingBottom: 10, paddingTop: 5 }} >
              {/* <Grid item xs={12} sm={12}  > */}
              {/* <Grid container spacing={1}> */}
              <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end', }} >

                {getProduct.length == getProduct.length - key ? <div style={{}}>
                  {item.status == 'Active' ? <Button startIcon={<HighlightOffIcon />} style={{ backgroundColor: '#212121', color: '#fff' }} onClick={() => setOpen(true)} >Cancel Order </Button> : <></>}
                </div> : <></>}
              </Grid>
              <Grid item xs={12} sm={1} onClick={() => handleShowProductView(item.productid, item.modelid)} className={classes.center}  >
                <div style={{ width: 90, height: 135, cursor: 'pointer' }}>
                  <Image src={`${ServerURL}/images/${item.picture}`} width={100} height={135} /></div>
              </Grid>
              <Grid item xs={12} sm={8} style={{ padding: '0px 0px 0px 35px' }}>
                <div style={{ fontSize: 14, fontWeight: 600, cursor: 'pointer' }} onClick={() => handleShowProductView(item.productid, item.modelid)} >{item.productname + " - " + item.color}</div>
                {item.size != "none" ? <div style={{ fontSize: 13 }} ><b>Size -</b>{item.size}</div> : <></>}
                <div style={{ fontSize: 13 }} ><b>Price</b>&nbsp;{numberFormat(item.discount)}
                  &nbsp;&nbsp;<small><s> {numberFormat(item.price)}</s> &nbsp; &nbsp;<br />
                    <b><font color="green" >You Saved {numberFormat(item.price - item.discount)}</font></b></small></div>
                < div style={{ fontSize: 13, }}><small><b>Quantity</b> {item.quantity}</small></div>
                < div style={{ fontSize: 13, }}><small><b>{item.notesforitem}</b></small></div>
                < div style={{ fontSize: 13, }}><small><b>Delivery Charge&nbsp;</b>{numberFormat(item.deliverycharges)}</small></div>
                < div style={{ fontSize: 12, }} ><small><b>Status&nbsp; </b>{item.status}</small></div>
              </Grid>
              {item.status == 'Delivered' ? <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end', }}>
                <Button startIcon={<StarIcon />} style={{ backgroundColor: '#212121', color: '#fff' }} onClick={() => props.history.push({ pathname: `/RatingnReviews/${item.productid}` })} >Rate this Product</Button>
              </Grid> : <></>}
              {/* </Grid> */}
              {/* </Grid> */}
              <Divider />

            </Grid>
          </>
        )
      })

    )
  }


  //  const showProducts=()=>{

  //    return(
  //   getProduct.map(function(item,key){
  //    return(
  //      <>

  //       <Grid container spacing={0} className={classes.gridStyle}style={{ borderBottom: '1px solid #dcdde1',paddingBottom:10,paddingTop:5}} >
  //         {/* <Grid item xs={12} sm={12}  > */}
  //       {/* <Grid container spacing={1}> */}
  //       <Grid item xs={12} sm={1} className={classes.center}  >
  //        <div style={{width:90,height:135}}> 
  //        <Image src={`${ServerURL}/images/${item.picture}`} width={100} height={135} /></div>
  //       </Grid>
  //        <Grid item xs={12} sm={8} style={{padding:'0px 0px 0px 35px'}}>
  //         <div style={{fontSize:14,fontWeight:600}}>{item.productname+" - "+item.color}</div> 
  //         {item.size !="none" ? <div style={{fontSize:13}} ><b>Size -</b>{item.size}</div> :<></> }
  //         <div style={{fontSize:13}} ><b>Price</b>&nbsp;{numberFormat(item.discount)}
  //         &nbsp;&nbsp;<small><s> {numberFormat(item.price)}</s> &nbsp; &nbsp;<br/>
  //         <b><font color="green" >You Saved {numberFormat(item.price-item.discount)}</font></b></small></div> 
  //         < div style={{fontSize:13,}}><small><b>Quantity</b> {item.quantity}</small></div>  
  //         < div style={{fontSize:13,}}><small><b>{item.notesforitem}</b></small></div>    
  //         < div style={{fontSize:13,}}><small><b>Delivery Charge&nbsp;</b>{numberFormat(item.deliverycharges)}</small></div>    
  //         < div style={{fontSize:12,}} ><small><b>Status&nbsp; </b>{item.status}</small></div>
  //        </Grid>
  //        <Grid item xs={12} sm={3} >

  //         {getProduct.length == key+1 ?  <div style={{}}>
  //         {item.status!='Cancel Order'?<Button startIcon={<HighlightOffIcon/>} size="small" variant="contained" color="secondary" onClick={()=>setOpen(true)} >Cancel Order </Button>:<></>}
  //       </div> :<></>}
  //        </Grid>
  //      {/* </Grid> */}
  //      {/* </Grid> */}
  //      <Divider />

  //       </Grid>
  //    </>
  //    )
  //   })

  //    )
  // }

  const handleClose = () => {
    setOpen(false);
  };

  const CancelOrderConfirm = (orderno) => {
    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Cancel Order </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to cancel this order ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{ color: '#747d8c' }}>
              No
            </Button>
            <Button onClick={() => CancelOrder(orderno)} style={{ color: '#747d8c' }} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const showProductTrack = () => {
    return (
      <div class="row d-flex justify-content-center mb-3" >
        <div class="col-12">
          <ul id="progressbar" class="text-center">
            {getTrackStatus && getTrackStatus.tracking_status && JSON.parse(getTrackStatus.tracking_status).map((item, key) => <li class={`${item.date != "" ? "active" : ""} step0`}><p class="font-weight-bold">{item.status}</p><span class="font-weight-600">{item.date && moment(item.date).format('DD/MM/YYYY HH:MM:SS A')}</span></li>
            )}
          </ul>
        </div>
      </div>
    )

  }

  // var statusdelivered = getTrackStatus && getTrackStatus.tracking_status && JSON.parse(getTrackStatus.tracking_status)[1].status
  var dates = getTrackStatus && getTrackStatus.tracking_status && moment(JSON.parse(getTrackStatus.tracking_status)[0].date).format("DDMMYYYY")
  var managedate = moment(dates).add(7, "days").format("DDMMYYYY")
  // const showButton = () => {
  //   const date = getTrackStatus && getTrackStatus.tracking_status && JSON.parse(getTrackStatus.tracking_status)[0].date
  //   console.log(date)

  //   return ""
  // }
  return (
    <div>
      <Grid container spacing={1} className={classes.gridStyle} >
        {getOrderHistory.length && !getStatus ? <>
          <Grid item xs={12} sm={3} >
            <div className={classes.filterStyleHeading}>My Order</div>
          </Grid>
          <Grid item xs={12} sm={9} >
            <div style={{ float: 'right', fontWeight: 'bold', fontFamily: 'Calibri' }}>{getOrderHistory.length} order had placed  from your account</div>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item xs={12} sm={12} >

            <table class="table table-striped">
              {OrderDetailsHeading()}
              {OrderDetails()}
            </table>
          </Grid>
        </> : getStatus ? <>
          <Grid item xs={12} sm={4} >
            <div style={{ fontWeight: 'bold', fontFamily: 'Calibri' }}>All Bill Products</div>
          </Grid>
          <Grid item xs={12} sm={8} >
            <div style={{ float: 'right', fontWeight: 'bold', fontFamily: 'Calibri' }}>Total Amount Paid {numberFormat(getAmount)}</div>
          </Grid>
          <Divider className={classes.divider} />
          {/* <div style={{display:'flex',flexDirection:'row'}}>
               <div style={{fontWeight:'bold',width:'100%'}}>All Bill Products</div>
               <div style={{display:'flex',justifyContent:'flex-end',width:'100%'}}><b>Total Amount Paid<br/>{numberFormat(getAmount)}</b></div>
               </div>    */}
          <Grid item xs={12} sm={12} >
            <div class="container px-1 px-md-4 py-5 mx-auto">
              <div class="card">
                <div class="row d-flex justify-content-between px-3 top">
                  <div class="d-flex">
                    <h6>ORDER ID-<span class="text-primary font-weight-bold">{getTrackStatus.order_id}</span></h6>
                  </div>
                  <div class="d-flex flex-column text-sm-right">
                    <p class="mb-0 font-weight-bold">Expected Arrival <span>01/12/19</span></p>
                    {/* <p>USPS <span class="font-weight-bold">234094567242423422898</span></p> */}
                  </div>
                </div>
                {showProductTrack()}
                {/* <div class="row justify-content-around" style={{ paddingLeft: '1%', paddingRight: '2%' }}>
                  <div class="row d-flex icon-content">
                     <img class="icon" src="https://i.imgur.com/9nnc9Et.png" /> 
                    <div class="d-flex flex-column">
                      <p class="font-weight-bold">Processed</p>
                    </div>
                  </div>
                  <div class="row d-flex icon-content">
                     <img class="icon" src="https://i.imgur.com/u1AzR7w.png" /> 
                    <div class="d-flex flex-column">
                      <p class="font-weight-bold">Shipped</p>
                    </div>
                  </div>
                  <div class="row d-flex icon-content">
                     <img class="icon" src="https://i.imgur.com/TkPm63y.png" /> 
                    <div class="d-flex flex-column">
                      <p class="font-weight-bold">En Route</p>
                    </div>
                  </div>
                  <div class="row d-flex icon-content">
                     <img class="icon" src="https://i.imgur.com/HdsziHP.png" /> 
                    <div class="d-flex flex-column">
                      <p class="font-weight-bold">Arrived</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {showProducts()}
          </Grid>

          {/* ({getTrackStatus.tracking_status && JSON.parse(getTrackStatus.tracking_status)[3].date}) */}
          {/* {moment(moment().format("YYYY-MM-DD")).isBetween(getTrackStatus && getTrackStatus.tracking_status && moment(JSON.parse(getTrackStatus.tracking_status)[3].date).format("YYYY-MM-DD"), getTrackStatus && getTrackStatus.tracking_status && moment(JSON.parse(getTrackStatus.tracking_status)[3].date).add(7, "days").format("YYYY-MM-DD"), null, '[]') ? "1" : "2"} */}
          { }
          {/* {showButton()} */}
          {/* <Grid item sx={6} sm={12}>
            
          </Grid> */}
          <Grid item sx={6} sm={12}>
            <Button startIcon={<VisibilityIcon />} style={{ backgroundColor: '#212121', color: '#fff' }} fullWidth onClick={() => setStatus(false)}>Show All Orders</Button>
          </Grid>
        </> : <div><h2>No Order has Placed from your Account</h2></div>}
      </Grid>
      {CancelOrderConfirm(order)}
    </div>


  );
}