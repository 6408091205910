import React, { useEffect, useState, createRef } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import { getData, postData, ServerURL } from '../FetchNodeServices'
import Button from '@material-ui/core/Button';
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Paper from '@material-ui/core/Paper';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import "react-placeholder/lib/reactPlaceholder.css";
import CircularProgress from '@material-ui/core/CircularProgress';
// import "./style.css";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Radium, { StyleRoot } from 'radium';
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import SearchIcon from "@material-ui/icons/Search";


const useStyles = makeStyles((theme) => ({


  toproot: {
    //alignItems:'center',
    justifyContent: 'flex-start',
    display: 'flex',
    padding: 10,
    width: 250,
    height: 310,
    margin: 10,
    padding: 10,
    // border:'2px solid #dcdde1',
    // borderRadius: 10,
    flexDirection: 'column',

  },
  productAction: {
    display: "flex",
    justifyContent: "center",
    margin: 7,
    // flexDirection: "column",
    color: "#747d8c",
  },
  ImageView: {
    // width: 250,
    // height: 250,
    display: "flex",
    // border:'1px solid #f1f2f6',
    // alignItems: "center",
    // justifyContent: "center",
    //padding: 5,
    "&:hover": {
      // background: "#747d8c",
      // transform: "scale(1.035)",
      // transition: "all 0.1s ease 0s",
    },
    // backgroundColor: "#f1f2f6",
    // borderRadius: 5,
  },
  cartbtn: {
    padding: 2,
    height: 30,
    margin: '5px 0px',
    backgroundColor: '#747d8c',
    color: '#ffffff',
    "&:hover": {
      background: "#747d8c",
      transition: 'all 0.5s ease 0s',
    },

  },
  cardview: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scardview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    paddingTop: 15,
    // margin:10
  },

  headingName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 0,
    // fontSize: 30,
    color: "#212121",
    textAlign: 'center',
    fontWeight: "bold",
    letterSpacing: "2.5px",
    fontFamily: 'Calibri',
    backgroundColor: 'transparent'
  },
  divider: {
    margin: '5px 5px',
    backgroundColor: '#e74c3c',
    height: 3,
    width: 100,
  },
  text: {
    display: 'flex',
    paddingTop: 10,

  },
  hoverimg: {
    "&:hover": {
      //background: "#de011b",
      //background: "#000000",
      transform: "rotateY(0deg)",
      transition: "all 1s ease 1s",
      color: "#fff",
    },
  },

}));

export default function TopProducts(props) {
  const classes = useStyles();
  const style = {

    // Adding media querry..
    // '@media screen and (max-width: 5464px) and (min-width: 4099px)':{
    //   position:'absolute',marginLeft:'-1%',marginBottom:20,minWidth:'0.8%',textAlign:'center',borderRadius:'100%',backgroundColor:'#ff0000',padding:7,
    // },
    // '@media screen and (max-width: 4098px) and (min-width: 2733px)':{
    //   position:'absolute',marginLeft:'-0.3%',marginBottom:20,minWidth:'0.20%',textAlign:'center',borderRadius:'100%',backgroundColor:'#ff0000',padding:7,
    // },
    // '@media screen and (max-width: 2732px) and (min-width: 2050px)':{
    //   position:'absolute',marginLeft:'-0.4%',marginBottom:20,minWidth:'0.25%',textAlign:'center',borderRadius:'100%',backgroundColor:'#ff0000',padding:7,
    // },
    // '@media screen and (max-width: 2049px) and (min-width: 1823px)':{
    //   position:'absolute',marginLeft:'-0.4%',marginBottom:20,minWidth:'0.3%',textAlign:'center',borderRadius:'100%',backgroundColor:'#ff0000',padding:7,
    // },
    // '@media screen and (max-width: 1822px) and (min-width: 1709px)':{
    //   position:'absolute',marginLeft:'-0.4%',marginBottom:20,minWidth:'0.30%',textAlign:'center',borderRadius:'100%',backgroundColor:'#ff0000',padding:7,
    // },
    // '@media screen and (max-width: 1708px) and (min-width: 1519px)':{
    //   position:'absolute',marginLeft:'-0.47%',marginBottom:20,minWidth:'0.35%',textAlign:'center',borderRadius:'100%',backgroundColor:'#ff0000',padding:7,
    // },
    // '@media screen and (max-width: 1518px) and (min-width: 1367px)': {
    //   position:'absolute',marginLeft:'-0.55%',marginBottom:20,minWidth:'0.4%',textAlign:'center',borderRadius:'100%',backgroundColor:'#ff0000',padding:7,
    // },
    // '@media screen and (max-width: 1366px) and (min-width: 768px)  ': {
    //   position:'absolute',marginLeft:'-0.5%',minWidth:'0.35%',textAlign:'center',borderRadius:'100%',backgroundColor:'#ff0000',padding:7
    // },
    // '@media screen and (max-width: 767px) and (min-width: 240px)  ': {
    //   position:'absolute',marginLeft:'-0.5%',width:'0.35%',textAlign:'center',borderRadius:'100%',backgroundColor:'#ff0000',padding:7
    // },
  };
  const [getTopProducts, setTopProducts] = useState([])
  const [getState, setState] = useState(false)

  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(value);


  useEffect(function () {
    fetchTopProducts()
  }, [])

  const fetchTopProducts = async () => {
    var list = await getData('product1/productitemsoffer')
    var arr = list.sort((a, b) => (b.price - b.offerprice) - (a.price - a.offerprice))
    list = []
    arr.map((item, key) => {
      if (key < 20) {
        list.push(item)
      }
    })
    setTopProducts(list)
    setState(true)
  }


  //Show Products //
  const ShowTopProducts = () => {
    return (

      getTopProducts.slice(0, 20).map((item, key) => {
        var save = item.price - item.offerprice
        var o = 1
        var color = 'green'
        if (item.status == 'Coming Soon' || item.status == 'Pre Booking') {
          o = 0.5
          if (item.status == 'Coming Soon') {
            color = 'red'
          }
        }
        var discount = Math.ceil((save / item.price) * 100)
        if (save > 0) {
          return (
            <>

              <div className={classes.productAction} >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', }}>
                  {/* <div className={classes.productAction}> */}
                  {/* <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} > */}
                  <Paper elevation={3}>

                    {/* <div class="show" > */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                        cursor: 'pointer',
                        padding: 10,
                        // transition: 'transform 1s;'
                      }}
                      onClick={() => handleShowProductView(item.productid, item.modelid)}>

                      <Hidden mdUp>
                        <img
                          width={220}
                          height={"100%"}
                          style={{ opacity: o, }}
                          alt={item.productname}
                          className={classes.hoverimg}
                          src={`${ServerURL}/images/${item.picture}`}
                        />
                        <div style={{ position: 'absolute', zIndex: 1, opacity: 1, }}>
                          <img src="/images/dis.png" />
                          <div style={{ position: 'absolute', top: 9, color: 'rgb(255, 255, 255)', fontSize: 12, fontWeight: 'bold', left: 12, textAlign: 'center', cursor: 'pointer' }}>
                            <p>{discount}% </p><p>Off</p>
                          </div>
                        </div>
                      </Hidden>
                      <Hidden smDown>
                        <img
                          alt={item.productname}
                          class="frontpageimg2"
                          src={`${ServerURL}/images/${item.picture}`}
                        />
                        <div style={{ position: 'absolute', zIndex: 1, opacity: 1, }}>
                          <img src="/images/dis.png" />
                          <div style={{ position: 'absolute', top: 9, color: 'rgb(255, 255, 255)', fontSize: 12, fontWeight: 'bold', left: 12, textAlign: 'center', cursor: 'pointer' }}>
                            <p>{discount}% </p><p>Off</p>
                          </div>
                        </div>

                        {/* <StyleRoot>
                     
                        <div style={style}>
                          <div style={{color:'#fff',fontWeight:500}}><p style={{fontSize:16}}>{discount}<small>%</small></p><small>Off</small></div>
                        </div>
                    </StyleRoot> */}

                      </Hidden>

                      {/* <div id="axis" style={{ flexDirection: 'column', display: 'flex', }}>
                    <span style={{ position: 'absolute', marginLeft: -40, marginTop: 40, cursor: 'pointer' }} class="mousehover"> <ShoppingCart onClick={() => handleShowProductView(item.productid, item.modelid)} style={{ color: '#212121' }} /><FavoriteBorderIcon style={{ color: '#212121' }} /><SearchIcon style={{ color: '#212121' }} /><img class="object van move-left" /></span>
                  </div> */}
                    </div>
                    {/* </div> */}
                    <div

                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderTop: "1px solid #dcdde1",
                        padding: 10,
                        // maxWidth: '250px'
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Hidden mdDown>
                          <div style={{ fontSize: 14, padding: "5px 0px", borderRadius: 3 }}>
                            <span
                              className="price_tag" style={{ backgroundColor: '#212121', color: '#fff' }}
                            >
                              {numberFormat(item.offerprice)}

                            </span>
                          </div>
                          <div style={{ float: 'right', marginLeft: 'auto', fontWeight: 500 }}>
                            {/* <span> */}
                            <small>
                              MRP &nbsp;
                              <del>{numberFormat(item.price)}</del>&nbsp; <br />

                            </small>
                            {/* {discount > 5 ? (
                            <span style={{ color: "red", fontSize: 10,marginLeft:90,  position: 'absolute' }}>
                              {discount}% off
                            </span>
                          ) : (
                            <></>
                          )} */}
                            {/* </span> */}
                          </div>
                        </Hidden>
                        <Hidden mdUp>
                          <div style={{ fontSize: 12, padding: "5px 0px", borderRadius: 3 }}>
                            <span
                              className="price_tag" style={{ backgroundColor: '#212121', color: '#fff' }}
                            >
                              {numberFormat(item.offerprice)}
                            </span>
                          </div>
                          <div style={{ float: 'right', marginLeft: 'auto', fontWeight: 500, fontSize: 12 }}>
                            <span>
                              <small>
                                MRP &nbsp;
                                <del>{numberFormat(item.price)}</del>&nbsp; <br />

                              </small>
                              {/* {discount > 5 ? (
                            <span style={{ color: "red", fontSize: 10,marginLeft:90,  position: 'absolute' }}>
                              {discount}% off
                            </span>
                          ) : (
                            <></>
                          )} */}
                            </span>
                          </div>
                        </Hidden>
                      </div>
                      <div
                        style={{ fontSize: 15, fontWeight: "bold", padding: "5px 0px 3px" }}
                      >
                        {item.brandname}
                      </div>
                      <Hidden smDown>
                        <div style={{ fontSize: 14, padding: "3px 0px", }}>
                          {item.productname.length >= 30
                            ? item.productname.toString().substring(0, 27) + "..."
                            :
                            item.productname}
                          {/* {item.productname} */}
                        </div>
                      </Hidden>
                      <Hidden mdUp>
                        <div style={{
                          fontSize: 14, padding: "3px 0px", overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}>
                          {item.productname.length >= 30
                            ? item.productname.toString().substring(0, 25) + "..."
                            :
                            item.productname}
                          {/* {item.productname} */}
                        </div>
                      </Hidden>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 0
                      }}
                    >
                      {item.status == "Pre Booking" ? (
                        <>
                          <Button
                            variant="contained"
                            fullWidth
                            style={{
                              height: 30,
                              padding: 5,
                              backgroundColor: "#212121",
                              color: "#ffffff",
                              borderRadius: 50
                            }}
                            onClick={() => handleShowProductView(item.productid, item.modelid)}
                          >
                            Pre Book Now &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                          </Button>
                        </>
                      ) :
                        <Button
                          variant="contained"
                          fullWidth
                          style={{
                            height: 40,
                            padding: 10,
                            backgroundColor: "#212121",
                            color: "#ffffff",
                            fontSize: 15,
                            letterSpacing: "0px",
                            fontWeight: "bold",
                            // width: 250,
                            borderRadius: 0
                          }}
                          onClick={() => handleShowProductView(item.productid, item.modelid)}
                        >
                          Add to Cart &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                          <big>
                            <ShoppingCart
                              style={{ marginTop: 6, backgroundColor: "transparent" }}
                            />{" "}
                          </big>
                        </Button>
                      }
                    </div>
                  </Paper>
                  {/* </Grid>
                  </Grid> */}
                  {/* </div> */}
                </div>
              </div>
            </>
          );
        }
      }))
  }


  const state = () => {
    setState(true)
  }
  const handleShowProductView = (productid, modelid) => {
    props.history.push({ pathname: `/ProductView/${productid}/${modelid}` })
  }


  var sliderRef = createRef()


  const settings = {
    //autoplay: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,

        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,

        }
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 415,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };



  const gotoNext = () => {
    sliderRef.current.slickNext()
  }
  const gotoPrev = () => {
    sliderRef.current.slickPrev()
  }


  return (<>

    <div className={classes.scardview} style={{ margin: 10 }}>
      <Paper elevation={0} style={{ width: '95%', backgroundColor: 'transparent' }} >
        {getState ? <> <div className={classes.headingName}>
          <Divider className={classes.divider} />
          <Hidden smDown>
            <h1>TRENDING NEAR YOU</h1>
          </Hidden>
          <Hidden smUp>
            <h6>TRENDING NEAR YOU</h6>
          </Hidden>
          <Divider className={classes.divider} />
        </div>
          <div>
            {getTopProducts.length > 5 ? <div className={classes.scardview}><div style={{ marginLeft: 20, }} onClick={() => gotoPrev()} >
              <KeyboardArrowLeftIcon style={{ fontSize: 40 }} />
            </div>
              <div style={{ width: '90%' }}>
                <Slider {...settings} ref={sliderRef} >
                  {ShowTopProducts()}
                </Slider>
              </div>
              <div style={{ marginRight: 20, }} onClick={() => gotoNext()}>
                <KeyboardArrowRightIcon style={{ fontSize: 40 }} />
              </div></div> : <div className={classes.cardview}>
              {ShowTopProducts()}</div>}</div> </> :
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 50 }}>
            <CircularProgress style={{ color: '#747d8c' }} />
          </div>}
      </Paper>
    </div>
  </>
  );
}



