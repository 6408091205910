import React, { useEffect, useState, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import { getData, postData, ServerURL } from '../FetchNodeServices'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Paper from '@material-ui/core/Paper';
import Footer from './Footer'
import Header from './Header'
import Image from 'react-image-resizer'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ViewSlider from './ViewSlider'
import ShowCategory from './ShowCategory'
import TopProducts from './TopProducts'
import ProductAd from './ProductAd'
import NewBrands from './NewBrands'
import Dealsoftheday from './Dealsoftheday'
import TopBrands from './TopBrands'
import ShowSubCategories from './ShowTopCategories'
import PromiseMenu from './PromiseMenu'


const useStyles = makeStyles((theme) => ({


  scardview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
  },


  headingName: {
    fontSize: '20px', fontWeight: "bold",
    padding: 10, borderBottom: '1px solid #bdc3c7'
  },


}));

export default function FirstPage(props) {
  const classes = useStyles();

  useEffect(function () {
    window.scrollTo(0, 0)
  }, [])





  return (
    <div style={{ backgroundColor: '#FFF' }} >
      <Header history={props.history} />
      <ViewSlider history={props.history} />
      {/* <ShowCategory history={props.history} /> */}
      <ShowSubCategories history={props.history} />
      <Dealsoftheday history={props.history} />
      <TopProducts history={props.history} />
      {/* <TopBrands  history={props.history}  /> */}
      {/* <ProductAd history={props.history} /> */}
      <PromiseMenu />
      {/*
      <Dealsoftheday  history={props.history} />
      
     
      <div className={classes.scardview}>
        <Paper elevation={0} style={{ width: '96%', margin: '10px 5px', paddding: '5px 5px 20px' }} >
          <div className={classes.headingName}>
            Top Brands
      </div>
          <div>
            {getTopBrands.length > 5 ? <div className={classes.scardview} >
              <div style={{ marginLeft: 20, }} onClick={() => gotoPrevbrand()}>
                <KeyboardArrowLeftIcon style={{ fontSize: 'large' }} />
              </div>
              <div style={{ width: '90%', }}>
                <Slider  {...settings} ref={topbrandRef} >

                  {TopBrands()}

                </Slider>
              </div>
              <div style={{ marginRight: 20, }} onClick={() => gotoNextbrand()} >
                <KeyboardArrowRightIcon style={{ fontSize: 'large' }} />
              </div>
            </div>
              : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
                {TopBrands()}</div>}
          </div>
        </Paper>
      </div>
      <NewBrands history={props.history} />*/}
      <Footer history={props.history} />
    </div>
  );
}



