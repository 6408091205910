import React, { useEffect, useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Header from './Header';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { postData } from '../FetchNodeServices';

import { checkRequire, checkMobile, checkEmail, checkUserPassword } from '../Checks';



const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    }

}));

export default function FAQ(props) {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = React.useState('order');
    const [getOrder, setOrder] = useState('')
    const [orderlist, setOrderlist] = useState([])


    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(function () {
        window.scrollTo(0, 0)
    }, [])

    const handleSubmit = async () => {
        if (!checkRequire(getOrder)){
        alert("Enter Order Id")
        }
        else{
            var result=await postData('purchase/orderdetailsbyorderno',{orderno:getOrder})
            setOrderlist(result)
            setOrder('')
            //console.log("CCC",result)
        }
    }

    return (
        <div >

            <div className={classes.center}>
                <Paper elevation={0} style={{ width: window.innerWidth * 0.8, margin: 20, padding: 30, borderRadius: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className={classes.center}><img src="/images/track_icon.png" width="45" height="52" /></div>&nbsp;&nbsp;
                        <div className={classes.center} style={{ fontSize: 18, fontWeight: 'bold' }}>Track status of your shipment</div>
                    </div>
                    <Divider style={{ margin: '20px 0px 40px' }} />
                    <span style={{ fontSize: 15, fontWeight: 'bold' }}>
                        Search By :
                    </span>
                    <span>
                        <Radio
                            color="#000"
                            checked={selectedValue === 'order'}
                            onChange={handleChange}
                            value="order"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'Order Id' }}
                            style={{ margin: '0px 7px' }}
                        /> <b> Order Id</b>
                        <Radio
                            color="#000"
                            checked={selectedValue === 'awb'}
                            onChange={handleChange}
                            value="awb"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'AWB' }}
                            style={{ margin: '0px 7px' }}
                        /> <b>AWB</b>
                    </span>
                    <div>
                        <TextField focused={false} style={{ width: 400, margin: '20px 0px' }}
                            size="small" placeholder="Enter Order Id/AWB number to search"
                            variant="outlined"
                            onChange={(event) => setOrder(event.target.value)} />
                    </div>
                    <div style={{ margin: '15px 0px' }}>
                        <Button style={{ width: 200, height: 40 }} variant="contained" color="primary" onClick={() => handleSubmit()} >
                            Submit
                        </Button>
                    </div>
                    <div style={{ margin: '15px 0px' }}>

                    </div>
                </Paper>
            </div>
        </div>


    );
}