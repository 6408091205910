import React, { useEffect, useState, ComponentDidUpdate } from "react";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import { getData, postData, ServerURL } from "../FetchNodeServices";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import { useSelector, useDispatch } from "react-redux";
import QtyCtrl from "./QtyCtrl";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Image from "react-image-resizer";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Slider from "@material-ui/core/Slider";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import StarIcon from "@material-ui/icons/Star";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from '@material-ui/core/Tooltip';
import { GetColorName } from 'hex-color-to-color-name';
import "./style.css";

const PrettoSlider = withStyles({
  root: {
    color: "#212121",
    height: 4,
    width: '90%',
    marginRight: 2
  },

  thumb: {
    height: 14,
    width: 14,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -5,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + -6px)"
  },
  track: {
    height: 4,
    borderRadius: 2
  },
  rail: {
    height: 4,
    borderRadius: 2
  }
})(Slider);


const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    //margin: "20px 0px",
    height: "auto",
    position: "sticky",
    //top: 120,
  },
  span: {
    fontSize: 15,
    fontFamily: 'Calibri',
    textDecoration: 'none',
    color: '#535c68'
  },
  FormControlLabel: {
    //marginLeft:-10,    
    marginTop: -25,
    marginBottom: 15,
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 5px 0px',
    borderRadius: 0,
  },
  grow: {
    flexGrow: 1,
    padding: 0,
    backgroundColor: "#f3f3f3",
  },
  scardview: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start !important",
    flexWrap: "wrap",
    padding: 10,
  },
  productAction: {
    display: "flex",
    justifyContent: "flex-start",
    //alignItems:'center',
    //height: 320,
    //borderRadius: 10,
    margin: 7,
    //padding: 10,
    // border: "2px solid #dcdde1",
    flexDirection: "column",
    color: "#747d8c",
    //cursor: "pointer",
  },
  ImageView: {
    border: '1px solid #e2e2e2',
    width: '100%',
    height: '100%',
    display: "flex",
    // border:'1px solid',
    alignItems: "center",
    justifyContent: "center",
    //padding: 5,
    "&:hover": {
      // background: "#747d8c",
      transform: "scale(1)",
      //transform: "rotateY(360deg)",
      transition: "all 1s ease 1s",
      // boxShadow: '0 1px 10px 0 rgba(0,0,0,0.2)',
    },
    borderRadius: 5,
  },
  divStyle: {
    margin: "0px 20px 20px",
    // width: window.innerWidth * 0.75,
    borderRadius: 5,
  },
  filterStyleHeading: {
    fontSize: 22,
    fontWeight: 600,
    fontFamily: 'Calibri',
    color: '#000000',

    padding: 10
  },
  filterStyle: {
    fontSize: 15,
    fontFamily: 'Calibri',
    color: '#000000',
    fontWeight: 550,
    padding: "10px 10px 5px 14px",
  },
  sidenavheading: {
    fontSize: 15, fontWeight: 550, fontFamily: 'Calibri', color: '#000000'
  },
  hover: {
    "&:hover": {
      background: "#747d8c",
      transform: "scale(1)",
      transition: "all 0.5s ease 0s",
      color: "#fff",
    },
  },
  hoverimg: {
    "&:hover": {
      //background: "#de011b",
      //background: "#000000",
      transform: "rotateY(360deg)",
      transition: "all 1s ease 1s",
      color: "#fff",
    },
  },
}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));
export default function ViewListofProducts(props) {
  const classes = useStyles();
  const classess = useStylesBootstrap();
  const [getList, setList] = useState([]);
  const [getFilterList, setFilterList] = useState([]);
  const [getModel, setModel] = useState('');
  const [getBrand, setBrand] = useState([]);
  const [getCount, setCount] = useState(false);
  const [getPage, setPage] = useState([]);
  const [getCategoryList, setCategoryList] = useState([]);
  const [getMaxPrice, setMaxPrice] = React.useState(0);
  const [getMinPrice, setMinPrice] = React.useState(0);
  const [getPrice, setPrice] = React.useState([getMinPrice, getMaxPrice]);
  const [getAvailabilty, setAvailability] = useState("");
  const [getOrder, setOrder] = useState("");
  const [getCategory, setCategory] = useState("");
  const [getDiscount, setDiscount] = useState("");
  const [getState, setState] = useState(false);
  const [getSelectedSize, setSelectedSize] = useState([])
  const [getColorsIdList, setColorsIdList] = useState([])
  const [getSizeByColorAndProduct, setSizeByColorAndProduct] = useState([])
  const [getSelectedColor, setSelectedColor] = useState([])
  const [getColors, setColors] = useState([]);

  const dispatch = useDispatch();
  const params = useParams();
  var cart = useSelector((state) => state.cart);
  var cartItems = Object.values(cart);

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  const fetchColors = async () => {
    var list = await getData("color/fetchcolorbygroup");

    // var c = list[0].color.slice(1, list[0].color.length)
    // var col = await (await fetch(`https://api.color.pizza/v1/${c}`)).json()
    // alert(JSON.stringify(col))
    // setColors(list);
    const col = list.map(async (item) => {
      var c = GetColorName(item.color.slice(1, item.color.length))
      return { ...item, colorName: c };
    })
    Promise.all(col).then(function (results) {
      setColors(results)
    })
  };
  const fetchSize = async () => {
    let body = { pat: params.search };
    var list = await postData("size/sizebysearchid", body);
    setSizeByColorAndProduct(list)
  };
  useEffect(
    function () {
      // fetchBrandAd();
      fetchData();
      fetchSize()
      fetchColors()
      fetchCategory();
      window.scrollTo(0, 0);
      setState(false);
    },
    [params.search]
  );

  const fetchBrandAd = async () => {
    var result = await postData("product1/productitemsAdvSearch", {
      brandi: params.search,
    });
    if (result) {
      //console.log(result)
      setBrand(result.data);
    }
  };

  const fetchData = async () => {
    var arr = [];
    let body = { pat: params.search };
    var list = await postData("product1/productitemsAdvSearch", body);
    if (list.length != 0) {
      setList(list);
      setFilterList(list);
      list.map(function (item, index) {
        if (index < 20) {
          arr.push(item);
        }
      });
      setPage(arr);
      setCategory(list[0].categoryname)
      setModel(list[0].modelname)
      var min = list.reduce(
        (min, b) => Math.min(min, b.offerprice),
        list[0].offerprice
      );
      var max = list.reduce(
        (max, b) => Math.max(max, b.offerprice),
        list[0].offerprice
      );
      setMinPrice(min);
      setMaxPrice(max);
      setPrice([min, max]);
      setOrder("");
      setDiscount("");
      setAvailability("");
      setState(true);
    }
    else {
      setState(true);
      setList([])
      setFilterList([])
      setOrder("");
      setDiscount("");
      setAvailability("");
      setMinPrice(0);
      setMaxPrice(0);
      setPrice([0, 0]);
      setCategory("")
      setModel("")
    }
  };

  //Count Cart Items//
  const handleQtyChange = (value, item, key) => {
    if (value >= 1 && value <= item.stock) {
      item["qtydemand"] = value;
      dispatch({ type: "ADD_CART", payload: [item.productid, item] });
      setCount(!getCount);
    } else if (value == 0) {
      item["qtydemand"] = value;
      var list = getPage;
      list[key]["cartstatus"] = 0;
      setPage(list);
      dispatch({ type: "REMOVE_CART", payload: [item.productid, item] });
      setCount(!getCount);
    } else {
    }
  };

  //ADD To Cart//
  const AddToCart = (item, key) => {
    if (item.stock >= 1) {
      item["qtydemand"] = 1;
      var list = getPage;
      list[key]["cartstatus"] = 1;
      setPage(list);
      dispatch({ type: "ADD_CART", payload: [item.productid, item] });
      setCount(!getCount);
    } else {
      handleShowProductView(item.productid, item.modelid);
    }
  };

  // Show Products //
  const ShowProducts = () => {
    return getPage.map(function (item, key) {
      var save = item.price - item.offerprice;
      var o = 1;
      var color = "green";
      if (item.status == "Coming Soon" || item.status == "Pre Booking") {
        o = 0.5;
        if (item.status == "Coming Soon") {
          color = "red";
        }
      }
      var st = false;
      var discount = Math.ceil((save / item.price) * 100);
      item["cartstatus"] = 0;
      //console.log("item",item)
      cartItems.map(function (product, key) {
        if (item.colorid == product.colorid) {
          item.qtydemand = product.qtydemand;
          item.cartstatus = 1;
          st = true;
        }
      });

      return (
        <div className={classes.productAction}>
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%' }} >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} >
                <Paper elevation={3} onClick={() => handleShowProductView(item.productid, item.modelid)}>

                  {/* <div class="show" > */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-end",
                      cursor: 'pointer',
                      padding: 10,
                      // transition: 'transform 1s;'
                    }}

                    onClick={() => handleShowProductView(item.productid, item.modelid)}

                  >

                    {/* <Hidden smUp>
                  <Image
                      width={200}
                      height={250}
                      style={{ opacity: o, borderRadius: 100 }}
                      alt={item.productname}
                      src={`${ServerURL}/images/${item.picture}`}
                    // onClick={() => handleShowProductView(item.productid)}
                    />
                  </Hidden> */}
                    <Hidden mdUp>
                      <img
                        width={187}
                        height={220}
                        // style={{ opacity: o, borderRadius: 100 }}
                        alt={item.productname}
                        className={classes.hoverimg}
                        src={`${ServerURL}/images/${item.picture}`}
                      // onClick={() => handleShowProductView(item.productid)}
                      />
                      <div style={{ position: 'absolute', zIndex: 1, opacity: 1, }}>
                        <img src="/images/dis.png" />
                        <div style={{ position: 'absolute', top: 9, color: 'rgb(255, 255, 255)', fontSize: 12, fontWeight: 'bold', left: 12, textAlign: 'center', cursor: 'pointer' }}>
                          <p>{discount}% </p><p>Off</p>
                        </div>
                      </div>

                    </Hidden>
                    <Hidden smDown>
                      <img
                        // width="237" height="280"
                        // style={{}}
                        alt={item.productname}
                        // className={classes.hoverimg}
                        class="img2"
                        src={`${ServerURL}/images/${item.picture}`}
                      // onClick={() => handleShowProductView(item.productid)}
                      />
                      <div style={{ position: 'absolute', zIndex: 1, opacity: 1, }}>
                        <img src="/images/dis.png" />
                        <div style={{ position: 'absolute', top: 9, color: 'rgb(255, 255, 255)', fontSize: 12, fontWeight: 'bold', left: 12, textAlign: 'center', cursor: 'pointer' }}>
                          <p>{discount}% </p><p>Off</p>
                        </div>
                      </div>
                      {/* <StyleRoot>
                        <div style={style}>
                          <div style={{color:'#fff',fontWeight:500}}><p style={{fontSize:16}}>{discount}<small>%</small></p><small>Off</small></div>
                        </div>
                    </StyleRoot> */}

                    </Hidden>

                    <div id="axis" style={{ flexDirection: 'column', display: 'flex', }}>
                      <span style={{ position: 'absolute', marginLeft: -40, marginTop: 40, cursor: 'pointer' }} class="mousehover"> <ShoppingCart onClick={() => handleShowProductView(item.productid, item.modelid)} style={{ color: '#212121' }} /><FavoriteBorderIcon style={{ color: '#212121' }} /><SearchIcon style={{ color: '#212121' }} /><img class="object van move-left" /></span>
                    </div>
                  </div>
                  {/* </div> */}
                  <div

                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderTop: "1px solid #dcdde1",
                      padding: 10,
                      maxWidth: '250px'
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div style={{ fontSize: 14, padding: "5px 0px", borderRadius: 3 }}>
                        <span
                          className="price_tag" style={{ backgroundColor: '#212121', color: '#fff' }}
                        >
                          {numberFormat(item.offerprice)}

                        </span>
                      </div>
                      <div style={{ float: 'right', marginLeft: 'auto', fontWeight: 600 }}>
                        <span>
                          <small>
                            MRP &nbsp;
                            <del>{numberFormat(item.price)}</del>&nbsp; <br />

                          </small>
                          {/* {discount > 5 ? (
                            <span style={{ color: "red", fontSize: 10,marginLeft:90,  position: 'absolute' }}>
                              {discount}% off
                            </span>
                          ) : (
                            <></>
                          )} */}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{ fontSize: 15, fontWeight: "bold", padding: "5px 0px 3px" }}
                    >
                      {item.brandname}
                    </div>
                    <Hidden smDown>
                      <div style={{ fontSize: 14, padding: "3px 0px", }}>
                        {item.productname.length >= 30
                          ? item.productname.toString().substring(0, 27) + "..."
                          :
                          item.productname}
                        {/* {item.productname} */}
                      </div>
                    </Hidden>
                    <Hidden mdUp>
                      <div style={{
                        fontSize: 14, padding: "3px 0px", overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}>
                        {item.productname.length >= 30
                          ? item.productname.toString().substring(0, 25) + "..."
                          :
                          item.productname}
                        {/* {item.productname} */}
                      </div>
                    </Hidden>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 0
                    }}
                  >
                    {item.status == "Pre Booking" ? (
                      <>
                        <Button
                          variant="contained"
                          fullWidth
                          style={{
                            height: 30,
                            padding: 5,
                            backgroundColor: "#212121",
                            color: "#ffffff",
                            borderRadius: 50
                          }}
                          onClick={() => handleShowProductView(item.productid, item.modelid)}
                        >
                          Pre Book Now &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        </Button>
                      </>
                    ) :
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          height: 40,
                          padding: 10,
                          backgroundColor: "#212121",
                          color: "#ffffff",
                          fontSize: 15,
                          letterSpacing: "0px",
                          fontWeight: "bold",
                          // width: 250,
                          borderRadius: 0
                        }}
                        onClick={() => handleShowProductView(item.productid, item.modelid)}
                      >
                        Add to Cart &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <big>
                          <ShoppingCart
                            style={{ marginTop: 6, backgroundColor: "transparent" }}
                          />{" "}
                        </big>
                      </Button>
                    }
                  </div>
                </Paper>
              </Grid>
            </Grid>

          </div>
        </div>
      );
    });
  };


  const handleShowProductView = (productid, modelid, qt) => {
    props.history.push({ pathname: `/ProductView/${productid}/${modelid}` }, qt);
  };

  const handlePages = (event, page) => {
    var list = [];
    var i = 0;
    getFilterList.map(function (item, index) {
      if (index >= (page - 1) * 20 && i < 20) {
        list.push(item);
        i++;
      }
    });
    setPage(list);
    setState(true);
  };

  const fetchCategory = async () => {
    var list = await getData("pack/category");
    setCategoryList(list);
  };

  const Categories = () => {
    return (
      <RadioGroup
        value={getCategory}
        onChange={(event) => setCategory(event.target.value)}
      >
        {getCategoryList.map(function (item, key) {
          return (
            <FormControlLabel
              value={item.categoryname}
              control={<Radio style={{ color: "#747d8c" }} size="small" />}
              label={<span style={{ fontSize: 11 }}>{item.categoryname}</span>}
            />
          );
        })}
      </RadioGroup>
    );
  };

  const PriceWise = () => {
    var arr = [];
    var list = [];
    getList.map((item) => {
      if (item.offerprice >= getPrice[0] && item.offerprice <= getPrice[1]) {
        arr.push(item);
      }
    });
    setFilterList(arr);
    //console.log(list)
    arr.map(function (item, index) {
      if (index < 20) {
        list.push(item);
      }
    });
    setPage(list);
    setAvailability("");
    setDiscount("");
    setOrder("");
  };

  const handlePrice = (event, newPrice) => {
    setPrice(newPrice);
    PriceWise();
  };

  const Price = () => {
    return (
      <div>
        <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
          <PrettoSlider
            value={getPrice}
            onChange={handlePrice}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            aria-label="pretto slider"
            min={getMinPrice}
            max={getMaxPrice}
          //style={{ color: "#747d8c" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: 12,
            padding: "0px 15px 10px",
          }}
        >
          <div style={{ width: "100%" }}>
            {" "}
            Min - {getPrice[0]}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {" "}
            Max - {getPrice[1]}{" "}
          </div>
        </div>
      </div>
    );
  };

  const SortWise = (order) => {
    var arr = [];
    switch (order) {
      case "Low to High":
        arr = getList.sort((a, b) => a.offerprice - b.offerprice);
        setFilterList(arr);
        break;
      case "High to Low":
        arr = getList.sort((a, b) => b.offerprice - a.offerprice);
        setFilterList(arr);
        break;
      case "Oldest to Newest":
        arr = getList.sort((a, b) => a.productid - b.productid);
        setFilterList(arr);
        break;
      case "Newest to Oldest":
        arr = getList.sort((a, b) => b.productid - a.productid);
        setFilterList(arr);
        break;
      default:
        return;
    }
    var list = [];
    arr.map(function (item, index) {
      if (index < 20) {
        list.push(item);
      }
    });
    setPage(list);
    setAvailability("");
    setPrice([getMinPrice, getMaxPrice]);
    setDiscount("");
  };
  const handleOrder = (event) => {
    setOrder(event.target.value);
    var order = event.target.value;
    SortWise(order);
  };
  const SortBy = () => {
    return (
      <RadioGroup value={getOrder} onChange={(event) => handleOrder(event)}>
        <FormControlLabel className={classes.FormControlLabel}
          value="Low to High"
          control={<Radio style={{ color: "#212121" }} size="small" />}
          label={<span style={{ fontSize: 13, fontFamily: 'Calibri' }}>Low to High</span>}
        />
        <FormControlLabel className={classes.FormControlLabel}
          value="High to Low"
          control={<Radio style={{ color: "#212121" }} size="small" />}
          label={<span style={{ fontSize: 13, fontFamily: 'Calibri' }}>High to Low</span>}
        />
        <FormControlLabel className={classes.FormControlLabel}
          value="Oldest to Newest"
          control={<Radio style={{ color: "#212121" }} size="small" />}
          label={<span style={{ fontSize: 13, fontFamily: 'Calibri' }}>Oldest to Newest</span>}
        />
        <FormControlLabel className={classes.FormControlLabel}
          value="Newest to Oldest"
          control={<Radio style={{ color: "#212121" }} size="small" />}
          label={<span style={{ fontSize: 13, fontFamily: 'Calibri' }}>Newest to Oldest</span>}
        />
      </RadioGroup>
    );
  };

  const handleChangeAvailability = (event) => {
    setAvailability(event.target.value);
    var arr = [];
    getList.map((item) => {
      if (item.stock > 0) {
        arr.push(item);
      }
    });
    setFilterList(arr);
    var list = [];
    arr.map(function (item, index) {
      if (index < 20) {
        list.push(item);
      }
    });
    setPage(list);
    setDiscount("");
    setPrice([getMinPrice, getMaxPrice]);
    setOrder("");
  };

  const Availability = () => {
    return (
      <div style={{ fontSize: 15 }}>
        <Checkbox
          checked={getAvailabilty == "In Stock"}
          onChange={handleChangeAvailability}
          style={{ color: "#747d8c" }}
          value="In Stock"
        />
        <span style={{ padding: "10px 0px" }}>In Stock</span>
      </div>
    );
  };

  const DiscountWise = (discount) => {
    var arr = [];
    switch (discount) {
      case "40% or more":
        getList.map((item) => {
          var dis = Math.ceil(
            ((item.price - item.offerprice) / item.price) * 100
          );
          if (dis >= 40) {
            arr.push(item);
          }
        });
        setFilterList(arr);
        break;
      case "30% or more":
        getList.map((item) => {
          var dis = Math.ceil(
            ((item.price - item.offerprice) / item.price) * 100
          );
          if (dis >= 30) {
            arr.push(item);
          }
        });
        setFilterList(arr);
        break;
      case "20% or more":
        getList.map((item) => {
          var dis = Math.ceil(
            ((item.price - item.offerprice) / item.price) * 100
          );
          if (dis >= 20) {
            arr.push(item);
          }
        });
        setFilterList(arr);
        break;
      case "10% or more":
        getList.map((item) => {
          var dis = Math.ceil(
            ((item.price - item.offerprice) / item.price) * 100
          );
          if (dis >= 10) {
            arr.push(item);
          }
        });
        setFilterList(arr);
        break;
      default:
        return;
    }
    var list = [];
    arr.map(function (item, index) {
      if (index < 20) {
        list.push(item);
      }
    });
    setPage(list);
    setAvailability("");
    setPrice([getMinPrice, getMaxPrice]);
    setOrder("");
  };

  const handleDiscount = (event) => {
    setDiscount(event.target.value);
    DiscountWise(event.target.value);
  };

  const Discount = () => {
    return (
      <div>
        <RadioGroup
          value={getDiscount}
          onChange={(event) => handleDiscount(event)}
        >
          <FormControlLabel className={classes.FormControlLabel}
            value="40% or more"
            control={<Radio style={{ color: "#212121" }} size="small" />}
            label={<span style={{ fontSize: 13, fontFamily: 'Calibri' }}>40% or more</span>}
          />
          <FormControlLabel className={classes.FormControlLabel}
            value="30% or more"
            control={<Radio style={{ color: "#212121" }} size="small" />}
            label={<span style={{ fontSize: 13, fontFamily: 'Calibri' }}>30% or more</span>}
          />
          <FormControlLabel className={classes.FormControlLabel}
            value="20% or more"
            control={<Radio style={{ color: "#212121" }} size="small" />}
            label={<span style={{ fontSize: 13, fontFamily: 'Calibri' }}>20% or more</span>}
          />
          <FormControlLabel className={classes.FormControlLabel}
            value="10% or more"
            control={<Radio style={{ color: "#212121" }} size="small" />}
            label={<span style={{ fontSize: 13, fontFamily: 'Calibri' }}>10% or more</span>}
          />
        </RadioGroup>
      </div>
    );
  };

  const Size = () => {
    return (<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {
        getSizeByColorAndProduct.map(function (item, key) {
          return (
            <div value={item.color}
              onClick={(event) => handleSize(item.sizeno)}
              style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                backgroundColor: getSelectedSize == item.sizeno ? '#000000' : '#ffffff',
                color: getSelectedSize == item.sizeno ? '#ffffff' : '#000000',
                borderRadius: getSelectedSize == item.sizeno ? 0 : 50,
                margin: 3,
                border: '1px solid #ced6e0',
                height: 30, width: 30,
                fontSize: 12,
              }}>
              {item.sizeno}
            </div>
          );
        })
      }
    </div>
    );
  };
  const handleSize = async (data) => {
    // alert(JSON.stringify(data))
    setSelectedSize(data)
    SizeWise(data)
  };
  const SizeWise = async (data) => {
    // alert(data)
    var arr = [];
    getList.map((item) => {
      // alert(data)
      if (item.sizes.includes(data)) {
        arr.push(item);
      }
    })
    setFilterList(arr);
    var list1 = []
    arr.map(function (item, index) {
      if (index < 20) {
        list1.push(item);
      }
    });
    setPage(list1);
    setAvailability("");
    setDiscount("");
    setOrder("");
  };

  const Colors = () => {
    return (<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {
        getColors.map(function (item, key) {
          return (
            // <FormControlLabel
            //   value={item.categoryname}
            //   control={<Radio style={{ color: "#747d8c" }} size="small" />}
            //   label={<span style={{ fontSize: 11 }}>{item.categoryname}</span>}
            // />
            <>
              <Tooltip title={item.colorName} classes={classess} arrow placement="top">
                <div value={item.color}
                  onClick={(event) => handleColor(item.color, item.colorid)}
                  style={{ cursor: 'pointer', backgroundColor: item.color, margin: 3, border: '1px solid #ced6e0', height: 18, width: 18, borderRadius: getSelectedColor.includes(item.color) ? 0 : 50 }} />
              </Tooltip>
              {/* <FormControlLabel value={item.color} control={<Checkbox style={{color:item.color}} name="checkedC" checked={getSelectedColor.includes(item.color) || false} onChange={(event) => handleColor(event.target.checked, item.color)}
              />} label={item.colorName} /> */}
            </>
          );
        })
      }
    </div>
    );
  };

  const handleColor = async (data, cid) => {
    console.log("cid, ", cid)

    if (!getSelectedColor.includes(data)) {
      var prev = [...getColorsIdList]
      prev.push(cid)
      setColorsIdList(prev)
      var data1 = [...getSelectedColor]
      data1.push(data)
      setSelectedColor(data1)
      ColorWise(data1, prev);
    } else {
      var prev = [...getColorsIdList]
      const index1 = prev.indexOf(cid);
      if (index1 > -1) {
        prev.splice(index1, 1); // 2nd parameter means remove one item only
      }
      setColorsIdList(prev)
      var arr = [...getSelectedColor];
      const index = arr.indexOf(data);
      if (index > -1) {
        arr.splice(index, 1); // 2nd parameter means remove one item only
      }
      setSelectedColor(arr)
      ColorWise(arr, prev);
    }
  };
  const ColorWise = async (data, prevColor) => {
    console.log(data, prevColor)
    var arr = [];
    var pid = []
    getList.map((item) => {
      if (data.length) {
        data.map(it => {
          if (item.colors.includes(it)) {
            arr.push(item);
            pid.push(item.productid)
          }
        })
      } else {
        arr.push(item);
        pid.push(item.productid)
      }

    });
    setFilterList(arr);
    // let body = { pid: pid, cid: prevColor };
    // var list = await postData("size/getsizebycolororproduct", body);
    // setSizeByColorAndProduct(list)
    var list1 = []
    arr.map(function (item, index) {
      if (index < 20) {
        list1.push(item);
      }
    });
    setPage(list1);
    setAvailability("");
    setDiscount("");
    setOrder("");
  };



  const Filters = () => {
    return (
      <div className={classes.center} style={{ position: 'sticky', top: 80 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12}>
            <Paper elevation={0} className={classes.paper}>
              <div className={classes.filterStyleHeading}>
                {" "}
                Filters
              </div>
              <Divider />
              <div className={classes.filterStyle}>PRICE</div> {Price()}{" "}
              <Divider />
              <div>
                <Accordion elevation={0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.sidenavheading}
                  >
                    SORT BY
                  </AccordionSummary>
                  <AccordionDetails>{SortBy()}</AccordionDetails>
                </Accordion>
              </div>
              <Divider />
              <div>
                <Accordion elevation={0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.sidenavheading}
                  >
                    DISCOUNT
                  </AccordionSummary>
                  <AccordionDetails>{Discount()}</AccordionDetails>
                </Accordion>
              </div>
              <Divider />
              {getSizeByColorAndProduct.length > 0 ? <><div>
                <Accordion elevation={0} >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.sidenavheading}
                  // style={{ fontSize: 14, fontWeight: 'bold', }} 
                  >
                    SIZE
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ display: 'block' }}>
                      {Size()}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div></> : <></>}
              <div>
                <Divider />
                <Accordion elevation={0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.sidenavheading}
                  >
                    COLORS
                  </AccordionSummary>
                  <AccordionDetails>{Colors()}</AccordionDetails>
                </Accordion>
              </div>
              <Divider />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  };

  const ProductsData = () => {
    return (
      <Paper elevation={0} className={classes.paper}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: 'flex-start'
            // margin: "20px 10px",
          }}
        >
          <div style={{ display: "flex", padding: 11 }}>
            <div style={{ width: "100%" }}>

              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link className={classes.span} style={{ textDecoration: 'none' }}>
                  {getModel}
                  {/* {getCategory} */}
                </Link>
                {/* <Typography color="textPrimary" className={classes.span}>{getModel}</Typography> */}
              </Breadcrumbs>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", textAlign: "center", width: "100%", padding: '5px 10px 0px 0px', }}>
              <span className={classes.span}>Showing{" "}<b>{getPage.length}{" "}/{" "}{getFilterList.length}</b>{" "}items</span>
            </div>
            <div
              className={classes.hover}
              style={{
                borderRadius: 20,
                background: '#212121',
                color: '#fff',
                padding: "5px 10px",
                width: 200,
                //height:20,
                cursor: "pointer",
                textAlign: "center",
                justifyContent: "center",
                fontSize: 13,
                fontFamily: 'Calibri'
              }}
              onClick={() => fetchData()}
            >
              All Products
            </div>
          </div>
          <Divider />
          <div className={classes.scardview}>
            {getFilterList.length == 0 ? (
              <img src={`/images/empty.png`} />
            ) : (
              ShowProducts()
            )}
          </div>
          {getFilterList.length / 20 <= 1 ? (
            <></>
          ) : (
            <div className={classes.center} style={{ width: "100%" }}>
              <Pagination
                count={Math.ceil(getFilterList.length / 20)}
                variant="outlined"
                shape="rounded"
                onChange={(event, page) => handlePages(event, page)}
              />
            </div>
          )}
        </div>
      </Paper>
    );
  };

  return (
    <div>
      <Header history={props.history} />
      {/* <div className={classes.center}> */}
      <div
        style={{ padding: "10px 10px" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            {Filters()}
          </Grid>
          <Grid item xs={12} md={10}>
            {getState ? (
              ProductsData()
            ) : (
              <div style={{ marginTop: 100, display: 'flex', justifyContent: 'center' }}>
                {/* <CircularProgress style={{ color: "#ff7979" }} /> */}
                <img src="/images/logo/animated-svg-loading-icon-13.jpg" width="500" />
                {/* <img src="/images/logo/preview.gif" width="200" />
                <img src="/images/logo/BlandMildDungbeetle-size_restricted.gif" width="200" />
                <img src="/images/logo/dot.gif" width="200" />
                <img src="/images/logo/shopping-loader.gif" width="200" /> */}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      {/* </div> */}
      <Footer history={props.history} />
    </div>
  );
}

