import React, { useEffect, useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Header from './Header';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { postData } from '../FetchNodeServices';
import { checkRequire, checkMobile, checkEmail, checkUserPassword } from '../Checks';
import Icon from '@material-ui/core/Icon';




const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
    align: {
        textAlign: 'center',
        fontSize: 17,
        fontFamily: 'Calibri',
        color: '#212121',
        padding: '5px 2px'
    },
    css: {
        fontSize: 15,
        fontFamily: 'Calibri',
        color: '#212121',
        padding: '5px 2px'
    },
    err: {
        color: 'red',
        fontSize: 12,
        padding: '3px 0px',
        position: 'd'
    }

}));

export default function GetInTouch(props) {
    const classes = useStyles();
    const [getName, setName] = useState('')
    const [getEmail, setEmail] = useState('')
    const [getMobile, setMobile] = useState('')
    const [getSub, setSub] = useState('')
    const [getMessage, setMessage] = useState('')
    const [getErrName, setErrName] = useState('')
    const [getErrEmail, setErrEmail] = useState('')
    const [getMsg, setMsg] = useState('')
    const [getcheck, setcheck] = useState(false)



    const CheckSession = async () => {
        if (localStorage.getItem('user')) {
            var user = JSON.parse(localStorage.getItem('user'))
            setMobile(user.mobileno)
            CheckTouch(user.mobileno)
        }
        else {
            props.history.replace({ pathname: `/UserLogin` })
        }
    }
    useEffect(function () {
        window.scrollTo(0, 0)
        CheckSession()
    }, [])

    const CheckTouch = async (mobile) => {
        var result = await postData('touch/checktouch', { mobileno: mobile })
        if (result.RESULT) {
            setcheck(true)
        }
    }


    const handleErrReset = () => {
        setErrEmail("")
        setErrName("")
        setMsg("")
    }


    const handleReset = () => {
        setEmail("")
        setName("")
        setMessage("")
        setSub("")
    }

    const handleSubmit = async () => {
        handleErrReset()
        var err = false;
        if (!checkRequire(getName)) {
            err = true
            setErrName('Name can not be empty')
        }
        if (!checkRequire(getEmail)) {
            err = true
            setErrEmail('Email can not be empty')
        }
        if (checkRequire(getEmail)) {
            if (!checkEmail(getEmail)) {
                err = true
                setErrEmail('Invalid Email')
            }
        }
        if (!err) {
            var body = { mobileno: getMobile, name: getName, email: getEmail, subject: getSub, message: getMessage }
            if (getcheck) {
                var res = await postData('touch/edit', body)
                if (res.RESULT) {
                    setMsg("Message Sent Successfully")
                    handleReset()
                }
                else {
                    alert("Server Error")
                }
            }
            else {
                var res = await postData('touch/add', body)
                if (res.RESULT) {
                    setMsg("Message Sent Successfully")
                    setcheck(true)
                    handleReset()
                }
                else {
                    alert("Server Error")
                }
            }

        }

    }

    return (
        <div >
            <Header history={props.history} />
            <div className={classes.center}>
                <Paper elevation={0} style={{ width: window.innerWidth * 0.6, margin: 20, padding: 50, borderRadius: 20 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', paddingTop: 40 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p className={classes.align}><b><big>GET IN TOUCH</big></b></p>
                                <p className={classes.align}>We are looking forward to hearing from you</p>

                                <p className={classes.align}>For any  Support or Design Requirements</p>
                                <p className={classes.align}>Reach us</p>

                                <p className={classes.align}><b>Email</b> : support@the7shades.in</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.center}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label className={classes.css}>Your Name <font color="red" size="3">*</font></label>
                                <TextField variant="outlined" size="small" focused={false} value={getName} onChange={(event) => setName(event.target.value)} />
                                <p className={classes.err}>{getErrName}</p>
                                <label className={classes.css}>Your Email  <font color="red" size="3">*</font></label>
                                <TextField variant="outlined" size="small" focused={false} value={getEmail} onChange={(event) => setEmail(event.target.value)} />
                                <p className={classes.err}>{getErrEmail}</p>
                                <label className={classes.css}>Subject</label>
                                <TextField variant="outlined" size="small" focused={false} value={getSub} onChange={(event) => setSub(event.target.value)} />
                                <label className={classes.css}>Message</label>
                                <TextField variant="outlined" size="large" focused={false} value={getMessage} onChange={(event) => setMessage(event.target.value)} />

                                <Button style={{ height: 40, color: '#fff', backgroundColor: '#212121', padding: 5, margin: '20px 0px' }}
                                    endIcon={<Icon>send</Icon>} variant="contained" onClick={() => handleSubmit()} >
                                    Send
                                </Button>

                                <div style={{ padding: '10px 0px', fontFamily: 'Calibri' }}>
                                    <b>{getMsg}</b>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </div>


    );
}