import React, { useEffect, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { getData, postData, ServerURL } from "../FetchNodeServices";
import QtyCtrl from "./QtyCtrl";
import Button from "@material-ui/core/Button";
import Header from "./Header";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PaymentIcon from "@material-ui/icons/Payment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { blue } from "@material-ui/core/colors";
import Image from "react-image-resizer";
import Footer from './Footer'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  status: {
    danger: '#e57373',
  },
  palette: {
    primary: {
      main: '#343434',
      light: '#ff9800',
      darker: '#e65100',
      contrastText: '#fff',
    },
    neutral: {
      main: '#e57373',
      contrastText: '#fff',
    },
  },
});
const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    margin: 10,
    backgroundColor: "#FFFFFF",
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 5px 0px',
    borderRadius: 0
    // padding: 20,
    // margin: 10,
    // backgroundColor: "#FFFFFF",
    // boxShadow:'rgb(0 0 0 / 20%) 0px 1px 5px 0px',
    // borderRadius:0
  },
  grow: {
    flexGrow: 1,
  },
  heading: {
    fontSize: 17,
    color: '#212121',
    fontWeight: 600
  },
  paperPayment: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  rowStart: {
    fontSize: 14,
    paddingTop: 10,
    fontWeight: 500,
    display: "flex",
    justifyContent: "flex-start",
    width: 300,
  },
  rowEnd: {
    fontSize: 14,
    padding: 10,
    display: "flex",
    justifyContent: "flex-end",
    width: 300,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'row',
    color: '#707070',
    fontFamily: 'Calibri',
  },
  span: {
    fontWeight: 450,

    fontFamily: 'Calibri'
  },
  hover: {
    "&:hover": {
      //background: "#de011b",
      background: "#FFC312",
      transform: "scale(1)",
      //transition: "all 0.5s ease 0s",
      color: "#000",
    },
  },
  divider: {
    //width: '100%',
    margin: '10px -20px 0px -20px'
  },
}));

export default function ShowCart(props) {
  var cart = useSelector((state) => state.cart);
  var account = JSON.parse(localStorage.getItem('user'))
  // alert(JSON.parse(localStorage.getItem('user')))
  var length = Object.keys(cart).length;
  var cartItems = Object.values(cart);
  // alert(JSON.stringify(cart))
  const [getCount, setCount] = useState(false);
  const [getCoupon, setCoupon] = useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();
  var total = cartItems.reduce(calculate, 0);
  var totalsavings = cartItems.reduce(calculatesavings, 0);
  const [delivery, setDelivery] = useState(0);
  const [getNetAmount, setNetAmount] = useState();
  const getcoupon = localStorage.getItem("getcoupon")
  const [getCouponApply, setCouponApply] = useState(getcoupon != "" && getcoupon);
  const [getCouponDiscount, setCouponDiscount] = useState(0);
  const [getButtonStatus, setButtonStatus] = useState(0);
  const [getMsg, setMsg] = useState("");
  const [getCouponStatus, setCouponStatus] = useState(0);
  function calculate(a, b) {
    ////console.log("calculate-",a,b)
    var price = a + (b.offerprice != 0 ? b.offerprice : b.price) * b.qtydemand;
    return price;
  }
  function calculatesavings(a, b) {
    // //console.log("calculatesavings-",a,b)
    var saveprice = a + (b.price - b.offerprice) * b.qtydemand;
    return saveprice;
  }

  useEffect(
    function () {
      fetchdelivery();
      handleCouponApply()
      fetchcoupon();
      window.scrollTo(0, 0);
    },
    [total]
  );

  const fetchcoupon = async () => {
    var result = await getData("coupon/displayallcoupon");
    setCoupon(result.result);
  };

  const fetchdelivery = async () => {
    var result = await getData("deliverycharge/filter/" + total);
    if (result.status) {
      if (result.result.length != 0) {
        setDelivery(result.result[0].charge);
      }
    }
  };
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);

  //Count Cart Items//
  const handleQtyChange = (value, item, key) => {
    // alert(JSON.stringify(item))
    if (value >= 1 && value <= item.stock) {
      item["qtydemand"] = value;
      dispatch({ type: "ADD_CART", payload: [item.colorid, item] });
      setCount(!getCount);
    } else if (value == 0) {
      item["qtydemand"] = value;
      //var list=cartItems
      //list[key]['cartstatus']=0
      dispatch({ type: "REMOVE_CART", payload: [item.colorid, item] });
      setCount(!getCount);
    } else {
    }
    handleCouponApply()
  };

  const ShowCouponApply = () => {
    return getCoupon.map((item, key) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px 10px 0px' }}>
          <LocalOfferIcon style={{ color: '#212121', fontSize: 16 }} />
          <div title={"Click to set code"} style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => setCouponApply(item.coupon_code)}>
            Flat &#8377;{item.amount_off} off on orders above &#8377;{item.min_price} - <b>({item.coupon_code})</b>
          </div>
        </div>
      )
    })
  }


  const ShowCartItems = () => {
    return cartItems.map(function (item, key) {
      // //console.log("show item",item)
      var save = item.price - item.offerprice;
      var discount = Math.ceil((save / item.price) * 100);

      return (
        <div>
          <div
            container
            spacing={0}
            style={{
              padding: "10px 0px 0px 0px",
              display: "flex",
              justifyContent: "space-between",
              // alignItems: "flex-start",
            }}
          >
            <div style={{ width: '90px' }}>
              {/* <div style={{ width: 100, height: 100 }}> */}
              {" "}
              <img
                src={`${ServerURL}/images/${item.picture}`}
                // width={100}
                // height={100}
                style={{ width: 85, height: 100 }}
              />
              {/* </div> */}
            </div>
            <div style={{ width: '70%', paddingLeft: 10 }}
            // style={{
            //   padding: "0px 0px 10px 10px", margin: 0
            // }}
            >
              <div>
                <span style={{ display: 'block', fontSize: 17, fontWeight: 600, fontFamily: 'Calibri' }}>{item.productname}</span>
              </div>
              <div>
                <span style={{ display: 'block', fontSize: 13, fontWeight: 500, color: 'grey', }}>Size: {item.size != "none" ? <font>
                  {item.size}
                </font> : <></>}</span>
              </div>
              {/* {item.size != "none" ?  <div style={{ fontSize: 15, padding: "3px 7px" }}>
                <b>Size -</b> {item.size}
              </div>:<></>} */}
              <div style={{ display: 'block', fontSize: 18, color: '#FF2341', fontWeight: 500, display: 'flex', flexDirection: 'column' }}>
                <small style={{ display: 'block', color: 'grey', fontSize: 12 }}>
                  M.R.P. <s> {numberFormat(item.price)}</s>
                </small>
                <span >Price: {numberFormat(item.offerprice)}</span>
                {/* <span >Price: {numberFormat(item.offerprice)}</span> */}
              </div>
              <div style={{ fontSize: 14, fontWeight: 500, color: '#009432', }}>

                You Save {numberFormat(save)} ({discount}%off)

              </div>
            </div>
            <div
              // item
              // xs={12}
              // sm={3}
              style={{
                display: "flex",
                padding: "5px 0px 5px 5px", justifyContent: "flex-end", alignItems: 'flex-end', width: '15%'
              }}
            // style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              {item.cartstatus == 1 ? (
                <QtyCtrl
                  value={item.qtydemand}
                  onChange={(value) => handleQtyChange(value, item, key)}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
          {length != key + 1 ? <Divider className={classes.divider} /> : <div></div>}
        </div >
      );
    });
  };
  // alert(localStorage.getItem("getcoupon"))
  const handleCouponApply = async () => {

    var totall = cartItems.reduce(calculate, 0);
    if (!!getCouponApply) {
      let body = { coupon_code: getCouponApply };
      let result = await postData("coupon/checkcoupon", body);
      // alert(totall+" "+result.result[0].min_price)
      if (result.result[0].amount_off != 0 && totall >= result.result[0].min_price) {
        var total1 = totall - result.result[0].amount_off;
        setNetAmount(total1)
        localStorage.setItem("getcoupon", getCouponApply);
        setCouponDiscount(result.result[0].amount_off)
        setButtonStatus(1)
        setMsg(<span style={{ marginTop: 10, color: '#4cd137', fontWeight: 600, padding: 5 }}>&#8377;{result.result[0].amount_off} Your coupon ({result.result[0].coupon_code}) was successfully applied.</span>)
      }
      else if (result.result[0].percentage_off != 0 && total1 >= result.result[0].min_price) {
        var total1 = totall - (result.result[0].percentage_off * total1) / 100
        setNetAmount(total1)
        // alert(total1)
        setCouponDiscount((result.result[0].percentage_off * total1) / 100)
        setButtonStatus(1)
        setMsg(<span style={{ marginTop: 10, color: '#4cd137', fontWeight: 600, padding: 5 }}>Your coupon ({result.result[0].coupon_code}) was successfully applied.</span>)
      }
      else {
        setCouponDiscount(0)
        setButtonStatus(0)
        setMsg(<span style={{ marginTop: 10, color: '#EA2027', fontWeight: 600, padding: 5 }}>Shop for &#8377;{result.result[0].min_price} or more to apply this coupon.</span>)
      }
    }
    else {
      // setMsg("This is a required field")
      // alert("This is a required field")
      // setMsg("Invalid Coupon Code!")
    }
  }

  const handleCouponCancel = () => {
    setButtonStatus(0)
    setCouponDiscount(0)
    setCouponApply("")
    setMsg("")
    localStorage.removeItem("getcoupon")
    // setNetAmount("")
  }

  const ShowCoupon = () => {
    //fetchdelivery()
    return (
      <div>
        {account ? <>
          <div className={classes.center}>
            <ThemeProvider theme={theme}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12}>
                  <Paper elevation={0} className={classes.paper}>
                    <div className={classes.heading} style={{ display: 'flex', alignItems: 'center' }}>
                      <LocalOfferIcon style={{ fontSize: 16 }} /> <div style={{ marginLeft: 10 }}>Have a coupon/referral code?</div>
                      {/* {getMsg} */}
                    </div>
                    <Divider className={classes.divider} />
                    <div style={{ marginTop: 10, display: 'flex' }}>
                      {getButtonStatus ? <>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={9}>
                            <TextField size="small" disabled value={getCouponApply} onChange={(event) => setCouponApply(event.target.value)} fullWidth id="outlined-basic" label="Enter Coupon Code" variant="outlined" />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Button
                              fullWidth
                              style={{ padding: 9, fontSize: 12, color: '#fff', backgroundColor: '#212121', WebkitBoxShadow: '0 5px 19px 2px rgba(0,0,0,0)', }}
                              onClick={() => handleCouponCancel()}
                            >
                              Cancel
                            </Button>
                          </Grid>
                        </Grid>
                      </> : <>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={9}>
                            <TextField size="small" value={getCouponApply} onChange={(event) => setCouponApply(event.target.value)} fullWidth id="outlined-basic" label="Enter Coupon Code" variant="outlined" />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Button
                              fullWidth
                              style={{ padding: 9, fontSize: 12, color: '#fff', backgroundColor: '#212121', WebkitBoxShadow: '0 5px 19px 2px rgba(0,0,0,0)', }}
                              onClick={() => handleCouponApply()}
                            >
                              Apply
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                      }
                    </div>
                    {getButtonStatus ? <div style={{ pointerEvents: 'none', opacity: '0.7' }}>{ShowCouponApply()}</div> : <div>{ShowCouponApply()}</div>}
                    <Divider className={classes.divider} />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <LocalOfferIcon style={{ fontSize: 16 }} /> <div style={{ marginLeft: 10 }}>Have a coupon/referral code?</div> */}
                      {getMsg}
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </ThemeProvider>
          </div></> : <>
          <div className={classes.center}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12}>
                <Paper elevation={0} className={classes.paper}>
                  <div className={classes.heading}>
                    <b>Apply Coupon</b>
                  </div>
                  <Divider className={classes.divider} />
                  <div style={{ paddingTop: 10 }}>
                    <Button
                      fullWidth
                      style={{ padding: 8, fontSize: 20, color: '#fff', backgroundColor: '#212121', WebkitBoxShadow: '0 5px 19px 2px rgba(0,0,0,0)', }}
                      onClick={() => handleUserLogin()}
                    >
                      User Login
                    </Button>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </>}
        {/* <Grid container spacing={0}> */}
        <Paper elevation={0} className={classes.paper}>
          <Grid item xs={12} sm={12}>
            <div className={classes.heading}>
              <b>Payment Details</b>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.paperPayment}>
              <div className={classes.rowStart}>M.R.P</div>
              <div className={classes.rowEnd}>
                {numberFormat(total + totalsavings)}
              </div>
            </div>
            <Divider />
            <div className={classes.paperPayment}>
              <div className={classes.rowStart}>Product Discount</div>
              <div className={classes.rowEnd}>
                <b style={{ color: '#009432' }}>- {numberFormat(totalsavings)}</b>
              </div>
            </div>
            <Divider />
            <div className={classes.paperPayment}>
              <div className={classes.rowStart}>Delivery Charges</div>
              <div className={classes.rowEnd}>
                <b>+ {numberFormat(delivery)}</b>
              </div>
            </div>
            <Divider />
            <div className={classes.paperPayment}>
              <div className={classes.rowStart}>
                <b>Total Amount</b>
              </div>
              <div className={classes.rowEnd}>
                <b>{numberFormat(total + parseInt(delivery))}</b>
              </div>
            </div>
            {getCouponDiscount ? <> <Divider />
              <div className={classes.paperPayment}>
                <div className={classes.rowStart}><b>Coupon Applied ({getCouponApply})</b></div>
                <div className={classes.rowEnd}>
                  <b style={{ color: '#009432' }}>- {numberFormat(getCouponDiscount)}</b>
                </div>
              </div>
              <Divider /></> : <>
              <Divider />
            </>}
            <div className={classes.paperPayment}>
              <div className={classes.rowStart}>
                <b style={{ fontSize: 18 }}>Net Amount</b>
              </div>
              <div className={classes.rowEnd}>
                <b style={{ fontSize: 18 }}>{numberFormat(total + parseInt(delivery) - parseInt(getCouponDiscount))}</b>
              </div>
            </div>
            <Divider />
            <div
              style={{ display: "flex", justifyContent: "flex-start", color: '#009432', fontWeight: 500, fontSize: 16, padding: '10px 0px 5px 0px', }} >
              You will save {numberFormat(totalsavings + parseInt(getCouponDiscount))} on this order
            </div>
          </Grid>
          <Divider className={classes.divider} style={{ marginBottom: 20 }} />
          <Grid item xs={12} sm={12}>
            {account ? <> <Button
              fullWidth
              style={{ padding: 8, fontSize: 20, color: '#fff', backgroundColor: '#212121', WebkitBoxShadow: '0 5px 19px 2px rgba(0,0,0,0)', }}
              onClick={() => handleUserLogin()}
            >
              Place Order
            </Button></> : <> <Button
              fullWidth disabled
              style={{ padding: 8, fontSize: 20, color: '#fff', backgroundColor: '#212121', WebkitBoxShadow: '0 5px 19px 2px rgba(0,0,0,0)', }}
              onClick={() => handleUserLogin()}
            >
              Place Order
            </Button></>}

          </Grid>
        </Paper>
        {/* </Grid> */}

      </div>
    );
  };
  const handleUserLogin = () => {

    if (!localStorage.getItem("user")) {
      props.history.push({ pathname: "UserLogin" });
    } else {
      var user = JSON.parse(localStorage.getItem("user"));
      props.history.push(
        { pathname: "/ShowCartWithAddress" },
        { mobileno: user.mobileno, data: user, cartdata: cartItems, codecoupon: getCouponApply }
      );
    }
  };
  const handleProducts = () => {
    props.history.push({ pathname: "/" });
  };

  return (
    <div >
      <Header history={props.history} />
      {length != 0 ? (
        <div className={classes.center}>
          <div style={{ width: "98%", margin: "10px 5px", }}>
            <Grid container spacing={0}>
              {/* <Grid item xs={12} md={12}>
                <Paper elevation={0} className={classes.paper}>
                  <Grid item xs={12} sm={12} className={classes.center}>
                    <Grid item xs={12} sm={4} style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}>
                      <ShoppingCartIcon style={{ color: "#212121", fontSize: 18, }} />
                      <span style={{ fontWeight: 500, fontSize: 18, color: '#212121' }}>Your Cart</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Divider style={{ marginRight: 60 }} />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}>
                      <PaymentIcon style={{ color: "#212121", fontSize: 18 }} />
                      <span style={{ fontWeight: 500, fontSize: 18, color: '#212121' }}>Payment</span>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Divider style={{ marginRight: 60 }} />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}>
                      <AssignmentTurnedInIcon style={{ color: "#212121", fontSize: 18 }} />
                      <span style={{ fontWeight: 500, fontSize: 18, color: '#212121' }}>Order Summary</span>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid> */}
              <Grid item xs={12} md={8}>
                <Paper elevation={0} className={classes.paper}>
                  <Grid item xs={12} sm={12} className={classes.center}>
                    <Grid item xs={12} md={5}>
                      <div
                        style={{
                          fontSize: 17,
                          display: "flex",
                          color: '#444444',
                          fontWeight: 'bold'
                        }}
                      >
                        My Cart({length})
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div style={{ fontSize: 17, fontWeight: 500, }}>
                        Order Summary
                        <small> ({length} items)</small>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={2} style={{ alignItems: 'center', justifyContent: 'right', justifyItems: 'right' }}>
                      <div style={{ fontSize: 17, color: '#009432', fontWeight: 500, float: 'right' }}>
                        Total: {numberFormat(total)}
                      </div>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid item xs={12} sm={12}>
                    {ShowCartItems()}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={1} style={{ position: 'sticky', top: 75 }}>
                  <Grid item xs={12} md={12}>

                    <div style={{ position: 'sticky' }}>{ShowCoupon()}</div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div
          className={classes.center}
          style={{ margin: "50px 0px 0px", flexDirection: "column" }}
        >
          <div>
            <img src="/images/empty.png" />
          </div>
          <div style={{ textAlign: "center", color: '#212121' }}>
            Your Cart is empty!
            <br />
            You have no items added in the cart.
          </div>
          <div style={{ margin: 10 }}>
            <Button
              startIcon={<AddShoppingCartIcon />}
              style={{ width: 300, color: '#fff', backgroundColor: '#212121' }}
              onClick={() => handleProducts()}
            >
              Add Products
            </Button>
          </div>
        </div>
      )}
      <Footer history={props.history} />
    </div>
  );
}
/* */
