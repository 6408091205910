import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import { checkRequire, checkMobile, checkEmail, checkUserPassword } from '../Checks';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { postDataAndImage, getData, postData } from '../FetchNodeServices'
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import { statecity } from '../statecity/StateCity';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PhoneIcon from '@material-ui/icons/Phone';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import Divider from "@material-ui/core/Divider";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import swal from 'sweetalert';

const theme = createMuiTheme({
  status: {
    danger: '#e57373',
  },
  palette: {
    primary: {
      main: '#343434',
      light: '#ff9800',
      darker: '#e65100',
      contrastText: '#fff',
    },
    secondary: {
      main: '#343434',
      contrastText: '#fff',
    },
  },
});
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px 10px',
    padding: 10,
    flexDirection: 'column'
  },
  divider: {
    margin: 15,
    backgroundColor: '#ED8267',
    height: 3,
    width: '70%',
  },
  paperStyle: {
    width: 'auto',
    margin: 10,
    padding: '5px 30px 5px 5px',
    height: 'auto',
    borderRadius: 10,
    backgroundColor: '#FFFFFF'
  },
  avator: {
    width: 'auto',
    height: 'auto',
    borderRadius: 10
  },
  gridStyle: {
    display: 'flex',
    padding: '0px 20px 0px 20px',
    flexDirection: 'row'
  },
  red: {
    color: '#ffffff',
    backgroundColor: '#de011b',
    width: 55,
    height: 55,
    margin: '10px 30px',

  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  textfieldStyle: {
    margin: '4px 4px',
    backgroundColor: '#f8f9f9'
  },
  divRow: {
    display: 'flex', flexDirection: 'row',
    fontSize: 12,
  },
  error: {
    color: 'red',
    position: "absolute",
    fontSize: 12,
    margin: '0px 4px'
  },
  filterStyleHeading: {
    display: 'flex',
    fontSize: 20,
    fontFamily: 'Calibri',
    color: '#000000',
    letterSpacing: 1,
    fontWeight: 600,
  },
  filterStyle: {
    fontSize: 14,
    fontFamily: 'Calibri',
    border: '1px solid #dfe6e9',
    //letterSpacing:0.5, 
    borderRadius: 1,
    padding: 10,
  },
  padding: {
    padding: 2,
    fontFamily: 'Calibri'
  },
}));

export default function AddAddress(props) {
  const classes = useStyles()

  const ITEM_HEIGHT = 30;
  const [getFirstName, setFirstName] = useState('')
  const [getLastName, setLastName] = useState('')
  const [getEmail, setEmail] = useState('')
  const [getMobile, setMobile] = useState('')
  const [getState, setState] = useState('')
  const [getCity, setCity] = useState('')
  const [getCountry, setCountry] = useState('India')
  const [getZipcode, setZipcode] = useState('')
  const [getAddress1, setAddress1] = useState('')
  const [getAddress2, setAddress2] = useState('')
  const [getOpen, setOpen] = useState(false)
  const [getMsg, setMsg] = useState('')
  const [getStateMsg, setStateMsg] = useState('')
  const [getCityMsg, setCityMsg] = useState('')
  const [getCountryMsg, setCountryMsg] = useState('')
  const [getZipcodeMsg, setZipcodeMsg] = useState('')
  const [getAddress1Msg, setAddress1Msg] = useState('')
  const [getAddress2Msg, setAddress2Msg] = useState('')
  const [getStateForm, setStateForm] = useState('')
  const [getCityForm, setCityForm] = useState('')
  const [getCountryForm, setCountryForm] = useState('India')
  const [getZipcodeForm, setZipcodeForm] = useState('')
  const [getAddress1Form, setAddress1Form] = useState('')
  const [getAddress2Form, setAddress2Form] = useState('')
  const [getAddAddressOpen, setAddAddressOpen] = useState(false)
  const [getAddressList, setAddressList] = useState('')
  const [getAddressId, setAddressId] = useState('')
  const [getAddressStatus, setAddressStatus] = useState('')
  const [getStateList, setStateList] = useState([])
  const [getCityList, setCityList] = useState([])
  const [anchorEl, setAnchorEl] = React.useState({ id: null, item: [] });
  const open = Boolean(anchorEl.id);
  const [snopen, setsnOpen] = React.useState(false);


  const handleCloseSN = () => {


    setsnOpen(false);
  };
  const handleClick = (event, item) => {
    setAnchorEl({ id: event.currentTarget, item: item });
  };

  const handleMenuClose = () => {
    setAnchorEl({ id: null, item: [] });
    setStates(false)
  };


  const [state, setStates] = React.useState(false);

  const handleChange = async (item) => {
    setStates(true)
    let body = { mobileno: item.usermobile, addressid: item.addressid }
    var res = await postData('users/changeaddress', body)
    await postData('address/setaddressstatus', body)
    if (res.RESULT) {
      handleMenuClose()
      fetchAllAddress()
      setsnOpen(true)
    }
  };


  const handleAddAddress = async () => {
    setStateMsg('')
    setCityMsg('')
    setCountryMsg('')
    setZipcodeMsg('')
    setAddress1Msg('')
    setAddress2Msg('')

    var err = false;
    if (!checkRequire(getState)) {
      err = true
      setStateMsg("Please enter your State")
    }

    if (!checkRequire(getCity)) {
      err = true
      setCityMsg("Please enter your City")
    }

    if (!checkRequire(getZipcode)) {
      err = true
      setZipcodeMsg("Please enter your Zipcode")
    }

    if (!checkRequire(getCountry)) {
      err = true
      setCountryMsg("Please enter your Country")
    }

    if (!checkRequire(getAddress1)) {
      err = true
      setAddress1Msg("Please enter your Address")
    }

    if (!checkRequire(getAddress2)) {
      err = true
      setAddress2Msg("Please enter your Address")
    }

    if (!err) {

      let body = { mobileno: getMobile, username: getFirstName + " " + getLastName, emailaddress: getEmail, state: getState, city: getCity, country: getCountry, zipcode: getZipcode, address1: getAddress1, address2: getAddress2 }
      var result1 = await postData('address/addAddress', body)
      if (result1.affectedRows == 1) {
        var result = await postData('address/fetchalladdress', body)
        if (result.length == 1) {
          var b = { mobileno: getMobile, username: getFirstName + " " + getLastName, emailaddress: getEmail, addressid: result[0].addressid }
          var r = await postData('users/userdata', b)
          var res = await postData('address/changeaddressstatus', b)
        }
        // setMsg('Address Added Successfully')
        swal("Added", "Address Added Successfully", "success");
        handleAddAddressClose()
        fetchAllAddress()
      }
      else {
        swal("Failed", "Failed to Added Address", "error");
        // setMsg('Failed to Added Address')
      }
      setAddress1('')
      setAddress2('')
      setState('')
      setCity('')
      setZipcode('')
    }

  }

  const fetchStates = async () => {
    var list = []
    statecity.map(function (item, key) {
      list[key] = item.state
    })
    setStateList(list)
  }
  const fillStates = () => {
    return getStateList.map(function (item, key) {
      return (
        <MenuItem value={item}>
          {item}
        </MenuItem>
      )
    })

  }

  const handleState = (event) => {
    var state = event.target.value
    setState(state)
    //setStateForm(state)
    fetchCity(state)
  }

  const handleEditState = (event) => {
    var state = event.target.value
    setStateForm(state)
    fetchCity(state)
  }

  const fetchCity = async (selectstate) => {
    var list = []
    statecity.map(function (item, key) {
      if (item.state == selectstate) {
        item.districts.map(function (data, key) {
          list[key] = data
        })
      }
    })
    setCityList(list)
  }

  const fillCities = () => {
    return getCityList.map(function (item, key) {
      return (
        <MenuItem value={item}>
          {item}
        </MenuItem>
      )
    })
  }


  useEffect(function () {
    fetchUserRec()
    fetchAllAddress()
    fetchStates()
  }, [])

  const fetchAllAddress = async () => {
    var u = JSON.parse(localStorage.getItem('user'))
    let body = { mobileno: u.mobileno }
    var result = await postData('address/fetchalladdress', body)
    if (result.RESULT == false) { setAddressStatus(0) }
    else {
      var arr = []
      result.map((item) => {
        if (item.address_status) {
          arr.push(item)
        }
      })
      result.map((item) => {
        if (!item.address_status) {
          arr.push(item)
        }
      })

      setAddressList(arr)
      setAddressStatus(1)
    }

  }


  const fetchUserRec = async () => {
    var user = JSON.parse(localStorage.getItem('user'))
    var name = user.username.split(' ')
    setFirstName(name[0])
    setLastName(name[1])
    setEmail(user.emailaddress)
    setMobile(user.mobileno)

    //var result=await postData('users/userdatadisplaybyid',body) 

  }


  const handleChangeAddress = async () => {
    setStateMsg('')
    setCityMsg('')
    setCountryMsg('')
    setZipcodeMsg('')
    setAddress1Msg('')
    setAddress2Msg('')

    var err = false;
    if (!checkRequire(getStateForm)) {
      err = true
      setStateMsg("Please enter your State")
    }

    if (!checkRequire(getCityForm)) {
      err = true
      setCityMsg("Please enter your City")
    }

    if (!checkRequire(getZipcodeForm)) {
      err = true
      setZipcodeMsg("Please enter your Zipcode")
    }


    if (!checkRequire(getAddress1Form)) {
      err = true
      setAddress1Msg("Please enter your Address")
    }

    if (!checkRequire(getAddress2Form)) {
      err = true
      setAddress2Msg("Please enter your Address")
    }

    if (!err) {
      let body = { mobileno: getMobile, state: getStateForm, city: getCityForm, country: getCountryForm, zipcode: getZipcodeForm, address1: getAddress1Form, address2: getAddress2Form, addressid: getAddressId }
      var result = await postData('address/changeaddress', body)
      if (result.RESULT) {
        handleClose()
        fetchAllAddress()
        // setMsg("Address updated Successfully")
        swal("Updated", "Address Updated Successfully", "success");
      }
      else {
        // setMsg("Failed to updated Address")
        swal("Failed", "Failed to updated Address", "error");
      }
    }
    setState('')
    setCity('')
    setZipcode('')
    //setCountry('')
    setAddress1('')
    setAddress2('')

  }



  const EditAddress = () => {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Dialog
            open={getOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"EDIT ADDRESS"}</DialogTitle>
            <Divider />
            <DialogContent>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Paper elevation={0} style={{}}>

                  <Grid container spacing={1}>

                    <Grid item xs={12} md={6} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getFirstName} id="outlined-size-small"
                        variant="outlined"
                        size="small" label="First Name" />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getLastName} id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Last Name" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField className={classes.textfieldStyle} fullWidth value={getMobile}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Mobile No" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField className={classes.textfieldStyle} fullWidth value={getEmail}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Email-id" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth size="small" style={{ backgroundColor: '#f8f9f9', paddingLeft: 4 }} >
                        <InputLabel id="demo-simple-select-outlined-label">State</InputLabel>
                        <Select
                          value={getStateForm}
                          onChange={(event) => handleEditState(event)}
                          label="State"
                        >
                          <MenuItem value="">Select State</MenuItem>
                          {fillStates()}
                        </Select>
                      </FormControl>
                      <div className={classes.error}><small>{getStateMsg}</small></div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth size="small" style={{ backgroundColor: '#f8f9f9', paddingLeft: 4 }}>
                        <InputLabel id="demo-simple-select-outlined-label">City</InputLabel>
                        <Select
                          value={getCityForm}
                          onChange={(event) => setCityForm(event.target.value)}
                          label="City"
                        >
                          <MenuItem value="">Select City</MenuItem>
                          {fillCities()}
                        </Select>
                      </FormControl>
                      <div className={classes.error}><small>{getCityMsg}</small></div>
                    </Grid>

                    <Grid item xs={12} md={6} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getCountryForm}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Country" />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getZipcodeForm} onChange={(e) => setZipcodeForm(e.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Zipcode" />
                      <div className={classes.error}>{getZipcodeMsg}</div>
                    </Grid>

                    <Grid item xs={12} md={12} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getAddress1Form} onChange={(e) => setAddress1Form(e.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Address1" />
                      <div className={classes.error}><small>{getAddress1Msg}</small></div>
                    </Grid>

                    <Grid item xs={12} md={12} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getAddress2Form} onChange={(e) => setAddress2Form(e.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Address2" />
                      <div className={classes.error}><small>{getAddress2Msg}</small></div>
                    </Grid>

                    <Grid item xs={12} className={classes.container} style={{ paddingLeft: 8, }} >
                      <Button fullWidth variant="contained" color="secondary" style={{ fontWeight: 'bold', color: '#fff', marginTop: 10 }} onClick={() => handleChangeAddress()} >
                        Edit Address
                      </Button>
                    </Grid>
                    {/* 
                    <Grid item xs={12} className={classes.container}>
                      <b>{getMsg}</b>
                    </Grid> */}

                  </Grid>
                </Paper>
              </div>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="#454d50">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </div>
    )
  }


  const AddAddress = () => {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Dialog
            open={getAddAddressOpen}
            onClose={handleAddAddressClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"ADD ADDRESS"}</DialogTitle>
            <Divider />
            <DialogContent>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'cennter' }}>
                <Paper elevation={0} style={{ padding: '0px 10px' }}>

                  <Grid container spacing={1}>

                    <Grid item xs={12} sm={6} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getFirstName} id="outlined-size-small"
                        variant="outlined"
                        size="small" label="First Name" />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getLastName} id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Last Name" />
                    </Grid>

                    <Grid item xs={12} sm={6} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getMobile}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Mobile No" />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getEmail}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Email-id" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth size="small" style={{ backgroundColor: '#f8f9f9', paddingLeft: 4 }} >
                        <InputLabel id="demo-simple-select-outlined-label">State</InputLabel>
                        <Select
                          value={getState}
                          onChange={(event) => handleState(event)}
                          label="State"
                        >
                          <MenuItem value="">Select State</MenuItem>
                          {fillStates()}
                        </Select>
                      </FormControl>
                      <div className={classes.error}><small>{getStateMsg}</small></div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth size="small" style={{ backgroundColor: '#f8f9f9', paddingLeft: 4 }}>
                        <InputLabel id="demo-simple-select-outlined-label">City</InputLabel>
                        <Select
                          value={getCity}
                          onChange={(event) => setCity(event.target.value)}
                          label="City"
                        >
                          <MenuItem value="">Select City</MenuItem>
                          {fillCities()}
                        </Select>
                      </FormControl>
                      <div className={classes.error}><small>{getCityMsg}</small></div>
                    </Grid>

                    <Grid item xs={12} sm={6} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getCountry}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Country" />
                    </Grid>
                    <Grid item xs={12} sm={6}  >
                      <TextField className={classes.textfieldStyle} fullWidth value={getZipcode} onChange={(e) => setZipcode(e.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Zipcode" />
                      <div className={classes.error}><small>{getZipcodeMsg}</small></div>
                    </Grid>

                    <Grid item xs={12} sm={12} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getAddress1} onChange={(e) => setAddress1(e.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Address1" />
                      <div className={classes.error}><small>{getAddress1Msg}</small></div>
                    </Grid>

                    <Grid item xs={12} sm={12} >
                      <TextField className={classes.textfieldStyle} fullWidth value={getAddress2} onChange={(e) => setAddress2(e.target.value)}
                        id="outlined-size-small"
                        variant="outlined"
                        size="small" label="Address2" />
                      <div className={classes.error}><small>{getAddress2Msg}</small></div>
                    </Grid>

                    <Grid item xs={12} className={classes.container} style={{ paddingLeft: 8, }}>
                      <Button fullWidth variant="contained" color="secondary" style={{ fontWeight: 'bold', color: '#fff', marginTop: 10 }} onClick={() => handleAddAddress()} >
                        Add Address
                      </Button>
                    </Grid>
                    {/* 
                    <Grid item xs={12} className={classes.container}>
                      <b>{getMsg}</b>
                    </Grid> */}

                  </Grid>
                </Paper>
              </div>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddAddressClose} color="#454d50">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </div>

    )
  }




  const handleClickOpen = (item) => {
    let body = { item: item }
    fetchCity(item.state)
    setStateForm(item.state)
    setCityForm(item.city)
    //setCountryForm(item.country)
    setZipcodeForm(item.zipcode)
    setAddress1Form(item.address1)
    setAddress2Form(item.address2)
    setAddressId(item.addressid)
    setOpen(true);
  };

  const handleAddAddressOpen = () => {
    setAddAddressOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMsg('')
    setStateMsg('')
    setCityMsg('')
    setZipcodeMsg('')
    setCountryMsg('')
    setAddress1Msg('')
    setAddress2Msg('')
    //fetchUserRec()
  };

  const handleAddAddressClose = () => {
    setAddAddressOpen(false);
    setMsg('')
    setStateMsg('')
    setCityMsg('')
    setZipcodeMsg('')
    setCountryMsg('')
    setAddress1Msg('')
    setAddress2Msg('')
    //fetchUserRec()
  };


  const handleAddressDelete = async (addressid) => {
    let body = { addressid: addressid }
    var result = await postData('address/deleteaddress', body)
    handleClose()
    fetchAllAddress()
  }


  return (
    <div>

      <Grid container spacing={1} className={classes.gridStyle} >
        <Grid item xs={12} md={12} >
          <div className={classes.filterStyleHeading}>Your Address</div>
          {/* <Divider className={classes.divider}/> */}
        </Grid>
        {getAddressStatus == 0 ? <Grid item xs={12} md={12} >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', border: '1px dashed #747d8c', padding: '10px 20px', borderRadius: 5, cursor: 'pointer' }} onClick={() => handleAddAddressOpen()}>
            <AddIcon style={{ fontSize: 60 }} />
            <div><h3>Add New Address</h3></div>
          </div>
        </Grid> : <>
          {getAddressList.length == 0 ? <div></div> : getAddressList.map(function (item, key) {
            return (
              <>
                <Grid item xs={12} sm={6}>
                  <div className={classes.filterStyle}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className={classes.padding} ><AccountCircleIcon style={{ color: '#212121', fontSize: 16 }} /></div>
                      <div className={classes.padding}><b>{getFirstName} {getLastName}</b></div>
                      <div style={{
                        width: '53%', display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end'
                      }}>{item.address_status == 0 ? <span style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>

                        <MoreVertIcon aria-controls="long-menu"
                          onClick={(event) => handleClick(event, item)} style={{ cursor: 'pointer', fontSize: 18 }} />
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl.id}
                          open={open}
                          onClose={handleMenuClose}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 3.5,
                              width: "30ch",
                              paddingLeft: 10,
                              fontSize: 12,
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'flex-end'
                            },
                          }}
                        >

                          <FormControlLabel
                            control={
                              <Switch
                                checked={state}
                                onChange={() => handleChange(anchorEl.item)}
                                color="primary"
                                size="small"
                              />
                            }
                            label="Make as Default"
                          />

                        </Menu>

                      </span> : <span> </span>}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className={classes.padding}><PhoneIcon style={{ color: '#212121', fontSize: 16 }} /></div>
                      <div className={classes.padding}>+91-{getMobile}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className={classes.padding}><HomeIcon style={{ color: '#212121', fontSize: 16 }} /></div>
                      <div className={classes.padding}>{item.address1}, {item.address2}, {item.city}-{item.zipcode} ,{item.state}</div>
                    </div>
                    <>
                      <Grid container spacing={1} style={{ display: 'flex', flexDirection: 'row', paddingTop: 20 }}>

                        {item.address_status == 1 ? <>
                          <Grid item xs={12} sm={6}>
                            <Button startIcon={<EditIcon />} fullWidth style={{ color: '#000', fontWeight: 'bold', border: '1px solid #212121' }} onClick={() => handleClickOpen(item)}>
                              Edit
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button startIcon={<HighlightOffOutlinedIcon />} disabled={true} variant="outlined" color="secondary" fullWidth >
                              <b> DEFAULT </b>
                            </Button>
                          </Grid></>
                          : <>
                            <Grid item xs={12} sm={6}>

                              <Button startIcon={<EditIcon />} fullWidth style={{ color: '#000', fontWeight: 'bold', border: '1px solid #212121' }} onClick={() => handleClickOpen(item)}>
                                <b> Edit</b>
                              </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Button startIcon={<DeleteForeverIcon />} fullWidth style={{ color: '#000', fontWeight: 'bold', border: '1px solid #212121' }} onClick={() => handleAddressDelete(item.addressid)} >
                                <b> Delete</b>
                              </Button>
                            </Grid></>
                        }
                      </Grid>
                    </>

                  </div>
                </Grid>

              </>
            )
          })}
          <Grid item xs={12} sm={6} >
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', border: '1px dashed #747d8c', padding: '10px 20px', borderRadius: 5, cursor: 'pointer' }} onClick={() => handleAddAddressOpen()}>
              <AddIcon style={{ fontSize: 72 }} />
              <div><h3>Add New Address</h3></div>
            </div>
          </Grid>
        </>}

        {AddAddress()}
        {EditAddress()}
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} open={snopen} autoHideDuration={2000} onClose={handleClose} onClick={handleCloseSN} >
          <Alert onClose={handleCloseSN} severity="success">
            Default Address has been Changed
          </Alert>
        </Snackbar>
      </Grid>

    </div>

  )
}


