import React, { useEffect, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputAdornment from "@material-ui/core/InputAdornment";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Link from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Person from "@material-ui/icons/Person";
import { getData, postData, ServerURL } from "../FetchNodeServices";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import ShowCartIcon from "./ShowCartIcon";
import FirstPage from "./FirstPage";
import ViewListofProducts from "./ViewListofProducts";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Hidden from "@material-ui/core/Hidden";
import DirectionsIcon from "@material-ui/icons/Directions";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import "./style.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import CloseIcon from "@material-ui/icons/Close";
//import Image from 'react-image-resizer'

const useStyles = makeStyles((theme) => ({
  mainmenu: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  small: {
    color: "#E4E4E4",
    fontSize: 10,
    fontWeight: "normal",
    letterSpacing: 1,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "2ch",
  },
  centerItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
  },
  grow: {
    width: "100%",
    position: "fixed",
    top: 0,
    bottom: "auto",
    zIndex: 1,
    //display:'flex',
    //justifyContent:'flex-end',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  list: {
    width: 300,
  },
  title: {
    display: "flex",
    paddingTop: 13,
  },
  search: {
    position: "relative",
    borderRadius: 50,
    //width:500,
    //padding: "5px 15px",
    border: "1px solid #212121",
    width: 300,
    //width:'100%'
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    margin: "0px 15px 0px 5px",
    padding: "0px 5px 0px 18px",
    // border:'0.2px solid #000'
    backgroundColor: "#fff",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "",
  },
  inputRoot: {
    color: "inherit",
  },
  // inputInput: {
  //   padding: theme.spacing(1, 1, 1, 0),
  //   // vertical padding + font size from searchIcon
  //   paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  //   transition: theme.transitions.create("width"),
  //   width: "100%",
  //   [theme.breakpoints.up("md")]: {
  //     width: "20ch",
  //   },
  // },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  center: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: 14,
    borderBottom: "1px solid #dcdde1",
  },
  drawerContent: {
    fontSize: 13,
    borderBottom: "1px solid #dcdde1",
    cursor: "pointer",
  },
  hover: {
    "&:hover": {
      transform: "scale(1)",
      transition: "all 0.5s ease 0s",
      color: "none",
      background: "none",
    },
  },
  root: {
    flexGrow: 1,
    //padding: '2px 4px',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    //justifyContent:'center',
    //flexWrap: 'wrap',
    fontSize: 14,
    fontFamily: "Calibri",
    width: "100%",
  },
  paper: {
    backgroundColor: "#FFFFFF",
    boxShadow: "rgb(0 0 0 / 20%) 0px 1px 5px 0px",
    borderRadius: 0,
  },
  subdiv: {
    padding: 10,
    width: 700,
    background: "none",
  },
  // search: {
  //   borderRadius: 6,
  //   flexGrow: 1,
  //   marginTop: 10,
  //   marginLeft: 10,
  //   marginRight: 10,
  //   marginBottom: 10,
  //   // display: 'flex',
  //   // alignItems: 'center',
  //   width: 400,
  //   WebkitBoxShadow: '0 5px 19px 2px rgba(0,0,0,0)'
  // },

  title: {
    flexGrow: 1,
    //borderRadius:10
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontSize: 18,
    fontWeight: "normal",
    color: "#000000",
    fontFamily: "Calibri",
    width: 400,
  },
  iconButtonMap: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    // paddingLeft: 10,
    // paddingRight: 10,
    padding: 11.5,
    backgroundColor: "#000",
    //borderRadius:6,
    color: "#fff",
  },
  deliverytext: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 8,
    backgroundColor: "#000",
    color: "#fff",
    width: 70,
    fontSize: 12,
  },
  textbox: {
    background: "#000",
    color: "#fff",
    border: "none",
    outline: "0px dashed white",
    width: 60,
    fontWeight: "bold",
  },

  searchtext: {
    padding: 12,
    backgroundColor: "#ffffff",
    //borderRadius:6,
    color: "#fff",
  },
  iconButtonSearch: {
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    // paddingLeft: 10,
    paddingRight: 7,
    // padding:11.5,
    backgroundColor: "none",
    //borderRadius:6,
    color: "#000",
    //color:'#0097e6',
    fontSize: 35,
  },
  iconButton: {
    padding: 12,
    backgroundColor: "#ffffff",
    borderRadius: 6,
    color: "#32aeb1",
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [getSrch, setSrch] = useState("");
  const [gettrue, settrue] = useState(false);

  const handleSearchProduct = () => {
    props.history.push({ pathname: `/ViewListofSearchProducts/${getSrch}` });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchProduct();
    }
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  ///Main Menu Items Implementation////
  const [anchorMM, setAnchorMM] = React.useState(null);
  const [getCategoryList, setCategoryList] = useState([]);
  const [getBrandList, setBrandList] = useState([]);
  const [getSubCategoryList, setSubCategoryList] = useState([]);
  const [getMenuList, setMenuList] = useState([]);
  const [getUserName, setUserName] = useState("Sign In / Sign Up");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  //const cartItems=useSelector(state=>state.cart)
  //const [getCount,setCount]=useState(cartItems.length)
  const changeView = (value, parameter) => {
    if (value == 1) {
      setContainer(<ViewListofProducts categoryid={parameter.categoryid} />);
    }
  };
  ////console.log(user)
  const [getContainer, setContainer] = useState(
    <FirstPage changeView={changeView} />
  );
  const handleClick = (event, categoryid) => {
    setAnchorMM(event.currentTarget);
    fetchModel(categoryid);
  };

  const handleClose = () => {
    // alert("Hello")
    setAnchorMM(null);
  };

  const CheckSession = async () => {
    if (localStorage.getItem("user")) {
      var user = JSON.parse(localStorage.getItem("user"));
      var name = user.username.split(" ");
      setUserName(name[0]);
      dispatch({ type: "ADD_USER", userdata: [user.mobileno, user] });
    }
  };

  const CheckCart = async () => {
    if (localStorage.getItem("cart")) {
      var cart = JSON.parse(localStorage.getItem("cart"));
      dispatch({ type: "SET_ALL_CART", cartItems: cart });
    }
  };

  useEffect(function () {
    fetchCategory();
    CheckSession();
    CheckCart();
  }, []);

  const fetchCategory = async () => {
    var list = await getData("pack/category");
    setCategoryList(list);
  };

  const fetchBrand = async (categoryid) => {
    var list = await postData("brand/brandfill", { categoryid: categoryid });
    setBrandList(list);
  };

  const fetchModel = async (categoryid) => {
    var list = await postData("models/fetchallmodels", {
      categoryid: categoryid,
    });
    var arr = [];
    list.map((item) => {
      arr.push(item.subcategoryname);
      arr.map((i, k) => {
        if (i == item.subcategoryname && k != arr.length - 1) {
          arr.pop();
        }
      });
    });
    setSubCategoryList(arr);
    setMenuList(list);
  };

  /* const DisplayMainMenu = () => {
    return (
      <div className="container" >
        <center>
          <div className="col-md-12">
            {getCategoryList.map((item, key) => {
              return (
                <Button
                  style={{
                    margin: "0px 10px",
                    padding: "5px 20px",
                    fontSize: 12,
                    color: "#FFFFFF",
                  }}
                  aria-controls="simple-menu"
                  value={item.id}
                  onClick={(event) => handleClick(event)}
                >
                  {item.categoryname} <KeyboardArrowDownIcon />
                </Button>
              );
            })}
          </div>
        </center>
      </div>
    );
  };
*/
  const DisplayMainMenu = () => {
    return (
      <div className={classes.mainmenu}>
        <div style={{ marginLeft: 10, padding: "0px 27px 0px 0px" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              color: "#fff",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {getCategoryList.map((item, key) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "280px",
                      alignItems: "center",
                    }}
                    onClick={(event) => handleClick(event, item.id)}
                  >
                    {/* <Toolbar> */}
                    <Avatar
                      src={`${ServerURL}/images/${item.picture}`}
                      style={{ width: 40, height: 40 }}
                    />
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        display: "flex",
                        paddingLeft: 10,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      {item.categoryname}{" "}
                      <small className={classes.small}>
                        {" "}
                        {item.description}
                      </small>
                    </span>

                    {/* </Toolbar> */}
                  </div>
                );
              })}
            </Grid>{" "}
          </Grid>
        </div>
      </div>
    );
  };
  const DisplayMainMenuList = () => {
    return (
      <div className={classes.mainmenu}>
        {getCategoryList.map((item, key) => {
          return (
            <div
              //className={classes.hover}

              style={{
                padding: "0px 0px 0px 0px",
                fontSize: 18,
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                fontFamily: "Calibri",
                margin: "0px 12px",
              }}
              onClick={(event) => handleClick(event, item.id)}
              // onMouseLeave={handleClose}
            >
              {/* <span style={{}}> {item.categoryname}</span> */}
              <span class="smootheds"> {item.categoryname}</span>
              <Icon variant="filled" aria-label="menu">
                <ExpandMoreIcon size="small" />
              </Icon>
            </div>
          );
        })}
      </div>
    );
  };
  const DisplayCategoryMenu = () => {
    return (
      <div className={classes.centerItem}>
        <div style={{ width: window.innerWidth * 0.73 }}>
          <Grid
            container
            spacing={2}
            style={{ cursor: "pointer", paddingLeft: 15, color: "#747d8c" }}
          >
            {getSubCategoryList.map((item) => {
              return (
                <Grid item xs={12} sm={3}>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      paddingBottom: 10,
                      paddingTop: 5,
                      color: "#212121",
                    }}
                  >
                    {item}
                  </div>
                  <Divider />
                  {getMenuList.map((data) => {
                    return (
                      <>
                        {data.subcategoryname == item ? (
                          <div
                            class="middle"
                            style={{ fontSize: 14, paddingBottom: 5 }}
                            onClick={() => handleProductModel(data.modelid)}
                          >
                            {data.modelname}
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    );
  };

  const handleProductBrand = (brandid) => {
    props.history.push({ pathname: `/ViewListofBrandProducts/${brandid}` });
  };
  const handleProductModel = (modelid) => {
    handleClose();
    localStorage.setItem("MyProfile", JSON.stringify("MyProfile"));
    props.history.push({ pathname: `/ViewListofModelProducts/${modelid}` });
  };

  const DisplayBrandMenu = () => {
    return (
      <div style={{ width: 250, outline: "none" }}>
        <Grid container spacing={1} style={{ cursor: "pointer" }}>
          {getBrandList.map((item, key) => {
            return (
              <>
                {getBrandList.length > 5 ? (
                  <Grid
                    className={[classes.center, classes.hover]}
                    style={{ padding: "5px 20px" }}
                    item
                    xs={12}
                    sm={6}
                    onClick={() => handleProductBrand(item.brandid)}
                  >
                    <Avatar
                      alt={item.brandname}
                      src={`${ServerURL}/images/${item.picture}`}
                    />{" "}
                    <span style={{ margin: 8 }}>{item.brandname}</span>
                  </Grid>
                ) : (
                  <Grid
                    className={[classes.center, classes.hover]}
                    style={{ padding: "5px 20px" }}
                    item
                    xs={12}
                    sm={12}
                    onClick={() => handleProductBrand(item.brandid)}
                  >
                    <Avatar
                      alt={item.brandname}
                      src={`${ServerURL}/images/${item.picture}`}
                    />{" "}
                    <span style={{ margin: 8 }}>{item.brandname}</span>
                  </Grid>
                )}
              </>
            );
          })}{" "}
        </Grid>
      </div>
    );
  };

  /////HamBurger/////

  // const [anchorEl, setAnchorEl] = React.useState(null);

  ///Handle Account////

  const handleAccount = () => {
    props.history.push({ pathname: `/MyAccount`, path: 1 });
  };

  ////HandleMyList////
  const handleMyList = () => {
    props.history.push({ pathname: `/ShowCart` });
  };
  ////Handle About us///
  const handleAboutUs = () => {
    props.history.push({ pathname: `/AboutUs` });
  };
  // Shop By Category////

  const handleShopByCategory = () => {
    props.history.push({ pathname: `/ShopByCategory` });
  };

  /////HandleFAQ/////

  const handleFAQ = () => {
    props.history.push({ pathname: `/NeedHelp` });
  };

  ////HnadleHome////
  const handleHome = () => {
    localStorage.setItem("MyProfile", JSON.stringify("MyProfile"));
    props.history.push({ pathname: `/` });
  };

  const handleLogin = () => {
    props.history.push({ pathname: `/UserLogin` });
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (cid) => (event, isExpanded) => {
    setExpanded(isExpanded ? cid : false);
    fetchModel(cid);
  };

  const list = (anchor) => (
    <div fullList style={{ padding: 0, width: "100%" }}>
      <div
        style={{
          padding: 10,
          color: "#000",
          borderBottom: "1px solid #999999",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <span
            style={{ fontSize: 20, color: "#000", cursor: "pointer" }}
            onClick={(event) => setState(false)}
          >
            <CloseIcon />
            {/* <b>MENU</b> */}
          </span>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            fontSize: 20,
          }}
        >
          <img
            src="/images/Logo/logo.png"
            class="img-responsive"
            width={100}
            //width='100%'
            onClick={() => handleHome()}
          />

          {/* {getUserName} */}
        </div>
      </div>

      <div
        onClick={() => handleAccount()}
        style={{
          padding: 10,
          color: "#000",
          flexDirection: "row",
          display: "flex",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Person style={{ fontSize: 18 }} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            fontWeight: "bold",
            fontFamily: "Roboto,Arial,sans-serif",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            fontSize: 12,
          }}
        >
          {getUserName == "Sign In / Sign Up" ? (
            getUserName
          ) : (
            <span>Hi {getUserName}</span>
          )}
        </div>
      </div>
      {getCategoryList.map((item) => {
        return (
          <Accordion
            expanded={expanded === item.id}
            onChange={handleChange(item.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              style={{
                fontSize: 14,
                color: "#212121",
                fontFamilly: "Roboto,Arial,sans-serif",
              }}
            >
              {item.categoryname}
            </AccordionSummary>
            <AccordionDetails>{MobileMenuList()}</AccordionDetails>
          </Accordion>
        );
      })}
      {getUserName == "Sign In / Sign Up" ? (
        <></>
      ) : (
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sm={12}
            onClick={() =>
              props.history.push({ pathname: `/MyAccount`, path: 3 })
            }
          >
            <Paper>
              <div style={{ fontSize: 14, padding: 15, cursor: "pointer" }}>
                Order History
              </div>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginTop: 1 }}
            onClick={() => userLogout()}
          >
            <Paper>
              <div style={{ fontSize: 14, padding: 15, cursor: "pointer" }}>
                Log Out
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );

  const userLogout = async () => {
    dispatch({ type: "REMOVE_ALL_CART_AND_USER" });
    props.history.replace({ pathname: `/UserLogin` });
  };

  const MenuList = () => {
    return (
      <div className={classes.centerItem}>
        <div style={{ width: window.innerWidth * 0.73 }}>
          <Grid
            container
            spacing={2}
            style={{ cursor: "pointer", paddingLeft: 15, color: "#747d8c" }}
          >
            {getSubCategoryList.map((item) => {
              return (
                <Grid item xs={12} sm={4}>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      paddingBottom: 10,
                      paddingTop: 5,
                      color: "#000",
                    }}
                  >
                    {item}
                  </div>
                  {getMenuList.map((data) => {
                    return (
                      <>
                        {data.subcategoryname == item ? (
                          <div
                            style={{ fontSize: 12, paddingBottom: 5 }}
                            onClick={() => handleProductModel(data.modelid)}
                          >
                            {data.modelname}
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    );
  };

  const MobileMenuList = () => {
    return (
      <div className={classes.centerItem}>
        <div style={{ width: window.innerWidth * 0.5 }}>
          <Grid
            container
            spacing={0}
            style={{ cursor: "pointer", paddingLeft: 5, color: "#212121" }}
          >
            {getSubCategoryList.map((item) => {
              return (
                <Grid item xs={12} sm={4}>
                  <div
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      paddingBottom: 10,
                      color: "#212121",
                    }}
                  >
                    {item}
                  </div>
                  {getMenuList.map((data) => {
                    return (
                      <>
                        {data.subcategoryname == item ? (
                          <div
                            style={{ fontSize: 12, paddingBottom: 5 }}
                            onClick={() => handleProductModel(data.modelid)}
                          >
                            {data.modelname}
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    );
  };

  return (
    <>
      <AppBar
        position="sticky"
        className={classes.root}
        style={{
          backgroundColor: "#ffffff",
          color: "#000",
          cursor: "pointer",
          WebkitBoxShadow: "0 1px 2px 0px rgba(0,0,0,0.3)",
          padding: "5px 0px 2px",
        }}
      >
        <Toolbar className={classes.root}>
          <Hidden lgUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer("left", true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <div
              style={{
                width: "100%",
                display: "flex",
                borderRadius: 0,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link
                component="button"
                style={{
                  textDecorationLine: "none",
                  textTransform: "uppercase",
                  fontSize: 30,
                  fontWeight: 600,
                  fontFamily: "Calibri",
                  flexBasis: "auto",
                  color: "#000",
                }}
              >
                {DisplayMainMenuList()}{" "}
              </Link>
            </div>
          </Hidden>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            {/* <div style={{fontSize:60,color:'#2e86de',fontWeight:'bold'}}><span style={{color:'#000',fontWeight:'bold'}}>.</span>in</div> */}
            <img
              src="/images/Logo/logo.png"
              class="img-responsive"
              width={200}
              // width="100%"
              onClick={() => handleHome()}
            />
          </div>
          <div className={classes.grow} />
          <div
            className={classes.sectionDesktop}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginLeft: 5,
            }}
          >
            <Hidden smDown>
              <div className={classes.search}>
                <InputBase
                  fullWidth
                  //style={{fontSize:15}}
                  placeholder="Search for products here"
                  onKeyPress={handleKeyPress}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{
                        color: "#fff",
                        pointerEvents: getSrch != "" ? "visible" : "none",
                        cursor: "pointer",
                        background: "#212121",
                        height: "100%",
                        borderRadius: 50,
                        padding: 6,
                      }}
                      onClick={() => handleSearchProduct()}
                    >
                      {" "}
                      <SearchIcon style={{ fontSize: 20 }} />
                    </InputAdornment>
                  }
                  onChange={(event) => setSrch(event.target.value)}
                />
              </div>
            </Hidden>
            <ShowCartIcon
              history={props.history}
              style={{ margin: "0px 10px" }}
            />
            <Hidden smDown>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0px 10px",
                }}
                onClick={() => handleAccount()}
              >
                {/* <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  style={{ padding: 0 }}
                > */}
                <Person style={{ fontSize: 28 }} />
                {/* </IconButton> */}
                <span style={{ padding: "0px 5px", fontSize: 12 }}>
                  {getUserName}
                </span>
              </div>
            </Hidden>
            <Menu
              id="simple-menu"
              anchorEl={anchorMM}
              getContentAnchorEl={null}
              keepMounted
              open={Boolean(anchorMM)}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              style={{ marginTop: 20, padding: 0 }}
            >
              {DisplayCategoryMenu()}
            </Menu>
            <Hidden mdUp>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0px 10px",
                }}
              >
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  style={{ padding: 0 }}
                  onClick={() => settrue(true)}
                >
                  <SearchIcon style={{ fontSize: 28 }} />
                </IconButton>
                <span style={{ padding: "0px 5px", fontSize: 12 }}>Search</span>
              </div>
            </Hidden>
          </div>
        </Toolbar>
        <React.Fragment key={"left"}>
          <SwipeableDrawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            {list("left")}
          </SwipeableDrawer>
        </React.Fragment>
      </AppBar>
      {gettrue ? (
        <AppBar position="fixed" style={{ top: 63 }}>
          <Paper elevation={0} style={{ padding: "0px 10px", borderRadius: 0 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "100%", padding: "10px 0px" }}>
                <InputBase
                  fullWidth
                  style={{ color: "#000" }}
                  placeholder="Search for products here"
                  onKeyPress={handleKeyPress}
                  onChange={(event) => setSrch(event.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  backgroundColor: "#212121",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#212121",
                    cursor: "pointer",
                    width: 50,
                    textAlign: "center",
                  }}
                  onClick={() => settrue(false)}
                >
                  <CloseIcon style={{ fontSize: 24, color: "#fff" }} />
                  {/* <b>MENU</b> */}
                </div>
              </div>
            </div>
          </Paper>
        </AppBar>
      ) : (
        <></>
      )}
    </>
  );
}
