import React, { useEffect, useState, createRef } from "react";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { getData, postData, ServerURL } from "../FetchNodeServices";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import QtyCtrl from "./QtyCtrl";
import { blue } from "@material-ui/core/colors";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { IconButton, TextField } from "@material-ui/core";
import Place from "@material-ui/icons/Place";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import renderHTML from "react-render-html";
import Image from "react-image-resizer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import SearchIcon from '@material-ui/icons/Search';
import ImageZoom from "react-medium-image-zoom";
import StarIcon from "@material-ui/icons/Star";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { KeyboardArrowLeft, KeyboardArrowRight, Rotate90DegreesCcw } from "@material-ui/icons";
import Snackbar from '@material-ui/core/Snackbar';
import "./style.css";
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import ReactImageZoom from 'react-image-zoom';
import $ from 'jquery'
import Slide from '@material-ui/core/Slide';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#FFFFFF",
    borderRadius: 0,
    boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 5px 0px'
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'row',
    color: '#707070',
    '& > *': {
      margin: theme.spacing(0),
    },
    fontFamily: 'Calibri'
  },
  scardview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    paddingTop: 25
  },

}));


export default function TermsAndCondition(props) {
  const classes = useState();
  const [getTC, setTc] = useState('');

  const fetchTermsandCondition = async () => {
    var result = await getData("termsandcondition/user");
    //alert(result)
    //console.log(result)
    setTc(result.data.description);
    //alert(result.data.description)
  }

  useEffect(function () {
    fetchTermsandCondition();
  }, []);



  return (
    <div>
      <Header history={props.history} />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ width: window.innerWidth * 0.85, margin: '20px 0px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper elevation={0} className={classes.paper} style={{ padding: 10, }}>
                {renderHTML(getTC)}
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer history={props.history} />
    </div>
  );
}
