import React, { useEffect, useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { postData, ServerURL, postDataAndImage } from '../FetchNodeServices';
import { useParams } from "react-router-dom";
import { param } from 'jquery';
import { Description, SettingsInputAntennaTwoTone } from '@material-ui/icons';
import Footer from "./Footer";
import Header from "./Header";
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Avatar from '@material-ui/core/Avatar';
import { checkRequire } from '../Checks'
import Paper from "@material-ui/core/Paper";



const useStyles = makeStyles((theme) => ({
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'row',
        color: '#212121',
    },
    divider: {
        //width: '100%',
        margin: '10px -10px 0px -10px'
    },
    mheading: {
        padding: 8,
        fontSize: 22,
        color: '#212121;',
        fontWeight: 600,
        fontFamily: 'Roboto,Arial,sans-serif'
    },
    heading: {
        padding: 8,
        fontSize: 18,
        color: '#212121;',
        fontWeight: 500,
        fontFamily: 'Roboto,Arial,sans-serif'
    },
    subheading: {
        padding: '20px 8px 20px 8px',
        fontSize: 16,
        color: '#212121;',
        fontWeight: 600,
        fontFamily: 'Roboto,Arial,sans-serif'
    },
    span: {
        padding: '0px 8px 20px 8px',
        fontSize: 13,
        color: '#212121;',
        fontFamily: 'Roboto,Arial,sans-serif'
    },
    paper: {
        padding: 10,
        //margin: 10,
        backgroundColor: "#FFFFFF",
        borderRadius: 0,
        boxShadow: 'rgb(0 0 0 / 20%) 0px 1px 5px 0px'
    },
    input: {
        display: 'none',
    },
    avatortheme: {
        width: 70,
        height: 70,
    },
    error: {
        color: 'red',
        //position:"absolute",
        fontSize: 12,
        margin: '0px 4px'
    }
}));

export default function RatingnReviews(props) {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = React.useState('order');
    const [getcheck, setcheck] = useState(false)
    const [product, setProduct] = useState([])
    const [state, setState] = useState(false)
    const [getMobile, setMobile] = useState('')
    const [value, setValue] = React.useState(1);
    const [hover, setHover] = React.useState(1);
    const [getIcon, setIcon] = useState({ icon: '', fileIcon: '' })
    const [getName, setName] = useState('The 7 Shades Customer')
    const [getDescription, setDescription] = useState('')
    const [getMsg, setMsg] = useState('')
    const [getErrname, setErrname] = useState('')
    const [getErrRate, setErrRate] = useState('')
    const [getErrDes, setErrDes] = useState('')
    const [getErrIcon, setErrIcon] = useState('')
    var params = useParams()

    const labels = {
        1: 'Very Poor',
        2: 'Poor',
        3: 'Good',
        4: 'Very Good',
        5: 'Excellent',
    };
    const CheckSession = async () => {
        if (localStorage.getItem('user')) {
            var user = JSON.parse(localStorage.getItem('user'))
            setMobile(user.mobileno)
            CheckOrder(user.mobileno)
            CheckReview(user.mobileno)
        }
        else {
            props.history.replace({ pathname: `/UserLogin` })
        }
    }
    useEffect(function () {
        window.scrollTo(0, 0)
        CheckSession()
    }, [])

    const CheckReview = async (mobile) => {
        var result = await postData('review/checkreview', { mobileno: mobile, productid: params.pid })
        console.log("GGG", result)
        if (result.RESULT) {
            console.log("GGG", result.data)
            setDescription(result.data.description)
            setName(result.data.name)
            setValue(result.data.ratings)
            setHover(result.data.ratings)
            setIcon({ icon: '', fileIcon: `${ServerURL}/images/${result.data.pic}` })
            setcheck(true)
        }
    }

    const CheckOrder = async (mobile) => {
        var result = await postData('review/checkorder', { mobileno: mobile, productid: params.pid })
        if (result.RESULT) {
            setProduct(result.data)
            setState(true)
        }
        else {
            setState(false)
        }
    }


    const handleSubmit = async () => {
        var err = false;
        if (!checkRequire(getName)) {
            err = true
            setErrname('Name can not be empty')
        }
        if (!getcheck) {
            if (getIcon.icon == '') {
                err = true
                setErrIcon('Image can not be empty')
            }
        }
        if (!checkRequire(getDescription)) {
            err = true
            setErrDes('Description can not be empty')
        }

        if (!err) {
            var formData = new FormData()
            formData.append('name', getName)
            formData.append('description', getDescription)
            formData.append('pic', getIcon.icon)
            formData.append('ratings', value)
            formData.append('usermobile', getMobile)
            formData.append('productid', product.productid)
            var config = { headers: { 'content-type': 'multipart/form-data' } }
            if (getcheck) {
                var result = await postDataAndImage('review/updateReview', formData, config)
            }
            else {
                var result = await postDataAndImage('review/addnewreview', formData, config)
            }
            //console.log(result)
            if (result) {
                setMsg("Submit")
            }
            else {
                alert("server Error")
            }
        }
    }


    return (
        <>
            {/* <div style={{  }}> */}
            <Header history={props.history} />
            <div className={classes.center}>
                <div style={{ width: "99%", margin: '5px 5px', }}>
                    {/* <div className={classes.center} >
                <div style={{ width: window.innerWidth * 0.96, margin: '10px 15px', }}> */}
                    {getMsg == 'Submit' ? <Paper elevation={1} className={classes.paper}>
                        <h4>You have successfully submitted your review</h4>
                    </Paper> :
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                {state ? <Paper elevation={1} className={classes.paper}>
                                    <Grid container spacing={0} className={classes.center}>
                                        <Grid item xs={12} sm={6}>
                                            <div className={classes.mheading}>Rating & Reviews</div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <img src={`${ServerURL}/images/${product.picture}`} width="60" height="60" />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column', }}>
                                                    <span style={{ fontSize: 14, fontFamily: 'Calibri', color: '#212121', padding: '0px 2px' }}>
                                                        {product.productname}
                                                    </span>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper> : <></>}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Paper elevation={1} className={classes.paper}>
                                    <Grid container spacing={0} className={classes.center}>
                                        <Grid item xs={12} sm={12}>
                                            <div className={classes.heading}><b>What makes a good review</b></div>
                                            <Divider className={classes.divider} />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <div className={classes.subheading}>Have you used this product?</div>
                                            <div className={classes.span}>Your review should be about your experience with the product.</div>
                                            <Divider style={{ width: '95%', marginLeft: 10 }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <div className={classes.subheading}>Why review a product?</div>
                                            <div className={classes.span}>Your valuable feedback will help fellow shoppers decide!</div>
                                            <Divider style={{ width: '95%', marginLeft: 10 }} />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <div className={classes.subheading}>How to review a product?</div>
                                            <div className={classes.span}>Your review should include facts. An honest opinion is always appreciated. If you have an issue with the product or service please contact us from the help centre.</div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Paper elevation={1} className={classes.paper}>
                                    {state ? <Grid container spacing={0} className={classes.center}>
                                        <Grid item xs={12} sm={12} style={{ borderBottom: '1px solid #ced6e0', }}>
                                            <div style={{ fontSize: 20, paddingTop: 5 }}> <b>Rate this Product</b></div>
                                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 5 }}>
                                                <Rating
                                                    name="hover-feedback"
                                                    value={value}
                                                    precision={1}
                                                    onChange={(event, newValue) => {
                                                        if (newValue == null) {
                                                            setValue(1)
                                                        }
                                                        else {
                                                            setValue(newValue)
                                                        }
                                                    }}
                                                    onChangeActive={(event, newHover) => {
                                                        setHover(newHover);
                                                    }}
                                                // size="small"
                                                />
                                                {value !== null && <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} style={{ borderBottom: '1px solid #ced6e0', paddingTop: 10 }}>
                                            <div style={{ fontSize: 16, paddingTop: 5 }}> <b>Review this Product</b></div>
                                            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 5 }}>
                                                <span style={{ fontSize: 13 }}><b> Description</b></span>
                                                <TextField value={getDescription} variant="standard" focused={true} onChange={(event) => setDescription(event.target.value)} />
                                            </div>
                                            <div className={classes.error}>{getErrDes}</div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} style={{ borderBottom: '1px solid #ced6e0', paddingTop: 10 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 5 }}>
                                                <span style={{ fontSize: 13 }}><b> Name</b></span>
                                                <TextField value={getName} variant="standard" focused={true} onChange={(event) => setName(event.target.value)} />
                                            </div>
                                            <div className={classes.error}>{getErrname}</div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={classes.center} style={{ borderBottom: '1px solid #ced6e0', padding: 10 }}>
                                            <input accept="image/*"
                                                className={classes.input}
                                                id="icon-button-file" type="file"
                                                onChange={(event) => setIcon({ icon: event.target.files[0], fileIcon: URL.createObjectURL(event.target.files[0]) })} />
                                            <label htmlFor="icon-button-file">
                                                <IconButton color="primary" aria-label="upload picture" component="span"  >
                                                    <PhotoCamera style={{ fontSize: 50, color: '#212121' }} />
                                                </IconButton>
                                            </label>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ borderBottom: '1px solid #ced6e0', padding: '12px 10px' }} >
                                            <Avatar alt="Pic" variant='rounded' src={getIcon.fileIcon} className={classes.avatortheme} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} style={{ padding: '12px 10px' }}>
                                            <div className={classes.error}>{getErrIcon}</div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}>
                                            {getMsg}
                                            <Button size="medium" variant="contained" style={{ backgroundColor: '#212121', color: '#fff' }} onClick={() => handleSubmit()} >Submit</Button>
                                        </Grid>
                                    </Grid> :
                                        <div className={classes.center} style={{ flexDirection: 'column', display: 'flex' }}>
                                            <h3>Haven't purchased this Product</h3>
                                            <h5>Sorry! You are not allowed to review this product since you haven't bought it.</h5>
                                        </div>}
                                </Paper>
                            </Grid>
                        </Grid>
                    }
                </div>
            </div>
            <Footer history={props.history} />
            {/* </div>
        </div> */}
        </>
    );
}