import React, { useEffect, useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getData, ServerURL, postData, postDataAndImage } from '../FetchNodeServices';
import "./style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { CallMissedSharp } from '@material-ui/icons';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    marginTop: 50
  },

  cardview: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headingName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
    // fontSize: 30,
    color: "#212121",
    textAlign: 'center',
    fontWeight: "bold",
    letterSpacing: "2.5px",
    fontFamily: 'Calibri',
    backgroundColor: 'transparent'
  },
  divider: {
    margin: '15px 10px',
    backgroundColor: '#e74c3c',
    height: 3,
    width: 100,
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 10,
    borderRadius: 0
  },
  popover: {
    pointerEvents: 'none',
    margintTop: 50
  },
  paper: {
    //padding: theme.spacing(0),

  },

}));

export default function ProductAd(props) {
  const classes = useStyles();


  const [getListBrandAd, setListBrandAd] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const fetchBrandAd = async () => {
    let list = await getData('product1/minproductadbybrand')
    // console.log('popopop',list)
    setListBrandAd(list)
  }


  useEffect(function () {
    fetchBrandAd()
  }, [])


  const showBrandAd = () => {
    return (
      getListBrandAd.map((item, key) => {
        return (
          <>
            {/* <Paper elevation={3}> */}
            <div class="container mt-40" style={{ margin: 10 }}>
              {/* <h3 class="text-center">Hover Effect Style : Demo - 15</h3> */}
              <div class="row mt-30">
                <div class="col-md-3 col-sm-6">
                  <div class="box15" onClick={() => handleShowProductView(item.productid, item.modelid)} >
                    <img src={`${ServerURL}/images/${item.ad}`} alt="" />
                    <div class="box-content">
                      <h3 class="title">{item.productname}</h3>
                      <ul class="icon">
                        {/* <li><a href="#"><i class="fa fa-search"></i></a></li>
                                <li><a href="#"><i class="fa fa-link"></i></a></li> */}
                        <div className="product_sticker">
                          <div className="sticker_bestsellers"><span>&#8377;</span> {item.minprice} /-</div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </Paper> */}

            {/* <Paper elevation={3} style={{margin:10}}>
          <div class="containers">
                <div class="box" onClick={() => handleShowProductView(item.productid,item.modelid)} >
                <img src={`${ServerURL}/images/${item.ad}`} width="280" height="340"  />
                    <div class="content">
                        <div class="wrap">
                            <h1>{item.productname}</h1>
                            
                        <p><div className="product_sticker">
                          <div className="sticker_bestsellers" ><span>&#8377;</span> {item.minprice} /-</div>
                        </div></p>
                        </div>
                    </div>
                </div>
            </div>
            </Paper> */}
            {/* <div aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose} style={{margin:10}} className={classes.root}  onClick={() => handleShowProductView(item.productid,item.modelid)} >
             <Paper elevation={3}>
            <div className="card">
              <img src={`${ServerURL}/images/${item.ad}`} width="280" height="340"  />

              <div className="product_sticker">
                <div style={{fontSize: 14, fontWeight: 'bold', marginLeft: 160}}>&nbsp;&nbsp;STARTING AT</div>
                <div className="sticker_bestsellers" style={{ marginLeft: 160 }}><span>&#8377;</span> {item.minprice} /-</div>

                <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center',marginTop:10,cursor:'pointer'}} > */}
            {/* <Popover elevation={0}
                    id="mouse-over-popover"
                    className={classes.popover}
                    
                    style={{borderRadius:0, display: 'flex',width:'100%',cursor:'pointer',flexDirection:'row',justifyContent: 'center', alignItems: 'center',marginTop:-80}}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <div className="explore_bestsellers" onClick={() => handleShowProductView(item.productid,item.modelid)}>EXPLORE NOW</div>
                  </Popover> */}
            {/* </div>
              </div>
            </div>
            </Paper>
          </div> */}
          </>
        );
      })
    )
  }

  const handleShowProductView = (productid, modelid) => {
    props.history.push({ pathname: `/ProductView/${productid}/${modelid}` })
  }

  return (
    <div className={classes.grow}>
      <div className={classes.headingName}>
        <Divider className={classes.divider} />
        <Hidden smDown>
          <h1>PRODUCT</h1>
        </Hidden>
        <Hidden smUp>
          <h3>PRODUCT</h3>
        </Hidden>

        <Divider className={classes.divider} />
      </div>
      <div className={classes.cardview}>
        {showBrandAd()}
      </div>
    </div>

  );
}